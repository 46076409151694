import React, { useState } from 'react';
import { useTransition, animated } from 'react-spring';
import ImageSlide from '../../components/ImageSlide';
import VideoPlayer from '../../components/VideoPlayer';
import ToolNavigation from '../../components/ToolNavigation';


// import './style.css';

let Unit = (props) => {
    let { isFullScreen, manageFullScreen, dimensions, manageToolData, engagementData,onSlideChange } = props;
    let { slideHeight, slideWidth } = dimensions;
    const [index, setIndex] = useState(0);

    const slides = [
        { id: 0, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Evolving+professionally/S05_EvoPro_02.PNG"} /> },
        { id: 1, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Evolving+professionally/S05_EvoPro_03.PNG"} /> },
        { id: 2, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Evolving+professionally/S05_EvoPro_04.PNG"} /> },
        { id: 3, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Evolving+professionally/S05_EvoPro_05.PNG"} /> },
        {
            id: 4, obj: <VideoPlayer
                imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Evolving+professionally/S05_EvoPro_06.PNG"}
                mediaUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Evolving+professionally/S05_EvolProf_Intro.mp4"} />
        },
        { id: 5, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Evolving+professionally/S05_EvoPro_07.PNG"} /> },
    ];

    const transitions = useTransition(slides[index], item => item.id, {
        from: { opacity: 0 }, enter: { opacity: 1 }, leave: { opacity: 0 },
    });
    // useEffect(() => { setIndex(sceneCount) }, [sceneCount]);

    return <div className='tool-holder'>
        <div className='slide-cntr' style={{ width: slideWidth, height: slideHeight }}>
            {transitions.map(({ item, props, key }) => (
                <animated.div
                    key={key}
                    className="slide"
                    style={{ ...props, position: 'absolute', width: slideWidth, height: slideHeight }}
                >
                    <div style={{ height: '100%' }}>{item.obj}</div>
                </animated.div>
            ))}
        </div>
        <ToolNavigation
            currentScene={index}
            sceneCount={slides.length}
            next={(index) => {
                manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index + 1 } });
                onSlideChange(engagementData);
                setIndex(index + 1);
            }}
            previous={(index) => {
                manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index - 1 } });
                onSlideChange(engagementData);
                setIndex(index - 1)
            }}
            isToolFullScreen={isFullScreen}
            toggleFullScreen={manageFullScreen}
        />
    </div>
}

export default Unit;