import { units } from "./d_units";
import { resources } from "./d_resources";
const { uo_001, uo_002, uo_003, uo_004, uo_005 } = units;
const { uo_006, uo_007, uo_008, uo_009, uo_010, uo_011 } = units;
const { uo_012, uo_013, uo_0014 } = units;
const { uo_012_h, uo_013_h, uo_0014_h } = units;
const { uo_014, uo_015, uo_016, uo_017, uo_018, uo_019, uo_020 } = units;
const { uo_0015, uo_0016, uo_0017, uo_0018, uo_0019, uo_0020, uo_0018_h, uo_0019_h, uo_0020_h } = units;
const { uo_0015_h, uo_0016_h, uo_0017_h } = units;
const { uo_021, uo_022, uo_023, uo_024, uo_025, uo_026, uo_027 } = units;
const { uo_028, uo_029, uo_030 } = units;
const { rs_001, rs_002, rs_003, rs_004, rs_005, rs_006, rs_007, rs_008 } = resources;
const { rs_009, rs_010, rs_0091, rs_0092 } = resources;
const { rs_011, rs_012, rs_013, rs_014, rs_015, rs_016, rs_017, rs_018, rs_019, rs_020, rs_021, rs_022, rs_023, rs_0222, rs_0223, rs_094, rs_095, rs_096 } = resources;
const { rs_024, rs_025, rs_026, rs_027, rs_028, rs_029, rs_030, rs_031, rs_032, rs_033, rs_034, rs_035, rs_036, rs_037, rs_038, rs_0224 } = resources;
const { rs_039, rs_040, rs_041, rs_042, rs_043, rs_044, rs_045, rs_046, rs_047, rs_048, rs_049, rs_0225 } = resources;
const { uo_031, uo_032, uo_033, uo_034 } = units;
const { rs_201, rs_202, rs_203, rs_204, rs_205 } = resources;
const { uo_035, uo_036, uo_037, uo_038, uo_0039 } = units;
const { rs_301, rs_302, rs_303, rs_304, rs_305 } = resources;

const { uo_0021, uo_0022, uo_0023, uo_0024, uo_0025, uo_0026 } = units;

const { uo_0211, uo_0212, uo_0213 } = units;
const { rs_0211, rs_0212, rs_0213, rs_0214, rs_0215, rs_0216, rs_0217, rs_0218, rs_0219, rs_0220, rs_0221, rs_097 } = resources;
const { experiments, write } = units;

const { uo_039, uo_040, uo_041 } = units;
const { rs_00039, rs_00040, rs_00041, rs_00042, rs_00043, rs_00044, rs_081, rs_082, rs_083 } = resources;

const { uo_042, uo_043, uo_044, uo_045 } = units;
const { rs_0041, rs_0042, rs_0043, rs_0044, rs_0045, rs_0046, rs_0047, rs_0048, rs_0049 } = resources;

const { uo_046, uo_047, uo_048, uo_049, uo_050 } = units;
const { rs_050, rs_051, rs_052, rs_053, rs_054, rs_076, rs_077, rs_078, rs_079, rs_080 } = resources;

const { uo_051, uo_052, uo_053, uo_054, uo_055, uo_056, uo_057, uo_058 } = units;
const { rs_056, rs_057, rs_058, rs_059, rs_060, rs_061, rs_062, rs_063, rs_064, rs_074 } = resources;

const { uo_059, uo_060, uo_061 } = units;
const { rs_065, rs_066, rs_067, rs_068 } = resources;

const { uo_066, uo_067, uo_068, uo_069 } = units;
const { rs_069, rs_070, rs_071, rs_072, rs_073 } = resources;

const { uo_070, uo_071, uo_072, uo_073, uo_074, uo_075 } = units;

const { uo_076, uo_077, uo_078 } = units;
const { rs_084, rs_085, rs_086, rs_087, rs_088, rs_089, rs_090, rs_091, rs_092, rs_093 } = resources;

const { uo_062, uo_063, uo_064, uo_065 } = units;

const { uo_079 } = units;

const { uo_080, uo_081, uo_082, uo_083, uo_084 } = units;
const { rs_098, rs_099, rs_100, rs_101, rs_102, rs_103, rs_104 } = resources;

export const organisers = {
  "os_001": { units: { uo_001, uo_002, uo_003, uo_004, uo_005 } },
  "os_002": { units: { rs_001, rs_002, rs_003, rs_004, rs_005 } },
  "os_003": { units: { uo_006, uo_007, uo_008, uo_009, uo_010, uo_011 } },
  "os_004": { units: { uo_012, uo_013 } },
  // "os_004": { units: { uo_012, uo_013, uo_0014, } },
  "os_004_h": { units: { uo_012_h, uo_013_h } },
  //"os_004_h": { units: { uo_012_h, uo_013_h, uo_0014_h, } },
  "os_005": { units: { rs_009, rs_0091, rs_0092 } },
  "os_006": { units: { rs_010 } },
  "os_0004": { units: { uo_0015, uo_0016 } },
  // "os_0004": { units: { uo_0015, uo_0016, uo_0017, } },
  "os_0004_h": { units: { uo_0015_h, uo_0016_h } },
  // "os_0004_h": { units: { uo_0015_h, uo_0016_h, uo_0017_h, } },
  "os_0005": { units: { rs_010 } },
  "os_0006": { units: { uo_0018, uo_0019 } },
  // "os_0006": { units: { uo_0018, uo_0019, uo_0020, } },
  "os_0006_h": { units: { uo_0018_h, uo_0019_h } },
  // "os_0006_h": { units: { uo_0018_h, uo_0019_h, uo_0020_h, } },
  "os_0007": { units: { rs_010 } },
  "os_007": { units: { uo_014, uo_015, uo_016, uo_017, uo_018, uo_019, uo_020 } },
  "os_008": {
    units: {
      rs_014, rs_015, rs_016, rs_017, rs_018, rs_019, rs_020,
      rs_021, rs_011, rs_012, rs_013, rs_022, rs_023, rs_0222, rs_0223,
      rs_094, rs_095, rs_096
    }
  },
  "os_009": { units: { uo_021, uo_022, uo_023, uo_024, uo_025, uo_026 } },
  "os_010": {
    units: {
      rs_024, rs_025, rs_026, rs_027, rs_028, rs_029, rs_030,
      rs_031, rs_032, rs_033, rs_034, rs_035, rs_036, rs_037, rs_038, rs_0224
    }
  },
  "os_011": { units: { uo_027, uo_028, uo_029, uo_030 } },
  "os_012": { units: { rs_039, rs_040, rs_041, rs_042, rs_043, rs_044, rs_045, rs_046, rs_047, rs_048, rs_049, rs_0225 } },
  "os_013": { units: { uo_031, uo_032, uo_033, uo_034 } },
  "os_014": { units: { rs_201, rs_202, rs_203, rs_204, rs_205 } },
  "os_015": { units: { uo_035, uo_036, uo_037, uo_038, uo_0039 } },
  "os_016": { units: { rs_301, rs_302, rs_303, rs_304, rs_305 } },

  "os_017": { units: { uo_0021, uo_0022, uo_0023, uo_0024, uo_0025, uo_0026 } },

  "os_018": { units: { uo_0211, uo_0212, uo_0213 } },
  "os_019": { units: { rs_0211, rs_0212, rs_0213, rs_0214, rs_0215, rs_0216, rs_0217, rs_0218, rs_0219, rs_0220, rs_0221, rs_097 } },

  "os_020": { units: { uo_039, uo_040, uo_041 } },
  "os_021": { units: { rs_00039, rs_00040, rs_00041, rs_00042, rs_00043, rs_00044, rs_081, rs_082, rs_083 } },

  "os_022": { units: { uo_042, uo_043, uo_044, uo_045 } },
  "os_023": { units: { rs_0041, rs_0042, rs_0043, rs_0044, rs_0045, rs_0046, rs_0047, rs_0048, rs_0049 } },

  "os_024": { units: { uo_046, uo_047, uo_048, uo_049, uo_050 } },
  "os_025": { units: { rs_050, rs_051, rs_052, rs_053, rs_054, rs_076, rs_077, rs_078, rs_079, rs_080 } },

  "os_026": { units: { uo_051, uo_052, uo_053, uo_054, uo_055, uo_056, uo_057, uo_058 } },
  "os_027": { units: { rs_056, rs_057, rs_058, rs_059, rs_060, rs_061, rs_062, rs_063, rs_064, rs_074 } },

  "os_028": { units: { uo_059, uo_060, uo_061 } },
  "os_029": { units: { rs_065, rs_066, rs_067, rs_068 } },

  "os_030_1": { units: { uo_062, uo_063, uo_064, uo_065 } },

  "os_030": { units: { uo_066, uo_067, uo_068, uo_069 } },
  "os_031": { units: { rs_069, rs_070, rs_071, rs_072, rs_073 } },

  "os_032": { units: { uo_070, uo_071, uo_072, uo_073, uo_074, uo_075 } },

  "os_033": { units: { uo_076, uo_077, uo_078 } },
  "os_034": { units: { rs_084, rs_085, rs_086, rs_087, rs_088, rs_089, rs_090, rs_091, rs_092, rs_093 } },

  "os_035": { units: { uo_079 } },

  "os_036": { units: { uo_080, uo_081, uo_082, uo_083, uo_084 } },
  "os_037": { units: { rs_098, rs_099, rs_100, rs_101, rs_102, rs_103, rs_104 } },

  "experiment": { units: { experiments, write } },
}