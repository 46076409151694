import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
// import Glide from '@glidejs/glide';
// import { Player } from 'video-react';
import Footer from '../../components/Footer'
// import {Input, InputLabel, FormControl, Button} from "@material-ui/core";
import "video-react/dist/video-react.css";
import Button from '@material-ui/core/Button';

import "./public.css";
// import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

let LoginLink = props => <Link to="./auth/login" {...props} />
let RegisterLink = props => <Link to="./auth/register" {...props} />

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  input: {
    display: 'none'
  }
});

let Public = class Public extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      registedMsg: 'hidden'
    };

    this.handleChange = prop => event => {
      this.setState({ [prop]: event.target.value });
    };

    this.handleMouseDownPassword = event => {
      event.preventDefault();
    };

    this.handleClickShowPassword = () => {
      this.setState({
        showPassword: !this.state.showPassword
      });
    };
  }

  render() {
    return <div style={{ overflow: 'auto' }}>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 72 }}>
        <div style={{ width: 116 }}>
          <div style={{ marginTop: -16 }}><img alt="logo-home" src="./images/assets/logo-home.svg" width={100} /></div>
          {/* <div style={{fontSize: 20, fontWeight: 700}}>Illumine Digital</div> */}
          {/* <div style={{ color: "#727272", fontSize: 14, textAlign: "right", paddingRight: 29 }}>A Digital Learning Platform by Illumine</div> */}
        </div>
        <div style={{ boxShadow: "0px 3px 6px #00000029", border: "1px solid #DDDDDD", display: "flex", justifyContent: "center", alignItems: "stretch" }}>
          <div style={{ height: 478, width: 404 }}>
            <img alt="hero-img-pgm" src="https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/design-collective.svg" height={478} width="auto" />
          </div>
          <div style={{ height: 478, width: 550, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <div>
              <div style={{ fontSize: 30, fontWeight: 500, padding: "40px 40px 20px" }}>We have moved!</div>
              <div style={{ fontSize: 20, padding: '10px 40px' }}>Visit <a style={{ fontSize: 20, backgroundColor: 'beige', padding: '10px' }} href="https://coaching.pedgog.in/auth/register">
                https://coaching.pedgog.in</a></div>
              <div style={{ margin: "20px 0px", padding: "0px 40px", fontSize: 14, fontStyle: 'italic' }}>
                Now available in a new platform that provides you with unique coaching and interactive experience.
              </div>
            </div>
            <div className="action-btn-cntr" style={{ textAlign: "center", alignItems: 'center', fontSize: '20px', padding: '40px 40px 20px' }}>
              Are you an existing user? &nbsp; &nbsp;
              <Button classes={{ root: "btn-root-light", label: "btn-text" }} component={LoginLink} style={{}}>Login</Button>
              {/* <Button classes={{ root: "btn-root", label: "btn-text" }} component={RegisterLink} style={{ width: "50%" }}>Register</Button> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  }
}

export default withStyles(styles)(Public)