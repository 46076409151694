import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import ImageSlide from '../../components/ImageSlide';
import ToolNavigation from '../../components/ToolNavigation';
import Eng from './trustEng';



let Unit = (props) => {
  let { isFullScreen, manageFullScreen, dimensions, manageToolData, engagementData ,onSlideChange} = props;
  let { slideHeight, slideWidth } = dimensions;
  const [index, setIndex] = useState(0);
  let engId = ['tslenp_trust_eng_0', 'tslenp_trust_eng_1', 'tslenp_trust_eng_2', 'tslenp_trust_eng_3', 'tslenp_trust_eng_4', 'tslenp_trust_eng_5', 'tslenp_trust_eng_6'];
  let questions = [
    { q_1: 'What are your strategies for building Trust Surplus with various stakeholders across the E&P system? Think of at least 10 strategies',q_2:'' },
    { q_1: '1.What does ‘maintaining personal integrity’ mean?', q_2: '2.From the strategies you identified earlier, select the ones related to this & fill in here' },
    { q_1: '1.What does ‘strengthening & respecting others’ mean?', q_2: '2.From the strategies you identified earlier, select the ones related to this & fill in here' },
    { q_1: '1.What does putting ‘collective purpose’ before ‘self-interest’ mean?', q_2: '2.From the strategies you identified earlier, select the ones related to this & fill in here' },
    { q_1: '1.What does ‘recognizing the boundedness of trust’ mean?', q_2: '2.From the strategies you identified earlier, select the ones related to this & fill in here' },
    { q_1: '1.What does ‘zero politics & transparency’ mean?', q_2: '2.From the strategies you identified earlier, select the ones related to this & fill in here' },
    { q_1: '1.What does aligning ‘word’ & ‘action’ mean?', q_2: '2.From the strategies you identified earlier, select the ones related to this & fill in here' },
  ];

  let subQuestions = [
    { q_1: "First think individually & put down a list of at least 10 strategies", q_2: "Then share with the class to fill in here" },
    { q_1: "Discuss to identify the relevant strategies. Fill only those here.", q_2: "Add any more relevant strategies here." }
  ];

  const slides = [
    { id: 0, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/S02_TRU_23.PNG"} /> },
    {
      id: 1, obj: <Eng textBoxId={engId[0]} allTextBoxId={engId} question={questions[0]} subQuestion={subQuestions[0]}
        manageTool={manageToolData} engagementData={engagementData}
        bgImg="https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/Images+for+tools/Slide24.PNG" />
    },
    { id: 2, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/S02_TRU_25.PNG"} /> },
    {
      id: 3, obj: <Eng textBoxId={engId[1]} allTextBoxId={engId} question={questions[1]} subQuestion={subQuestions[1]}
        manageTool={manageToolData} engagementData={engagementData}
        bgImg="https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/Images+for+tools/Slide26.PNG" />
    },
    {
      id: 4, obj: <Eng textBoxId={engId[2]} allTextBoxId={engId} question={questions[2]} subQuestion={subQuestions[1]}
        manageTool={manageToolData} engagementData={engagementData}
        bgImg="https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/Images+for+tools/Slide27.PNG" />
    },
    {
      id: 5, obj: <Eng textBoxId={engId[3]} allTextBoxId={engId} question={questions[3]} subQuestion={subQuestions[1]}
        manageTool={manageToolData} engagementData={engagementData}
        bgImg="https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/Images+for+tools/Slide28.PNG" />
    },
    {
      id: 6, obj: <Eng textBoxId={engId[4]} allTextBoxId={engId} question={questions[4]} subQuestion={subQuestions[1]}
        manageTool={manageToolData} engagementData={engagementData}
        bgImg="https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/Images+for+tools/Slide29.PNG" />
    },
    {
      id: 7, obj: <Eng textBoxId={engId[5]} allTextBoxId={engId} question={questions[5]} subQuestion={subQuestions[1]}
        manageTool={manageToolData} engagementData={engagementData}
        bgImg="https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/Images+for+tools/Slide30.PNG" />
    },
    {
      id: 8, obj: <Eng textBoxId={engId[6]} allTextBoxId={engId} question={questions[6]} subQuestion={subQuestions[1]}
        manageTool={manageToolData} engagementData={engagementData}
        bgImg="https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/Images+for+tools/Slide31.PNG" />
    },
    { id: 9, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/S02_TRU_32.PNG"} /> }

  ];

  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0 }, enter: { opacity: 1 }, leave: { opacity: 0 },
  });
  // useEffect(() => { setIndex(sceneCount) }, [sceneCount]);

  return <div className='tool-holder'>
    <div className='slide-cntr' style={{ width: slideWidth, height: slideHeight }}>
      {transitions.map(({ item, props, key }) => (
        <animated.div
          key={key}
          className="slide"
          style={{ ...props, position: 'absolute', width: slideWidth, height: slideHeight }}
        >
          <div style={{ height: '100%' }}>{item.obj}</div>
        </animated.div>
      ))}
    </div>
    <ToolNavigation
      currentScene={index}
      sceneCount={slides.length}
      next={(index) => {
        manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index + 1 } });
        onSlideChange(engagementData);
        setIndex(index + 1);
      }}
      previous={(index) => {
        manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index - 1 } });
        onSlideChange(engagementData);
        setIndex(index - 1)
      }}
      isToolFullScreen={isFullScreen}
      toggleFullScreen={manageFullScreen}
    />
  </div>
}

export default Unit;