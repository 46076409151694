import React from 'react';
import TextBox from '../../components/Textbox';
import infoImg from "./assets/Tool-LHS-TREE_IMG.png";

let textBlkStyle = { backgroundColor: '#FFF', borderRadius: 4, padding: 8, height: 165,overflow:'scroll' }
let sectionHeaderStyle = { color: 'rgb(230,71,8)', fontSize: '1.2rem', margin: '1rem 0rem 0.5rem', fontWeight: 'bold'}
let SelfDescription = ({story, manageTool,  engagementData}) => {
  return <div style={{ display: 'flex', backgroundColor: 'rgb(255,236,183)', height: '100%' }}>
    <div style={{display:'contents'}}>
      <img src={infoImg} style={{height:'100%', width: 'auto'}}/>
    </div>
    <div style={{ flexGrow: 1, padding: 24, display:'flex', flexDirection:'column' ,overflow:'scroll'}}>
      <div className='engagement'>ENGAGEMENT 2A</div>
      <div className='engagement-cntr'>
        <div className='being'>
          <div style={{...sectionHeaderStyle}}>1. What kind of outer fruits do we seek in life?</div>
          <div className='instructions'>Generate Examples</div>
          <div style={{ ...textBlkStyle }}>
            <TextBox  id='2A-1' initialData={engagementData["2A-1"]||null} processData={manageTool}/>
          </div>
        </div>
        <div className='function'>
          <div style={{...sectionHeaderStyle}}>2. What kind of inner fruits do we seek in life?</div>
          <div className='instructions'>Generate Examples</div>
          <div style={{ ...textBlkStyle }}>
            <TextBox  id='2A-2' initialData={engagementData["2A-2"]||null} processData={manageTool}/>
          </div>
        </div>
      </div>
    </div>


  </div>
}

export default SelfDescription;