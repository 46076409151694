import React from "react";


import zone_filled from './assets/zone_filled.png';
import discussion from './assets/discussion.png';

import './style.css'



export default ({ textBoxId, engagementData, isFullScreen, d_title, d_header, textBoxColor }) => {

  let instructionSize = '18px';
  let boxTextSize = '12px';

  if (isFullScreen) {
    instructionSize = '22px';
    boxTextSize = '14px';
  }

  let zone_data = []

  const displayItems = (textBoxId) => {
    let list = [];
    textBoxId.map((boxId) => {
      if (engagementData[boxId] !== null && engagementData[boxId] !== undefined) {
        engagementData[boxId].blocks.map((item, i) => {
          if (item.text !== "")
            list = [...list, item.text]
        })
        zone_data[textBoxId] = list;
      }
      console.log(textBoxId, zone_data[textBoxId])
    })
  }


  return <div style={{ height: '100%' }}>
    <div style={{ width: '100%', display: 'flex', backgroundColor: 'white' }}>
      <div style={{ height: '60px', width: '100%', padding: '2px 14px', lineHeight: '22px', display: 'flex' }}>
        <div style={{ fontWeight: 'bold', color: '#E64708', alignSelf: 'flex-end', fontSize: instructionSize }}>{d_header}</div>
      </div>
    </div>
    <div style={{ height: 'calc(100% - 60px)' }}>
      <div style={{ padding: '10px', width: '100%', display: 'flex', height: '50%', backgroundColor: '#FFF9CD' }}>
        {
          textBoxColor.map((ele, index) => {
            displayItems(textBoxId[index])
            return (
              <div key={index} style={{ display: 'flex', width: '33%', padding: '10px', height: '100%' }}>
                <div className='gtu_5_list-img' style={{ width: '100%', backgroundColor: ele, overflowWrap: 'anywhere' }}>
                  <div style={{ height: '22%' }}>
                    <div style={{ fontSize: boxTextSize, fontWeight: "bold", textAlign: 'center' }}>ZONE {index + 1}:</div>
                    <div style={{ fontSize: boxTextSize, fontWeight: "bold", textAlign: 'center' }}>{d_title[index]}</div>
                  </div>
                  <div style={{ overflowY: 'scroll', height: '78%' }}>
                    <ul>
                      {
                        zone_data[textBoxId[index]] ? zone_data[textBoxId[index]].map((ele, index) =>
                          // <div className='display-list-gtu-5' style={{ padding: '7px 0px' }} key={index}>{ele}</div>
                          <li key={index} style={{ display: 'flex', alignItems: 'center' }}>{ele}</li>
                        ) : null

                      }
                    </ul>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
      <div style={{ width: '100%', display: 'flex', height: '50%', padding: '5px 20px', justifyContent: 'space-between', backgroundColor: 'white' }}>
        <div style={{ width: '45%' }}><img src={zone_filled} height='100%' /></div>
        <div style={{ width: '45%', display: 'flex', justifyContent: 'flex-end' }}><img src={discussion} height='100%' /></div>
      </div>
    </div>
  </div >
}