
import React, { useState } from 'react';
import { useTransition, animated } from 'react-spring';
import ImageSlide from '../../components/ImageSlide';
import ToolNavigation from '../../components/ToolNavigation';

import GTUENG from "./gtu4Eng";

import chat_icon from "./assets/chat_icon.png";

// import './style.css';

let Unit = (props) => {
    let { isFullScreen, manageFullScreen, dimensions, manageToolData, engagementData, onSlideChange } = props;
    let { slideHeight, slideWidth } = dimensions;
    const trigQ = [
        { q_no: chat_icon, q: ["1.What is the cost of going through the events of life like Anil?", "2.What is the long term cost & payoff of going through the events of life like Sunil?"] },
    ];

    const [index, setIndex] = useState(0);
    const slides = [
        { id: 0, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://pedgog2.s3.ap-south-1.amazonaws.com/iBecome+FOS+program/M4-Success/FOS-M4-Ex3-S01.PNG"} /> },
        { id: 1, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://pedgog2.s3.ap-south-1.amazonaws.com/iBecome+FOS+program/M4-Success/FOS-M4-Ex3-S02.PNG"} /> },
        { id: 2, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://pedgog2.s3.ap-south-1.amazonaws.com/iBecome+FOS+program/M4-Success/FOS-M4-Ex3-S03.PNG"} /> },
        { id: 3, obj: <GTUENG readOnly={false} manageToolData={manageToolData} engagementData={engagementData} eventTools={true} /> },
        { id: 4, obj: <GTUENG readOnly={true} manageToolData={manageToolData} engagementData={engagementData} q_no={trigQ[0].q_no} trigQ={trigQ[0].q} /> },
        { id: 5, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://pedgog2.s3.ap-south-1.amazonaws.com/iBecome+FOS+program/M4-Success/FOS-M4-Ex3-S06.PNG"} /> }
    ];

    const transitions = useTransition(slides[index], item => item.id, {
        from: { opacity: 0 }, enter: { opacity: 1 }, leave: { opacity: 0 },
    });
    // useEffect(() => { setIndex(sceneCount) }, [sceneCount]);
    return <div className='tool-holder'>
        <div className='slide-cntr' style={{ width: slideWidth, height: slideHeight }}>
            {transitions.map(({ item, props, key }) => (
                <animated.div
                    key={key}
                    className="slide"
                    style={{ ...props, position: 'absolute', width: slideWidth, height: slideHeight }}
                ><div style={{ height: '100%' }}>{item.obj}</div></animated.div>
            ))}
        </div>
        <ToolNavigation
            currentScene={index}
            sceneCount={slides.length}
            next={(index) => {
                manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index + 1 } });
                onSlideChange(engagementData);
                setIndex(index + 1);
            }}
            previous={(index) => {
                manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index - 1 } });
                onSlideChange(engagementData);
                setIndex(index - 1)
            }}
            isToolFullScreen={isFullScreen}
            toggleFullScreen={manageFullScreen}
        />
    </div>
}

export default Unit;