import React, { useState } from 'react';
import { useTransition, animated } from 'react-spring';
import ImageSlide from '../../components/ImageSlide';
import ToolNavigation from '../../components/ToolNavigation';

import zone_1 from './assets/zone_1.png';
import zone_2 from './assets/zone_2.png';
import zone_3 from './assets/zone_3.png';
import DisplayingData from './DisplayingData';
import GTU_06_Eng from './GTU_06_Eng';


let Unit = (props) => {
  let { isFullScreen, manageFullScreen, dimensions,manageToolData, engagementData, onSlideChange } = props;
  let { slideHeight, slideWidth } = dimensions;
  const [index, setIndex] = useState(0);
  
  let instructions = ['Think together in the group to generate and fill-in as many contributions to self possible in your role as student.', 'Think together in the group to generate and fill-in as many contributions to your collectives as possible in your role as student.', 'Think together in the group to generate and fill-in as many contributions to society as possible in your role as student.'];

  let leftInfo = ['Contributions I can make to "MYSELF"', 'Contributions I can make to "COLLECTIVES" I am part of', 'Contributions I can make to "SOCIETY"'];
  let leftInfoQuestions = ['For every contribution identified, ask yourself how is it a contribution for self development?', 'For every contribution identified, ask yourself – ', 'For every contribution identified, ask yourself how is it a contribution to a larger society?'];
  let zone_2_left_points = ['Which collective is this contributing to?', 'And how is it a contribution for that collective?'];

  let rightTitle = ['As a student, zone 1 contributions I can make..', 'As a student, zone 2 contributions I can make..', 'As a student, zone 3 contributions I can make..'];

  let zone_1_textBox_questions = ['..to my self-development and capability building', '..in developing my talents and discovering new ones', '… to clarify my aspirations and vision of career', '…towards building my character, personality, self confidence'];
  let zone_2_textBox_questions = ['..to my institution', '…to any teams I’m part of', '…in the class', '…to committees/ clubs/ interest groups I’m in', '…to my family & friends', '...others'];
  let zone_3_textBox_questions = ['...to current social, cultural, or national issues', '…to the surrounding locality', '..to societal challenges I can work on in my career. What challenges?', '..to the field or domain I am specializing in', '..to shape the future destiny of our country. How?', '...others'];

  let zone_1_textBoxId = ['zone_1_textBox_1', 'zone_1_textBox_2', 'zone_1_textBox_3', 'zone_1_textBox_4'];
  let zone_2_textBoxId = ['zone_2_textBox_1', 'zone_2_textBox_2', 'zone_2_textBox_3', 'zone_2_textBox_4', 'zone_2_textBox_5', 'zone_2_textBox_6'];
  let zone_3_textBoxId = ['zone_3_textBox_1', 'zone_3_textBox_2', 'zone_3_textBox_3', 'zone_3_textBox_4', 'zone_3_textBox_5', 'zone_3_textBox_6'];

  let  textBoxColor = ['#FFC043','#FBA019','#EA7B00']

  let allTextBoxId = [zone_1_textBoxId, zone_2_textBoxId, zone_3_textBoxId];

  let d_header = 'This is the map of contributions we identified for our role as a student…';
  let d_title = ['Contributions to SELF', "Contributions to COLLECTIVES I'm part of", 'Contributions to SOCIETY'];

  const slides = [
    { id: 0, obj: <ImageSlide dimensions={ dimensions } imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Scope+of+contribution/unit_06_16.PNG"} /> },
    { id: 1, obj: <ImageSlide dimensions={ dimensions } imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Scope+of+contribution/unit_06_17.PNG"} /> },
    { id: 2, obj: <ImageSlide dimensions={ dimensions } imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Scope+of+contribution/unit_06_18.PNG"} /> },
    { id: 3, obj: <GTU_06_Eng textBoxId={zone_1_textBoxId} manageTool={manageToolData} isFullScreen={isFullScreen} leftImg={zone_1} engagementData={engagementData} instructions={instructions[0]} leftInfo={leftInfo[0]} leftInfoQuestions={leftInfoQuestions[0]} rightTitle={rightTitle[0]} textBoxQuestions={zone_1_textBox_questions} textBoxColor={textBoxColor[0]} /> },
    { id: 4, obj: <GTU_06_Eng textBoxId={zone_2_textBoxId} manageTool={manageToolData} isFullScreen={isFullScreen} leftImg={zone_2} engagementData={engagementData} instructions={instructions[1]} leftInfo={leftInfo[1]} leftInfoQuestions={leftInfoQuestions[1]} zone_2_left_points={zone_2_left_points} rightTitle={rightTitle[1]} textBoxQuestions={zone_2_textBox_questions} textBoxColor={textBoxColor[1]} /> },
    { id: 5, obj: <GTU_06_Eng textBoxId={zone_3_textBoxId} manageTool={manageToolData} isFullScreen={isFullScreen} leftImg={zone_3} engagementData={engagementData} instructions={instructions[2]} leftInfo={leftInfo[2]} leftInfoQuestions={leftInfoQuestions[2]} rightTitle={rightTitle[2]} textBoxQuestions={zone_3_textBox_questions} textBoxColor={textBoxColor[2]} /> },
    { id: 6, obj: <DisplayingData textBoxId={allTextBoxId} manageTool={manageToolData} isFullScreen={isFullScreen} engagementData={engagementData} d_header={d_header} d_title={d_title} textBoxColor={textBoxColor} /> },
  ];

  const transitions = useTransition( slides[index], item => item.id, {
    from: { opacity: 0 }, enter: { opacity: 1 }, leave: { opacity: 0 },
  });

  return <div className='tool-holder'>
    <div className='slide-cntr' style={{width: slideWidth, height: slideHeight}}>
    {transitions.map(({ item, props, key }) => (
      <animated.div
        key={key}
        className="slide"
        style={{ ...props, position:'absolute', width: slideWidth, height: slideHeight }}
      >
        <div style={{height: '100%'}}>{item.obj}</div>
      </animated.div>
    ))}
    </div>
    <ToolNavigation
      currentScene = {index}
      sceneCount = {slides.length}
      next={(index) => {
        manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index + 1 } });
        onSlideChange(engagementData);
        setIndex(index + 1);
      }}
      previous={(index) => {
        manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index - 1 } });
        onSlideChange(engagementData);
        setIndex(index - 1)
      }}
      isToolFullScreen = {isFullScreen}
      toggleFullScreen = {manageFullScreen}
      />
  </div>
}

export default Unit;