import React, { useState } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "./style.css"


let Textbox = ({ id, processData, initialData , customStyleWrapper , customStyleToolbar }) => {
  let a = initialData ? convertFromRaw(initialData) : null;
  let b = a ? EditorState.createWithContent(a) : EditorState.createEmpty();
  const [editorState, seteditorState] = useState(b);
  let onEditorStateChange = (_editorState) => {
    const contentState = _editorState.getCurrentContent();
    processData({ toolId: id, content: convertToRaw(contentState) });
    seteditorState(_editorState);
  };
  return <Editor
    editorState={editorState}
    toolbarClassName={customStyleToolbar ? customStyleToolbar : "textblk-toolbox"}
    wrapperClassName={customStyleWrapper ? customStyleWrapper : "textblk-wrapper"}
    editorClassName="textblk-editor"
    onEditorStateChange={onEditorStateChange}
    toolbar={{ options: ['list'] }}
  />
}

export default Textbox;