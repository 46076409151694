import React from "react";
import TextBox from '../../components/Textbox';
import "./trustEng.css";

let textBoxData = [];
export default ({ textBoxId, allTextBoxId, bgImg, engagementData, manageTool, question, subQuestion }) => {
  const displayItems = () => {
    let list = [];
    if (engagementData[textBoxId] !== null && engagementData[textBoxId] !== undefined) {
      engagementData[textBoxId].blocks.map((item) => {
        if (item.text !== "")
          list = [...list, item.text];
      })
    }
    textBoxData[textBoxId] = list;
  }

  displayItems();

  return <div style={{ height: '100%', backgroundColor: 'white' }}>
    <div style={{ width: '100%', display: 'flex' }}>
      <div style={{ height: '115px', padding: '18px', backgroundColor: '#e4e7ec', fontSize: '20px', fontWeight: 'bold', color: '#1F497D', width: '100%' }}>
        {question.q_1}
        {question.q_2 ? <br /> : null}
        {question.q_2}
        <br />
        <span style={{ fontSize: '14px', color: 'black', fontWeight: '400' }}>i.{subQuestion.q_1}</span>
        <br />
        <span style={{ fontSize: '14px', color: 'black', fontWeight: '400' }}>ii.{subQuestion.q_2}</span>
      </div>
    </div>
    <div style={{ display: "flex", padding: '8px', backgroundColor: '#e2f488', height: 'calc(100% - 155px)', borderRadius: '25px', boxShadow: 'rgb(195 195 195) 10px 10px 6px -6px', margin: '5px 15px 15px 15px' }}>
      <div style={{ padding: '0px 16px', width: '35%', textAlign: 'center' }}>
        <img src={bgImg} height='100%' />
      </div>
      <div style={{ padding: '20px', border: '3px solid black', width: '40%', flexGrow: '1', borderRadius: '24px', display: 'flex', flexDirection: 'column', backgroundColor: "#fdff9b" }}>
        <strong>
          Relevant strategies:
            </strong>
        <TextBox id={textBoxId} initialData={engagementData[textBoxId] || null} processData={manageTool} />
      </div>
      {textBoxId !== 'tslenp_trust_eng_0' ?
        <div className='trust_surplus_list' style={{ padding: '10px', width: '25%', flexGrow: '1', display: 'flex', flexDirection: 'column' }}>
          <strong style={{ fontSize: '14px' }}>
            Strategies identifed earlier:
          </strong>
          <ul className="scroll-bar" style={{ padding: '20px', overflowY: 'scroll', listStyleType: 'none' }}>
            {
              allTextBoxId.map((boxId) => {
                if (boxId !== textBoxId && textBoxData[boxId] !== null && textBoxData[boxId] !== undefined && textBoxData[boxId].length > 0)
                  return textBoxData[boxId].map((item, i) => <li key={i}>{item}</li>)
              })
            }
          </ul>
        </div> : null
      }
    </div>
    <div style={{ width: '100%', padding: '0px 15px', backgroundColor: 'white', fontWeight: 'bold' }}>
      PREPARED FOR TATA STEEL ENGINEERING & PROJECTS
      </div>
  </div>
}