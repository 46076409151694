import React from 'react';
import TextBox from '../../components/Textbox';
import infoImg from "./assets/BG.png";

let textBlkStyle = { backgroundColor: '#FFF', borderRadius: 4, padding: 8, height: 165, overflow: 'scroll' }
let sectionHeaderStyle = { color: 'rgb(230,71,8)', fontSize: '1.2rem', margin: '1rem 0rem 0.5rem', fontWeight: 'bold' }
let FruitMap = ({ story, manageTool, engagementData, id }) => {
  return <div className='fruit-map-cntr' style={{ display: 'flex', backgroundImage: `url(${infoImg})`, backgroundColor: 'rgb(255,236,183)', height: '100%', backgroundPositionX: '235px' }}>
    <div style={{ width: 500, padding: '28px 225px 32px 24px', overflow: 'scroll' }}>
      <div className='engagement'>ENGAGEMENT 3A</div>
      <div style={{ ...sectionHeaderStyle, color: '#000' }}>Case:</div>
      <div style={{ lineHeight: 1.5, color: '#222', fontWeight: 500 }}>{story}</div>
    </div>
    <div style={{ flexGrow: 1, padding: 24, display: 'flex', flexDirection: 'column', overflow: 'scroll' }}>
      <div style={{ display: 'flex', flexDirection: 'column', opacity: 0.925 }}>
        <div className='being'>
          <div style={{ ...sectionHeaderStyle }}>What outer fruits will it result in?</div>
          <div className='instructions'>Discuss & add fruits. Consider both (+) and (-) fruits</div>
          <div style={{ ...textBlkStyle }}>
            <TextBox id={`${id}-1`} initialData={engagementData[`${id}-1`] || null} processData={manageTool} />
          </div>
        </div>
        <div className='function'>
          <div style={{ ...sectionHeaderStyle }}>What inner fruits will it result in?</div>
          <div className='instructions'>Discuss & add fruits. Consider both positive and negative fruits</div>
          <div style={{ ...textBlkStyle }}>
            <TextBox id={`${id}-2`} initialData={engagementData[`${id}-2`] || null} processData={manageTool} />
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default FruitMap;