import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import "./eng.css";

import event1 from "./assets/event1.png";
import event2 from "./assets/event2.png";
import event3 from "./assets/event3.png";
import event4 from "./assets/event4.png";
import event5 from "./assets/event5.png";
import event6 from "./assets/event6.png";

import close_button from "./assets/close_button.png";

import event_slide_1 from './assets/event_slide_1.png';
import event_slide_2 from './assets/event_slide_2.png';
import event_slide_3 from './assets/event_slide_3.png';
import event_slide_4 from './assets/event_slide_4.png';
import event_slide_5 from './assets/event_slide_5.png';
import event_slide_6 from './assets/event_slide_6.png';

let eventSlides = [event_slide_1, event_slide_2, event_slide_3, event_slide_4, event_slide_5, event_slide_6];
let slideImg;
let eventIcons = [event1, event2, event3, event4, event5, event6];

export default function TransitionsModal({ pdf }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = (slide) => {
        setOpen(true);
        slideImg = slide;
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{ width: '100%', display: 'flex' }}>
            {pdf ? <p style={{ width: '100%', color: '#A47D00', fontWeight: '500', cursor: 'pointer' }} onClick={() => handleOpen(null)}><span style={{ fontSize: '32px', color: '#A47D00', textDecoration: "none" }}>&#x1F6C8;</span>List of Fruits</p> : <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                {
                    eventIcons.map((icon, index) => <img key={`img-${index}`} className='ripple' src={icon} style={{ width: '60px', height: '60px', cursor: 'pointer' }} onClick={() => handleOpen(eventSlides[index])} alt='Event' />)
                }
            </div>
            }
            <Modal
                className="modal"
                open={open}
                onClose={handleClose}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div style={{ border: 'none', outline: 'none' }}>
                        {pdf ?
                            <div>
                                <iframe src={pdf} width='1000px' height='500px'></iframe>
                                <img src={close_button} className="close-button" style={{ top: '10%', right: 'unset' }} onClick={handleClose} />
                            </div> :
                            <div>
                                <img src={slideImg} style={{ width: '1000px' }} />
                                <img src={close_button} className="close-button" onClick={handleClose} /></div>
                        }
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
