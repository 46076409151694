import React from "react";
import TextBox from '../../components/Textbox';
import "./eng.css";

export default ({ bgImg, engagementData, manageTool }) => {
  let textBoxId = 'enp_ownership_eng_4_4B';
  return <div style={{ height: '100%', backgroundColor: 'white' }}>
    <div style={{ height: '115px', width: '100%', padding: '24px', backgroundColor: '#e4e7ec', fontSize: '1.25rem', fontWeight: 'bold', color: '#264a69' }}>
      What practices &amp; engagement strategies should be adopted by any of us, if we want to demonstrate
      ‘Firm Ownership’?
  <br />
      <span style={{ fontSize: '14px', color: 'black', fontWeight: '400' }}>Open Discussion</span>
    </div>
    <div style={{ display: "flex", padding: '8px', backgroundColor: '#e2f488', height: 'calc(100% - 155px)', borderRadius: '25px', boxShadow: 'rgb(195 195 195) 10px 10px 6px -6px', margin: '5px 25px 15px 25px' }}>
      <div style={{ padding: '0px 16px', width: '45%' }}>
        <img style={{ height: '100%', width: 'auto' }} src={bgImg} />
      </div>
      <div style={{ padding: '20px', border: '3px solid black', flexGrow: '1', borderRadius: '24px', display: 'flex', flexDirection: 'column', backgroundColor: "#fdff9b" }}>
        <div>
          Enabling practices &amp; engagement strategies:
    </div>
        <TextBox id={textBoxId} initialData={engagementData[textBoxId] || null} processData={manageTool} />
      </div>
    </div>
    <div style={{ width: '100%', padding: '0px 15px', backgroundColor: 'white', fontWeight: 'bold' }}>
      PREPARED FOR TATA STEEL HR
    </div>
  </div>
}

