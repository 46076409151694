import React, { useState } from 'react';
import { useTransition, animated } from 'react-spring';
import ImageSlide from '../../components/ImageSlide';
import ToolNavigation from '../../components/ToolNavigation';

import Eng from './wfwEng';
// import './style.css';

let Unit = (props) => {
  let { isFullScreen, manageFullScreen, dimensions, manageToolData, engagementData, onSlideChange } = props;
  let { slideHeight, slideWidth } = dimensions;
  const [index, setIndex] = useState(0);

  const textBoxId = ['tslenp_wfw_box_1', 'tslenp_wfw_box_2'];
  const fillUpId = ['tslenp_wfw_fill_1', 'tslenp_wfw_fill_2'];

  const heading = 'Applying the ‘conscious practice’ approach to some work practices';

  const work_practices = {
    title: 'The Six Work Practices',
    data: ['Ownership',
      'Collaboration',
      'Responsiveness',
      'Becoming a Value Creator',
      'Evolving Professionally',
      'Growing in Aspirations']
  };


  const discuss = {
    title: 'Take 1-2 Work Practices to discuss –',
    data: ['What questions should we ask ourselves to reflect on our work and develop in the practice? (for e.g. Reflective questions for collaboration could be: Have I shared the vision enough? How can I make collaboration more effective and less time consuming? Can I improve my own involvement in collaborative projects?)',
      'What are some opportunities to practice it?']
  }

  const textBoxQuestions = ['Reflective questions:', 'Opportunity to Practice:'];


  const slides = [
    { id: 0, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Way+forward+workshop/Wayforw_09.PNG"} /> },
    { id: 1, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Way+forward+workshop/Wayforw_10.PNG"} /> },
    { id: 2, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Way+forward+workshop/Wayforw_11.PNG"} /> },
    {
      id: 3, obj: <Eng textBoxId={textBoxId} fillUpId={fillUpId} textBoxQuestions={textBoxQuestions} heading={heading} work_practices={work_practices} discuss={discuss}
        manageTool={manageToolData} engagementData={engagementData} isFullScreen={isFullScreen} />
    },
  ];

  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0 }, enter: { opacity: 1 }, leave: { opacity: 0 },
  });
  // useEffect(() => { setIndex(sceneCount) }, [sceneCount]);

  return <div className='tool-holder'>
    <div className='slide-cntr' style={{ width: slideWidth, height: slideHeight }}>
      {transitions.map(({ item, props, key }) => (
        <animated.div
          key={key}
          className="slide"
          style={{ ...props, position: 'absolute', width: slideWidth, height: slideHeight }}
        >
          <div style={{ height: '100%' }}>{item.obj}</div>
        </animated.div>
      ))}
    </div>
    <ToolNavigation
      currentScene={index}
      sceneCount={slides.length}
      next={(index) => {
        manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index + 1 } });
        onSlideChange(engagementData);
        setIndex(index + 1);
      }}
      previous={(index) => {
        manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index - 1 } });
        onSlideChange(engagementData);
        setIndex(index - 1)
      }}
      isToolFullScreen={isFullScreen}
      toggleFullScreen={manageFullScreen}
    />
  </div>
}

export default Unit;