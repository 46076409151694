import React from "react";
import TextBox from '../../components/Textbox';
import level_1 from './assets/level_1.png';
import question_image from './assets/question_image.png';
import pencil from './assets/pencil.png';



export default ({ textBoxId, manageTool, engagementData, topQuestion, toolQuestion, leftInfoTitle, leftInfo, leftBoardQuestion, leftBoardAnswer }) => {

  const displayTextBox = (i) => {
    return (
      <div style={{ borderRadius: '24px', display: 'flex', flexGrow: 1, flexDirection: 'column', width: '100%', padding: '10px', backgroundColor: "#DBEEF4", margin: '5px', border: '3px solid white' }}>
        <strong style={{ fontSize: '0.85rem' }}>
          {toolQuestion[i]}
        </strong>
        {console.log(textBoxId)}
        <TextBox id={textBoxId[i]} initialData={engagementData[textBoxId[i]] || null} processData={manageTool} />
      </div>
    )
  }


  return <div style={{ height: '100%' }}>
    <div style={{ width: '100%', display: 'flex', backgroundColor: 'white' }}>
      <div style={{ height: '80px', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <img src={level_1} width='150px' alt='leve_1' />
        <div style={{ padding: '24px', fontSize: '1.25rem', fontWeight: 'bold', color: '#E64708' }}>{topQuestion}</div>
        <img src={question_image} width='250px' alt='question_image' />
      </div>
    </div>
    <div style={{ display: "flex", backgroundColor: '#70BDD2', height: 'calc(100% - 80px)' }}>
      <div style={{ padding: '10px', width: '22%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', }}>
        <div style={{ color: 'white', fontWeight: '600' }}>
          <div style={{ color: 'white' }}>{leftInfoTitle}</div>
          <div style={{ color: 'white' }}>{leftInfo}</div>
        </div>
        <div style={{ padding: '25px', borderRadius: '16px', backgroundColor: "#FFFF99", position: 'relative' }}>
          <img src={pencil} style={{ position: 'absolute', width: '30px', height: '40px', top: '-15px', right: '0px' }} alt="pencil" />
          <div style={{ fontWeight: 'bold' }}>{leftBoardQuestion}</div>
          {
            leftBoardAnswer.map((ans, i) => <div key={i}>&#9679;&nbsp;&nbsp;&nbsp;{ans}</div>)
          }
        </div>
      </div>
      <div style={{ width: '78%', display: 'flex', flexDirection: 'column' }}>
        <div style={{ width: '100%', height: '50%', display: 'flex' }}>
          {displayTextBox(0)}
          {displayTextBox(1)}
        </div>
        <div style={{ width: '100%', height: '50%', display: 'flex' }}>
          {displayTextBox(2)}
          {displayTextBox(3)}
        </div>
      </div>
    </div>
  </div>
}