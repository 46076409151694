import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import "./MyCapsules.css";

function Capsule(props) {
  const { enumerate } = props;
  const {
    heading,
    title,
    description,
    icon,
    route,
    ready,
    icon_bg,
    resourceRoute,
    resourceOrganisers
  } = props.cardData;
  return (
    <div className='capsule-cntr'>
      {enumerate > 0
        ? <div className="card-enumeration">{enumerate}</div>
        : null}
      <div className='capsule-blk'>
        <div className='capsule-left'>
          <div className="ch-sub-title">{heading}</div>
          <div className="ch-title">{title}</div>
          <div className="ch-description">{description}</div>
          <div className='action-cntr'>
            {ready === true ? <Link to={`${route}/${Date.now()}`}><div className='action-btn active'>Run Session</div></Link> : <div className='action-btn disabled'>Coming Soon</div>}
            {ready === true && resourceOrganisers !== null ? <Link to={resourceRoute}><div className='action-btn active inverted' style={{ marginLeft: 16 }}>Prepare</div></Link> : null}
          </div>
        </div>
        <div className='capsule-right'>
          <img
            alt="home-img"
            src={icon
              ? icon
              : "https://s3.ap-south-1.amazonaws.com/illumineschool/homeimg1-op.jpg"}
            title="Illumine Awaken" /></div>
      </div>
    </div>
  );
}

Capsule.propTypes = {
  cardData: PropTypes.object.isRequired,
  enumerate: PropTypes.number
};

export default Capsule;