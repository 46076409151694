import React from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import channelData from "../../../modals/d_channels";
import { resources } from "../../../modals/d_resources";
import './resources.css';

let Resources = (props) => {
  let { engagementData, userState } = useSelector((state) => state.channelState);
  let auth = useSelector((state) => state.auth);
  let resourceId = props.match.params.resourceId;
  let units = channelData[resourceId].resourceOrganisers.units;
  let tags = {};
  console.log(engagementData);
  console.log(userState.engagements);
  Object.keys(units).forEach(key => {
    let resource = resources[key];
    if (!tags[resource.tag]) { tags[resource.tag] = {} }
    tags[resource.tag][key] = true;
  });
  return <div style={{ backgroundColor: '#fafafa' }}>
    <Header active='resources' />
    <div className='recource-cntr' style={{ margin: '2rem' }}>
      <div className='ch-title'>
        {`Preparation Support: ${channelData[resourceId].title}`}
      </div>
      {Object.keys(tags).map(tagId => {
        let tag = tags[tagId];
        return <div key={tagId} style={{ margin: "2rem 0rem" }}>
          <div style={{ margin: "1rem 0rem", fontSize: 16, lineHeight: 1.63, fontWeight: 500 }}>{tagId}</div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {Object.keys(tag).map(unitId => {
              let unit = resources[unitId];
              const {
                title,
                description,
                icon,
                route,
                ready,
                icon_bg,
                resourceRoute
              } = unit;
              return <div className='resource-cntr' key={unitId}>
                <div className='resource-blk'>
                  <div>
                    <div className="res-step-label">{title || null}</div>
                    <div className="res-step-label-desc">{description || null}</div>
                  </div>
                  <div className='action-cntr'>
                    {ready === true
                      ? <Link to={`/application/resource/${resourceId}/${unitId}` || 'route'}>
                        <div className='action-btn active inverted'>View</div></Link>
                      : <div className='action-btn disable'>Coming Soon</div>}
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      })}
    </div>
    <Footer />
  </div>
}

export default Resources;