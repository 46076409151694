import React, { Component } from 'react';
import { connect } from "react-redux";
import MultiPageTemplate from '../../../components/MultiPagePDFReader';
import DownloadablePDF from '../../../components/downloadable_pdf';
import VideoPlayer from '../../../components/VideoPlayer';
import { resources } from '../../../modals/d_resources';
import { updateReadingTracker } from '../channelDashboard/redux_actions';

// const isBrowser = () => typeof window !== 'undefined' && window.document !== undefined;
const duringLoad = <div className='loading-screen'>Loading...</div>

let Book = class Book extends Component {
  constructor(props) {
    super(props);
    let {uid} = props.bookStore.auth.user.user;
    let { groupId, resourceId } = props.match.params;
    updateReadingTracker({uid, groupId, resourceId, instanceId: Date.now()}); 
  }
  render() {
    let { resourceId } = this.props.match.params;
    let unit = resources[resourceId];
    if (unit) {
      return serveTemplate(unit) 
    }
    return <div>No Such Book exists</div>
  }
}

function serveTemplate(unit) {
  let src = unit.src, docRatio = unit.docRatio || null;
  if (unit.type = 'pdf_downloadable') {
    return <DownloadablePDF src={src} />
  } else if (unit.type = 'external_video') {
    return <div style={{width:560, height:315}}>
      <VideoPlayer mediaUrl={src}/>
      {/* <iframe width="560" height="315" 
      src={src} frameborder="0" 
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe> */}
    </div>
  } else {
    return <div id='_book_meta_cntr' className='_book_meta_cntr'>
      <MultiPageTemplate src={src} duringLoad={duringLoad} docRatio={docRatio} />
    </div>
  }
}
const mapStateToProps = state => ({ bookStore: state });
export default connect(mapStateToProps, null)(Book);