import React from 'react';
import PropTypes from 'prop-types';
import {
  StepLabel,
  StepButton,
  Step,
  Stepper
} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    backgroundColor: "transparent"
  }
});

class CourseStepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      activeStepId: null,
      completed: new Set(),
      skipped: new Set(),
      totalSteps: 0
    };
    this.handleStep = (key, index) => {
      let { referenceId } = props;
      this
        .props
        .onclick({ courseId: referenceId, value: key });
    }
  };
  static getDerivedStateFromProps(props, state) {
    let activeStepId = props.stepperstate.activeStepId;
    if (state.activeStepId !== activeStepId) {
      return {
        ...state,
        activeStepId
      }
    };
    return state;
  }

  render() {
    let { classes } = this.props;
    let { activeStepId } = this.state;
    let { units } = this.props.elements;
    return (
      <div className={classes.root}>
        <Stepper active={activeStepId} className="_stepper" nonLinear orientation="vertical" connector={null}>
          {Object
            .keys(units)
            .map((key, index) => {
              let unit = units[key];
              const props = {};
              return (
                <Step key={key} {...props} active={key === activeStepId} className={'-step' + (key === activeStepId ? ' active' : '')}>
                  <StepButton
                    onClick={() => {
                      this.handleStep(key, index)
                    }}
                    completed={this.state.completed[index]}
                    style={{
                      textAlign: "left", margin: 0
                    }}>
                    <StepLabel><span className="step-label">{unit.title}</span><br /><span className='step-label-time'>{unit.time}</span></StepLabel>
                    {/* <StepContent>
                      <Typography>{unit.description}</Typography>
                    </StepContent> */}
                  </StepButton>
                </Step>
              );
            })}
        </Stepper>
      </div>
    );
  }
}

CourseStepper.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(CourseStepper);