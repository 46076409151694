import React from "react";
import TextBox from '../../components/Textbox';
import "./wfwEng.css";

import dynamic_pic from './assets/dynamic_pic.png';
import chat_icon from './assets/chat_icon.png';

let textBoxData = [];
export default ({ textBoxId, fillUpId, textBoxQuestions, heading, work_practices, discuss, engagementData, manageTool, isFullScreen }) => {

  let headingFontSize = '24px';
  let discussFontSize = '13px';
  let boxTextHeight = '24px';
  let boxTextWidth = '42%';
  let fillupWidth = '56%';
  let discussMargin = '5px';

  if (isFullScreen) {
    headingFontSize = '34px';
    discussFontSize = '16px';
    boxTextHeight = '34px';
    boxTextWidth = '34%';
    fillupWidth = '66%';
    discussMargin = '10px';
  }

  let bulletPoints = ['a)', 'b)'];





  return <div style={{ height: '100%', backgroundColor: 'white', padding: '20px' }}>
    <div className='tslenp_wfw_heading' style={{ height: '60px', marginBottom: '18px', fontSize: headingFontSize, fontWeight: 'bold', color: '#C00000', width: '50%' }}>
      {heading}
    </div>
    <div style={{ display: 'flex', height: 'calc(100% - 60px)', width: '100%' }}>
      <div style={{ width: '31%' }}>
        <img src='https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Way+forward+workshop/Slide12.png' width='100%' height='100%' />
        {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ textAlign: 'center', marginRight: '40px', fontWeight: 'bold' }}>{work_practices.title}</div>
          <ul style={{ listStyleType: 'none', margin: '0px auto', fontSize: '14px' }}>
            {
              work_practices.data.map((item, i) => <li key={i}>{item}</li>)
            }
          </ul>
        </div> */}
      </div>
      <div style={{ width: '69%', height: '100%', marginLeft: '20px' }}>
        <div className='tslenp_wfw_list' style={{ height: '150px', position: 'relative', padding: '15px', backgroundColor: '#ECECEC' }}>
          <img src={chat_icon} style={{ width: '50px', position: 'absolute', top: '-20px', right: '0px' }} />
          <div>{discuss.title}</div>
          {
            discuss.data.map((item, i) => <div key={i} style={{ fontSize: discussFontSize, margin: discussMargin }}>{bulletPoints[i]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item}</div>)
          }
        </div>
        <div style={{ display: 'flex', height: 'calc(100% - 150px)', width: '100%' }}>
          {
            textBoxId.map((boxId, index) => {
              let fillupValue = engagementData[fillUpId[index]];
              return <div key={index} style={{ border: '0.5px solid black', margin: '10px 10px 0px 10px', display: 'flex', flexDirection: 'column', width: "50%" }}>
                <div style={{ padding: "10px 10px 0px 10px", lineHeight: boxTextHeight }}>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ fontSize: discussFontSize, fontWeight: 'bold', width: boxTextWidth }}>Work Practices:</div>
                    <input className='fillup' style={{ width: fillupWidth, fontSize: discussFontSize }}
                      value={fillupValue || ''}
                      onChange={(event) => {
                        engagementData[fillUpId[index]] = event.target.value;
                        manageTool({ toolId: fillUpId[index], content: engagementData[fillUpId[index]] });
                      }}
                    />
                  </div>
                  <div style={{ fontSize: discussFontSize }}>{textBoxQuestions[index]}</div>
                </div>
                <TextBox id={boxId} initialData={engagementData[boxId] || null} processData={manageTool} customStyleWrapper='gtu_5_wrapper' customStyleToolbar='gtu_5_toolbar' />
              </div>
            })
          }
        </div>
      </div>
    </div>
  </div>
}