import { organisers } from "./d_organisers";
import React from "react";
let mainRoute = "/application/channel";
let mainResourceRoute = "/application/resources";


const channels = {
  illumineCoachingExperiments_0001: {
    heading: "Illumine Experiments",
    title: "These are the set of units to demonstrate the engagement capabilities",
    ready: true,
    organisers: organisers.experiment,
    resourceOrganisers: organisers.os_002,
  },
  whyContribute: {
    heading: "Contributor Basics",
    title: "Why should I contribute?",
    cost: 250,
    excerpt: <div>This is the first of the 5-Module Contributor Basics Program Series for Tata Steel E&P – where participants explore - Who is a Contributor? And why should I contribute? This helps them answer the “what’s in it for me question”, before they embark on the journey of how to become a consistent contributor in the context of their work in E&P.</div>,
    description: "This is the first of the 5-Module Contributor Basics Program Series for Tata Steel E&P – where participants explore - Who is a Contributor? And why should I contribute? This helps them answer the “what’s in it for me question”, before they embark on the journey of how to become a consistent contributor in the context of their work in E&P.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_001,
    resourceOrganisers: organisers.os_002,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26PContext.png",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  },
  tslhr_ownership: {
    heading: "Future Ready Work Practices",
    title: "Ownership",
    cost: 250,
    excerpt: <div>This is the first of the 6-Module Future- Ready Work practices Series for Tata Steel HR – where participants discover the criticality of ‘ownership’ in order to be individually and collectively future-ready. They also become familiar with the ladder of ownership & build concrete practices to progress on this ladder.</div>,
    description: "This is the first of the 6-Module Future- Ready Work practices Series for Tata Steel HR – where participants discover the criticality of ‘ownership’ in order to be individually and collectively future-ready. They also become familiar with the ladder of ownership & build concrete practices to progress on this ladder.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Ownership/S01_OWN_23.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_013,
    resourceOrganisers: organisers.os_014,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Ownership/S01_OWN_22.jpg",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  },
  tslhr_collaboration: {
    heading: "Future-Ready Work Practices",
    title: "Collaboration",
    cost: 250,
    excerpt: <div>What is the future that is unfolding for the organization and why collaboration, where we learn to go beyond performing as individual managers or contributors, is key for organizational success? What practices we could bring into our way of working to put in place the conditions for smooth and strong collaboration? The module will help participants engage with such questions.</div>,
    description: "What is the future that is unfolding for the organization and why collaboration, where we learn to go beyond performing as individual managers or contributors, is key for organizational success? What practices we could bring into our way of working to put in place the conditions for smooth and strong collaboration? The module will help participants engage with such questions.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Collaboration/S02_COL_logo.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_015,
    resourceOrganisers: organisers.os_016,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Collaboration/PPT+images/S02_COLAB_01.PNG",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Collaboration/Video/HR_COL_S2_Part1_INTRO.mp4    ",
    offering: "A structured session tool that you can use for capacity building of trainers"
  }, acp_fdp_1: {
    heading: "The Awakened Citizen Program: Facilitator Development Program (Digital Version)",
    title: "Day 1 - Foundations Training for ACP Facilitators",
    cost: 250,
    excerpt: "The goal of Day 1 is to orient teachers to the foundations of the Awakened Citizen Program",
    description: "The goal of Day 1 is to orient teachers to the foundations of the Awakened Citizen Program",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Icon-Day+1.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_003,
    resourceOrganisers: organisers.os_005,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/DAY+1-Unit+0.png",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  }, acp_fdp_2: {
    heading: "The Awakened Citizen Program: Facilitator Development Program (Digital Version)",
    title: "Day 2 - Practice Training for Year 1 Facilitators",
    cost: 250,
    excerpt: "The goal of Day 2 is to enable teachers to practice and build confidence to conduct YEAR 1 of the Awakened Citizen Program, with guidance from the master facilitators.",
    description: "The goal of Day 2 is to enable teachers to practice and build confidence to conduct YEAR 1 of the Awakened Citizen Program, with guidance from the master facilitators.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Icon-Day+2.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_004,
    resourceOrganisers: organisers.os_006,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Day+2-Unit+0.png",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  },
  acp_fdp_3: {
    heading: "The Awakened Citizen Program: Facilitator Development Program (Digital Version)",
    title: "Day 2 - Practice Training for Year 2 Facilitators",
    cost: 250,
    excerpt: "The goal of Day 2 is to enable teachers to practice and build confidence to conduct YEAR 2 of the Awakened Citizen Program, with guidance from the master facilitators.",
    description: "The goal of Day 2 is to enable teachers to practice and build confidence to conduct YEAR 2 of the Awakened Citizen Program, with guidance from the master facilitators.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Icon-Day+2.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_0004,
    resourceOrganisers: organisers.os_0005,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Day+2-Unit+0.png",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  },
  acp_fdp_4: {
    heading: "The Awakened Citizen Program: Facilitator Development Program (Digital Version)",
    title: "Day 2 - Practice Training for Year 3 Facilitators",
    cost: 250,
    excerpt: "The goal of Day 2 is to enable teachers to practice and build confidence to conduct YEAR 3 of the Awakened Citizen Program, with guidance from the master facilitators.",
    description: "The goal of Day 2 is to enable teachers to practice and build confidence to conduct YEAR 3 of the Awakened Citizen Program, with guidance from the master facilitators.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Icon-Day+2.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_0006,
    resourceOrganisers: organisers.oos_0007,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Day+2-Unit+0.png",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  }, gtu_1: {
    heading: "Contributor Personality Development Program (CPDP)",
    title: "The Contributor Work Ideal",
    cost: 250,
    excerpt: "Discover a new work ideal – working as a “Future-ready Contributor” wherever you go. This will help you work more effectively and therefore perform better, especially in highly results-driven workplaces that demand value creation from every employee.",
    description: "Discover a new work ideal – working as a “Future-ready Contributor” wherever you go. This will help you work more effectively and therefore perform better, especially in highly results-driven workplaces that demand value creation from every employee.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/icons/unit_01.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_007,
    resourceOrganisers: organisers.os_008,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Who+is+Contributor/unit_01_01.PNG",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use to conduct a deep coaching session with your students"
  },
  gtu_2: {
    heading: "Contributor Personality Development Program (CPDP)",
    title: "Identity & Self-esteem",
    cost: 250,
    excerpt: "Build dynamic identities that give you the ability and confidence to be more agile and flexible, to adapt to the fast-changing demands of today’s organizations.",
    description: "Build dynamic identities that give you the ability and confidence to be more agile and flexible, to adapt to the fast-changing demands of today’s organizations.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/icons/unit_02.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_009,
    resourceOrganisers: organisers.os_010,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Contributor's+Identity/unit_02_01.PNG",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use to conduct a deep coaching session with your students"
  },
  gtu_3: {
    heading: "Contributor Personality Development Program (CPDP)",
    title: "Become a Creator of one’s destiny",
    cost: 250,
    excerpt: "Respond to the challenges of life as a ‘creator of destiny’; rather than allowing challenges overwhelm you and making you a ‘victim’ of your circumstances. This will not only help you shape your own career, but also help you take ownership & responsibility wherever you work – a key expectation in future workplaces.",
    description: "Respond to the challenges of life as a ‘creator of destiny’; rather than allowing challenges overwhelm you and making you a ‘victim’ of your circumstances. This will not only help you shape your own career, but also help you take ownership & responsibility wherever you work – a key expectation in future workplaces.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/icons/unit_03.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_011,
    resourceOrganisers: organisers.os_012,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Creator+of+Destiny/unit_03_01.PNG",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use to conduct a deep coaching session with your students"
  }, gtu_4: {
    heading: "Contributor Personality Development Program (CPDP)",
    title: "Achieving Sustainable Success",
    cost: 250,
    excerpt: "Learn to make better choices that can lead to long-term career fulfillment and sustainable, long-lasting success in your career lifetime (despite the ups & downs of life).",
    description: "Learn to make better choices that can lead to long-term career fulfillment and sustainable, long-lasting success in your career lifetime (despite the ups & downs of life).",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/icons/unit_04.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_018,
    resourceOrganisers: organisers.os_019,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/unit_04_01.PNG",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  }, gtu_5: {
    heading: "Contributor Personality Development Program (CPDP)",
    title: "Career Development Models",
    cost: 250,
    excerpt: "Open up different and even unconventional/ unusual career models possible, beyond the usual. This can open up new hidden opportunities such an environment offers. And also frees you from being pressurized by constraints of the job environment or a ‘herd mentality’ when making career choices.",
    description: "Open up different and even unconventional/ unusual career models possible, beyond the usual. This can open up new hidden opportunities such an environment offers. And also frees you from being pressurized by constraints of the job environment or a ‘herd mentality’ when making career choices.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/icons/unit_05.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_026,
    resourceOrganisers: organisers.os_027,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Career+Development/unit_05_01.PNG",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  }, gtu_6: {
    heading: "Contributor Personality Development Program (CPDP)",
    title: "Expanding contribution in every role",
    cost: 250,
    excerpt: "Discover a new way to grow in your career & take charge of your own career growth – as you discover your power to contribute in any role or job.",
    description: "Discover a new way to grow in your career & take charge of your own career growth – as you discover your power to contribute in any role or job.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Scope+of+contribution/06_ScopeContrib_icon.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_033,
    resourceOrganisers: organisers.os_034,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Scope+of+contribution/unit_06_01.PNG",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  },
  acp_fdp_3: {
    heading: "The Awakened Citizen Program: Facilitator Development Program (Digital Version)",
    title: "Day 2 - Practice Training for Year 2 Facilitators",
    cost: 250,
    excerpt: "The goal of Day 2 is to enable teachers to practice and build confidence to conduct YEAR 2 of the Awakened Citizen Program, with guidance from the master facilitators.",
    description: "The goal of Day 2 is to enable teachers to practice and build confidence to conduct YEAR 2 of the Awakened Citizen Program, with guidance from the master facilitators.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Icon-Day+2.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_0004,
    resourceOrganisers: organisers.os_0005,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Day+2-Unit+0.png",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  },
  acp_fdp_4: {
    heading: "The Awakened Citizen Program: Facilitator Development Program (Digital Version)",
    title: "Day 2 - Practice Training for Year 3 Facilitators",
    cost: 250,
    excerpt: "The goal of Day 2 is to enable teachers to practice and build confidence to conduct YEAR 3 of the Awakened Citizen Program, with guidance from the master facilitators.",
    description: "The goal of Day 2 is to enable teachers to practice and build confidence to conduct YEAR 3 of the Awakened Citizen Program, with guidance from the master facilitators.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Icon-Day+2.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_0006,
    resourceOrganisers: organisers.oos_0007,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Day+2-Unit+0.png",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  },
  acp_iwp: {
    heading: "Awakened Citizen Program",
    title: "Introductory Workshop for Principals",
    cost: 250,
    excerpt: "This workshop introduces the ‘Awakened Citizen Program’, to help school principals and authorities make an informed decision regarding opting for this program for their school.",
    description: "This workshop introduces the ‘Awakened Citizen Program’, to help school principals and authorities make an informed decision regarding opting for this program for their school.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Discovery+workshop/discovery_workshop_icon-06.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_017,
    resourceOrganisers: null,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Discovery+workshop/00_ACP_discovery_workshop.png",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  },

  tslhr_responsiveness: {
    heading: "Future-Ready Work Practices",
    title: "Responsiveness",
    cost: 250,
    excerpt: <div>In this module, help participants understand that an organization is a network of interconnected commitments & services, and why responsiveness is essential for this network to work efficiently and reliably. Introduce participants to 3 levels of responsiveness, which opens up the idea that one can respond at deeper levels to users’ needs.</div>,
    description: "In this module, help participants understand that an organization is a network of interconnected commitments & services, and why responsiveness is essential for this network to work efficiently and reliably. Introduce participants to 3 levels of responsiveness, which opens up the idea that one can respond at deeper levels to users’ needs.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Responsiveness/S03_RES_icon.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_020,
    resourceOrganisers: organisers.os_021,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Responsiveness/S03_RES_01.PNG",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Collaboration/Video/HR_COL_S2_Part1_INTRO.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  },

  trustSurplus: {
    heading: "Contributor Basics",
    title: "Building Trust Surplus in E&P",
    cost: 250,
    excerpt: <div>This is second of the 5-Module Contributor Basics Program Series which focuses on one of the 4 key levers of contribution in the E&P system i.e. building trust surplus.
    In this module, participants discover the notion of "trust surplus" and the necessity to build trust surplus in light of the organization's goals of becoming a future-ready & agile system. They also learn concrete approaches to build trust surplus with various stakeholders within the E&P system.
    </div>,
    description: "This is second of the 5-Module Contributor Basics Program Series which focuses on one of the 4 key levers of contribution in the E&P system i.e. building trust surplus. In this module, participants discover the notion of 'trust surplus' and the necessity to build trust surplus in light of the organization's goals of becoming a future-ready & agile system. They also learn concrete approaches to build trust surplus with various stakeholders within the E&P system.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/S02_TRU_icon.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_022,
    resourceOrganisers: organisers.os_023,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/S02_TRU_01.PNG",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Collaboration/Video/HR_COL_S2_Part1_INTRO.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  },
  tslhr_growing_pro: {
    heading: "Future-Ready Work Practices",
    title: "Growing in Aspirations",
    cost: 250,
    excerpt: <div>Why is aspiration so important? How does it help us go beyond the bubble of our comfort & self-satisfaction which can envelop even the best of us?  Using the ladder of growing aspirations help participants discover the power of aspirations. Explore how each subsequent level of aspiration can take us to newer and newer levels of personal & professional growth while infusing excitement into our work.</div>,
    description: "Why is aspiration so important? How does it help us go beyond the bubble of our comfort & self-satisfaction which can envelop even the best of us?  Using the ladder of growing aspirations help participants discover the power of aspirations. Explore how each subsequent level of aspiration can take us to newer and newer levels of personal & professional growth while infusing excitement into our work.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Growing+in+Aspirations/S06_ASP_icon.PNG",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_024,
    resourceOrganisers: organisers.os_025,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Growing+in+Aspirations/S06_ASP_01.PNG",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Collaboration/Video/HR_COL_S2_Part1_INTRO.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  },
  tslhr_valuecreation: {
    heading: "Future-Ready Work Practices",
    title: "Becoming a Value Creator",
    cost: 250,
    excerpt: <div>What does it mean to create 'value'? How is it different from efficiency parameters? What kind of thinking & work practices can help us grow as a value creator? Engage with these questions in this session.</div>,
    description: "What does it mean to create 'value'? How is it different from efficiency parameters? What kind of thinking & work practices can help us grow as a value creator? Engage with these questions in this session.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Value+creator/S04_VAL_icon.PNG",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_028,
    resourceOrganisers: organisers.os_029,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Value+creator/S04_VAL_01.PNG",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Collaboration/Video/HR_COL_S2_Part1_INTRO.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  },
  trustSurplus_bsli: {
    heading: "Contributor Basics",
    title: "Building Trust Surplus in Organisation",
    cost: 250,
    excerpt: <div>
      In this module, participants discover the notion of "trust surplus" and the necessity to build trust surplus in light of the organization's goals of becoming a future-ready & agile system. They also learn concrete approaches to build trust surplus with various stakeholders within the organisation.
    </div>,
    description: "In this module, participants discover the notion of \"trust surplus\" and the necessity to build trust surplus in light of the organization's goals of becoming a future-ready & agile system. They also learn concrete approaches to build trust surplus with various stakeholders within the organisation.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Value+creator/S04_VAL_icon.PNG",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_030_1,
    resourceOrganisers: organisers.os_023,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/BSLI/Trust_Surplus/Trust_Surplus_01.PNG",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Collaboration/Video/HR_COL_S2_Part1_INTRO.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  },

  // Evolving Professionally Culture lab

  tslhr_evolve: {
    heading: "Future-Ready Work Practices",
    title: "Evolving Professionally",
    cost: 250,
    excerpt: <div>How do we take a 'conscious development' approach to become world-class professional, and not just wait for years of experience help us grow? This module is an exploration into this journey of conscious growth in terms of scope and depth as professionals.</div>,
    description: "How do we take a 'conscious development' approach to become world-class professional, and not just wait for years of experience help us grow? This module is an exploration into this journey of conscious growth in terms of scope and depth as professionals.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Evolving+professionally/S05_EvoPro_icon.PNG",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_030,
    resourceOrganisers: organisers.os_031,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Evolving+professionally/S05_EvoPro_01.PNG",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Collaboration/Video/HR_COL_S2_Part1_INTRO.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  },

  // Day 1 - Foundations Training for ACP Facilitators – Eng-Hindi Version
  acp_fdp_1_hindi: {
    heading: "The Awakened Citizen Program: Facilitator Development Program (Digital Version)",
    title: "Day 1 - Foundations Training for ACP Facilitators – Eng-Hindi Version",
    cost: 250,
    excerpt: "The goal of Day 1 is to orient teachers to the foundations of the Awakened Citizen Program",
    description: "The goal of Day 1 is to orient teachers to the foundations of the Awakened Citizen Program",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Icon-Day+1.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_032,
    resourceOrganisers: null,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/DAY+1-Unit+0.png",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  }, acp_fdp_2_hindi: {
    heading: "The Awakened Citizen Program: Facilitator Development Program (Digital Version)",
    title: "Day 2 - Practice Training for Year 1 Facilitators - Eng-Hindi Version",
    cost: 250,
    excerpt: "The goal of Day 2 is to enable teachers to practice and build confidence to conduct YEAR 1 of the Awakened Citizen Program, with guidance from the master facilitators.",
    description: "The goal of Day 2 is to enable teachers to practice and build confidence to conduct YEAR 1 of the Awakened Citizen Program, with guidance from the master facilitators.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Icon-Day+2.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_004_h,
    resourceOrganisers: organisers.os_006,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Day+2-Unit+0.png",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  },
  acp_fdp_3_hindi: {
    heading: "The Awakened Citizen Program: Facilitator Development Program (Digital Version)",
    title: "Day 2 - Practice Training for Year 2 Facilitators - Eng-Hindi Version",
    cost: 250,
    excerpt: "The goal of Day 2 is to enable teachers to practice and build confidence to conduct YEAR 2 of the Awakened Citizen Program, with guidance from the master facilitators.",
    description: "The goal of Day 2 is to enable teachers to practice and build confidence to conduct YEAR 2 of the Awakened Citizen Program, with guidance from the master facilitators.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Icon-Day+2.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_0004_h,
    resourceOrganisers: organisers.os_0005,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Day+2-Unit+0.png",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  },
  acp_fdp_4_hindi: {
    heading: "The Awakened Citizen Program: Facilitator Development Program (Digital Version)",
    title: "Day 2 - Practice Training for Year 3 Facilitators - Eng-Hindi Version",
    cost: 250,
    excerpt: "The goal of Day 2 is to enable teachers to practice and build confidence to conduct YEAR 3 of the Awakened Citizen Program, with guidance from the master facilitators.",
    description: "The goal of Day 2 is to enable teachers to practice and build confidence to conduct YEAR 3 of the Awakened Citizen Program, with guidance from the master facilitators.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Icon-Day+2.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_0006_h,
    resourceOrganisers: organisers.oos_0007,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Day+2-Unit+0.png",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use for capacity building of trainers"
  },
  fos_demo: {
    heading: "Expanding our Vision of Success",
    title: "How to systematically build our Tree of Fulfillment",
    cost: 250,
    excerpt: "We know that success for us can mean both inner and outer fruits. How do we build our Tree of Fulfillment systematically? What is the method or approach for doing so?",
    description: "We know that success for us can mean both inner and outer fruits. How do we build our Tree of Fulfillment systematically? What is the method or approach for doing so?",
    badges: null,
    icon: "https://pedgog2.s3.ap-south-1.amazonaws.com/iBecome+FOS+program/M4-Success/FOS-M4-Thumbnail",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_035,
    resourceOrganisers: organisers.os_019,
    introSlide: "https://pedgog2.s3.ap-south-1.amazonaws.com/iBecome+FOS+program/M4-Success/FOS-M4-Ex3-S01.PNG",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  },
  tslhr_mod07: {
    heading: "Future-Ready Work Practices",
    title: "Future Ready Ideal – Way Forward Workshop",
    cost: 250,
    excerpt: "So far we have explored the 6 Work Practices of the Operating Ideal. In this session, participants consolidate their learning thus far and also get a view of what will happen next in the intervention. In short, it is a bridge session between Wave 1-Phase 1 and Wave 1-Phase 2 of the intervention.",
    description: "So far we have explored the 6 Work Practices of the Operating Ideal. In this session, participants consolidate their learning thus far and also get a view of what will happen next in the intervention. In short, it is a bridge session between Wave 1-Phase 1 and Wave 1-Phase 2 of the intervention.",
    badges: null,
    icon: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Way+forward+workshop/Wayforw_icon.PNG",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_036,
    resourceOrganisers: organisers.os_037,
    introSlide: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Way+forward+workshop/Wayforw_01.PNG",
    poster: "https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/WhyContribute-icon.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering: "A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  }
};

Object.keys(channels).forEach((key, i) => {
  channels[key].route = mainRoute + "/" + key;
});

Object.keys(channels).forEach((key, i) => {
  channels[key].resourceRoute = mainResourceRoute + "/" + key;
});

export default channels;