export const resources = {
  "rs_001": {
    title: 'Module 1: How-to-conduct Guide',
    description: 'This guide will help you systematically prepare to conduct & facilitate a group coaching session for this module. This includes slide-by-slide guidelines, tips for effective execution as well key message checklists.',
    type: 'pdf_downloadable',
    tag: 'Class Conduct Support',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/Guide-ENP-ContribBasics-Mod1-V1.pdf',
    time: 'NA',
    ready: true
  },
  "rs_002": {
    title: 'Reference Tool: Typical examples of outer fruits and inner fruits',
    description: 'For reference during any  Tree Building Exercise. Use it to trigger responses of participants as they fill in the tool.',
    type: 'pdf_downloadable',
    tag: 'Class Conduct Support',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/FruitRefList-V1.pdf',
    time: 'NA',
    ready: true
  },
  "rs_003": {
    title: 'Facilitation Best Practice Guide',
    description: 'Some best practices to help you effectively navigate & facilitate such discovery-based, digital sessions remotely.',
    type: 'pdf_downloadable',
    tag: 'Facilitation Support',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/DigitalFacilitation-BestPractices.pdf',
    time: 'NA',
    ready: true
  },
  "rs_004": {
    title: 'Idea Walkthrough: 2 aspects of our Personality',
    description: 'This AV is a further deep dive into the "2 aspects of our personality" - a key idea the participants engage with in Exploration 1 of the module.',
    type: 'external_video',
    tag: 'Concept Support',
    src: 'https://www.youtube.com/embed/8U3a5XJOYnc',
    time: 'NA',
    ready: true
  },
  "rs_005": {
    title: 'Concept Note: The cycle of Contribution: the life-force of a contributor’s Tree of Fulfillment',
    description: 'This micro-read is a further deep dive into this key idea that participants engage with in Exploration 3 of the module.',
    type: 'pdf_downloadable',
    tag: 'Concept Support',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/ToF-life-force.pdf',
    time: 'NA',
    ready: true
  },
  "rs_006": {
    title: 'FAQ: Can I act as a Contributor in an area of work that I am not interested in/ passionate about?',
    description: 'An insight on what actually \'prevents\' contribution',
    type: 'pdf_downloadable',
    tag: 'Concept Support',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/FAQ-1.pdf',
    time: 'NA',
    ready: true
  },
  "rs_007": {
    title: 'About the Contributor Basics Intervention in E&P',
    description: 'Program Guide for Coaches',
    type: 'pdf_downloadable',
    tag: 'Program Support',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/Guide-ENP-ContribBasics-Mod1-V1.pdf',
    time: 'NA',
    ready: true
  },
  "rs_008": {
    title: 'Practice in Learning Teams',
    description: 'Learning teams are a mechanism to help coaches practice & develop their ability to conduct a program effectively',
    type: 'pdf_downloadable',
    tag: 'Program Support',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/LearningTeamsSessionGuide.pdf',
    time: 'NA',
    ready: true
  },
  "rs_009": {
    title: 'Roadmap for Teacher Orientation (Digital) for Day 1',
    description: 'A quick snapshot of what to cover on the first day of facilitator development - which is common for all teachers i.e. Y1, Y2 and Y3.',
    type: 'pdf_downloadable',
    tag: 'Program Support',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Prepare+Section_FDPACP-D1.pdf',
    time: 'NA',
    ready: true
  },
  "rs_010": {
    title: 'Roadmap for Teacher Orientation (Digital) for Year 1 - Day 2',
    description: 'A quick snapshot of what to cover on Day 2 for facilitators of Y1 of ACP',
    type: 'pdf_downloadable',
    tag: 'Program Support',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Prepare+Section_FDPACP-D2.pdf',
    time: 'NA',
    ready: true
  },
  "rs_011": {
    title: 'Design Exercise Guide for Exploration 3 > Engagement 3B',
    description: 'Refer to this step-by-step guide while conducting Design Exercise in 3B to help students to design personal practices or work strategies by which human concern can be demonstrated.',
    type: 'pdf_downloadable',
    tag: 'Resources to conduct sessions effectively',
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Who+is+Contributor/design_guide/01-WhoisC-Eng3B.pdf",
    time: 'NA',
    ready: true
  },
  "rs_012": {
    title: 'Design Exercise Guide for Exploration 5 > Engagement 5B',
    description: 'Refer to this step-by-step guide while conducting Design Exercise in 5B to help students build 2 checklists for a team – a] 5 work practices to follow to enhance trust in the team, b] 5 behaviors to avoid that can break trust in the team.',
    type: 'pdf_downloadable',
    tag: 'Resources to conduct sessions effectively',
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Who+is+Contributor/design_guide/01-WhoisC-Eng5B.pdf",
    time: 'NA',
    ready: true
  },
  "rs_013": {
    title: 'Design Exercise Guide for Exploration 6',
    description: 'Refer to this step-by-step guide while conducting Design Exercise in exploration 6 to help students to build a Contributor’s Checklist for themselves, in their chosen profession or work role.',
    type: 'pdf_downloadable',
    tag: 'Resources to conduct sessions effectively',
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Who+is+Contributor/design_guide/01-WhoisC-Eng6.pdf",
    time: 'NA',
    ready: true
  },
  "rs_014": {
    title: "Get a Quick Walkthrough of the Module (~25 min video)",
    description: "This video walkthrough will help you understand the goals and outcomes of this module, how it is organized, and how to systematically conduct it for your students.",
    type: "external_video",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Who+is+Contributor/Videos/Quick_Walkthrough.mp4",
    time: null,
    ready: true
  },
  "rs_015": {
    title: "Demo Session for Exploration 1: Future of Work",
    description: "This is a live demonstration of a session-in-action (~20 min video). Watch and learn how to conduct guided discussions with your students to help them identify the challenges & opportunities they are likely to face in the future workplace.",
    type: "external_video",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Who+is+Contributor/Videos/Exp_01_The_future_of_work.mp4",
    time: null,
    ready: true
  },
  "rs_016": {
    title: "Demo Session for Exploration 2: Results-orientation and making things happen",
    description: "This is a live demonstration of a session-in-action (~20 min video). Watch and learn how to conduct an experience sharing exercise to motivate students to be result-oriented and take responsibilities for making things happen",
    type: "external_video",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Who+is+Contributor/Videos/Exp_02_Results-orientation_making_things_happen.mp4",
    time: null,
    ready: true
  },
  "rs_017": {
    title: "Demo Session for Exploration 03: Demonstrating Human Concern",
    description: "This is a live demonstration of a session-in-action (~20 min video). Watch and learn how you can use real-life examples and conduct a design exercise to help students appreciate human concern as an important tool to strengthen people around him.",
    type: "external_video",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Who+is+Contributor/Videos/Exp_03_Demonstrating_human_concern.mp4",
    time: null,
    ready: true
  },
  "rs_018": {
    title: "Demo Session for Exploration 04: Excellence in work",
    description: "This is a live demonstration of a session-in-action (~20 min video). Watch and learn how you can use real-life examples and conduct a recognition exercise, to help students appreciate what is an ‘attitude of ‘excellence’ and why it is important be a future-ready contributor.",
    type: "external_video",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Who+is+Contributor/Videos/Exp_04_Excellence_in_work.mp4",
    time: null,
    ready: true
  },
  "rs_019": {
    title: "Demo Session for Exploration 05: Acting in a way that builds trust",
    description: "This is a live demonstration of a session-in-action (~20 min video). Watch and learn how you can use real-life examples and conduct and a design exercise, to help students learn the importance of building trust for achieving the unified goals of an organization.",
    type: "external_video",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Who+is+Contributor/Videos/Exp_05_Acting_in_way_that_builds_trust.mp4",
    time: null,
    ready: true
  },
  "rs_020": {
    title: "Demo Session for Exploration 06: The Contributor’s Checklist",
    description: "This is a live demonstration of a session-in-action (~20 min video). Watch and learn how you can conduct a design exercise to help students learn how to use a simple checklist to begin acting as a future-ready contributor in all situations in their life.",
    type: "external_video",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Who+is+Contributor/Videos/Exp_06_The_contributor's_checklist.mp4",
    time: null,
    ready: true
  },
  "rs_021": {
    title: "Demo Session for Exploration 07: Closure Reflection",
    description: "This is a live demonstration of a session-in-action (~20 min video). Watch and learn how you can conduct a closure reflection exercise to consolidate the difference between working as a future ready Contributor and a ‘worker’.",
    type: "external_video",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Who+is+Contributor/Videos/Exp_07_Closure_Reflection.mp4",
    time: null,
    ready: true
  },
  "rs_022": {
    title: "How to ensure discovery & acceptance of a new ideal, not just ‘conceptual understanding’?",
    description: "Here is a video (from a live Teacher Training) which talks about the 4 pillars or shifts that a faculty can make in their teaching approach to meet this outcome.",
    type: "external_video",
    tag: "Improve your engagement as a facilitator",
    src: "https://www.youtube.com/embed/A1LUnlEBJ2s",
    time: null,
    ready: true
  },
  "rs_023": {
    title: "How to bridge the ‘theory’ and ‘practice’ gap?",
    description: "Often students ‘know’ and ‘understand’ that they need to be a Contributor – but it doesn’t really lead to any real change in their thinking or behavior. How do you bridge that gap? This teacher training video explores this question.",
    type: "external_video",
    tag: "Improve your engagement as a facilitator",
    src: "https://www.youtube.com/embed/P5dr756Y-PI",
    time: null,
    ready: true
  },
  "rs_024": {
    title: 'Get a Quick Overview of the Module 2',
    description: 'In this document, understand the goals and outcomes of this module, how it is organized, and how to systematically conduct it for your students.',
    type: 'pdf_downloadable',
    tag: 'Resources to conduct sessions effectively',
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Contributor's+Identity/Resources/Self-Esteem%26Identity_Goals_v2.pdf",
    time: 'NA',
    ready: true
  },
  "rs_025": {
    title: "Demo Session for Exploration 01: What is Identity?",
    description: "This is a live demonstration of a session-in-action (~40 min video). Watch and learn how you can conduct systematic discussions to help students appreciate what is the meaning of “identity” and why becoming aware of our own “identity” & developing it, is important if we want to become future-ready.",
    type: "external_video",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Contributor's+Identity/Videos/01+What+is+identity1.mp4",
    time: null,
    ready: true
  },
  "rs_026": {
    title: "Demo Session for Exploration 02: Dynamic Identity 1: Defining myself in terms of the difference I can make",
    description: "This is a live demonstration of a session-in-action (~15 min video). Watch and learn how you can help students appreciate the first dynamic identity (i.e. an identity based on the contribution they can make in the world) in contrast to a static identity they need to avoid getting trapped in (i.e. an identity based on qualifications, degrees, etc.).",
    type: "external_video",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Contributor's+Identity/Videos/02+Dynamic+identity+1.mp4",
    time: null,
    ready: true
  },
  "rs_027": {
    title: "Demo Session for Exploration 03: Dynamic Identity 2: Defining myself in terms of responsibilities I take ownership for",
    description: "This is a live demonstration of a session-in-action (~20 min video). Watch and learn how you can you use real-life examples and a design exercise;to help students appreciate the second dynamic identity that will help their self-confidence grow with a sense of ownership & responsibility in a world where positions & hierarchies are breaking down.",
    type: "external_video",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Contributor's+Identity/Videos/03+Dynamic+identity+2.mp4",
    time: null,
    ready: true
  },
  "rs_028": {
    title: "Demo Session for Exploration 04: Dynamic Identity 3: Defining myself in terms of my future possibilities",
    description: "This is a live demonstration of part 1 of the session-in-action (~10 min video). Watch and learn how you can use a scenario based example and a design exercise to enable students to appreciate the value of another dynamic identity based on who they can “become” rather than be limited by who they are today.",
    type: "external_video",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Contributor's+Identity/Videos/04+Dynamic+identity+3.mp4",
    time: null,
    ready: true
  },
  "rs_029": {
    title: "Demo Session for Exploration 05: Dynamic Identity 4: The Learner Identity",
    description: "This is a live demonstration of a session-in-action (~20 min video). Watch and learn how you can use real-life examples and a design exercise, to help students appreciate the 'Learner' identity which allows them to engage positively with all the new knowledge & skills that are rapidly emerging in the world.",
    type: "external_video",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Contributor's+Identity/Videos/05+Dynamic+identity+4.mp4",
    time: null,
    ready: true
  },
  "rs_030": {
    title: "Exploration 06: Becoming future-ready by developing a dynamic identity",
    description: "This is a live demonstration of a session-in-action (~15 min video). Watch and learn how you can help students reflect and re-examine their own identities and try to describe themselves in new light i.e. in terms of the 4 dynamic identities explored.",
    type: "external_video",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Contributor's+Identity/Videos/06+Becoming+FR.mp4",
    time: null,
    ready: true
  },
  "rs_031": {
    title: "Design Exercise Guide for Exploration 2 > Engagement 2B",
    description: "Refer to this step-by-step guide while conducting Design Exercise in 2B to help students to visualize and build a self-description based on the difference they can make in the world in a specific profession or role.",
    type: 'pdf_downloadable',
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Contributor's+Identity/design_guide/GTU-DesignGuide_S2_Eng2-B.pdf",
    time: 'NA',
    ready: true
  },
  "rs_032": {
    title: "Design Exercise Guide for Exploration 3 > Engagement 3B",
    description: "Refer to this step-by-step guide while conducting Design Exercise in 3B to help students to visualize and describe contrasting behaviors and actions of people when they operate from a dynamic identity as opposed to static identity in any given position of responsibility.",
    type: "pdf_downloadable",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Contributor's+Identity/design_guide/GTU-DesignGuide_S2_Eng3-B.pdf",
    time: 'NA',
    ready: true
  },
  "rs_033": {
    title: "Design Exercise Guide for Exploration 4 > Engagement 4B",
    description: "Refer to this step-by-step guide while conducting Design Exercise in 3B to help students to build a self-description of their “Future Self” i.e. describe themselves in terms of their future possibilities.",
    type: "pdf_downloadable",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Contributor's+Identity/design_guide/GTU-DesignGuide_S2_Eng4-B-v2.pdf",
    time: 'NA',
    ready: true
  },
  "rs_034": {
    title: "Concept Note: A dynamic identity is a future-facing identity",
    description: "This note further clarifies how a dynamic identity based on future possibilities, enables people to be more responsive, adaptive, and evolve.",
    type: "pdf_downloadable",
    tag: "Go Deeper into the core ideas",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Contributor's+Identity/Resources/A+dynamic+identity+is+a+future-facing+identity_v2.pdf",
    time: 'NA',
    ready: true
  },
  "rs_035": {
    title: "FAQ: Idea Clarification - A dynamic identity is flexible, not fickle",
    description: "This note clarifies a frequently asked doubt in people's mind and differentiates between being fickle & being agile and flexible.",
    type: "pdf_downloadable",
    tag: "Go Deeper into the core ideas",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Contributor's+Identity/Resources/A+dynamic+identity+is+flexible%2C+not+fickle_v2.pdf",
    time: 'NA',
    ready: true
  },
  "rs_036": {
    title: "Concept Note: What happens when one has a static ‘expert identity’ based on ‘I know’",
    description: "This note goes deeper into the consequences of 'I know’ identity and how it makes your personality static and refrains you from devolving further.",
    type: "pdf_downloadable",
    tag: "Go Deeper into the core ideas",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Contributor's+Identity/Resources/What+happens+when+one+has+a+static+expert+identity+based+on+I+know_v2.pdf",
    time: 'NA',
    ready: true
  },
  "rs_037": {
    title: "Idea Video: The importance of Identity in one’s life and career",
    description: "This video (~2 min) is a talk by Mr. V. Srinivas explaining about the importance of identity in one’s life and impacts our response to life situations.",
    type: "external_video",
    tag: "Go Deeper into the core ideas",
    src: "https://www.youtube.com/embed/1De4HS6MMMs",
    time: null,
    ready: true
  },
  "rs_038": {
    title: "Idea AV: How a Dynamic Identity is born",
    description: "This video (~5 min) show how the 2 aspects of our personality ‘Being‘ and ‘Function’ connect together to define our identity and make us future ready.",
    type: "external_video",
    tag: "Go Deeper into the core ideas",
    src: "https://www.youtube.com/embed/8U3a5XJOYnc",
    time: null,
    ready: true
  },
  "rs_039": {
    title: "A Guide on the goals of different explorations of the Module",
    description: "In this document, understand the goals and outcomes of this module, how it is organized, and how to systematically conduct it for your students.",
    type: "pdf_downloadable",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Creator+of+Destiny/Design+Guide/Mod3-Goals-COD.pdf",
    time: null,
    ready: true
  },
  "rs_040": {
    title: "Exploration 01: Who is a Creator of Destiny?",
    description: "This is a live demonstration of a session-in-action (~50 min video). Watch and learn how you can conduct systematic discussions to help students recognize the difference between the behaviors of a person who responds to life in a ‘victim mode’ as opposed to ‘creator of destiny mode’.",
    type: "external_video",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Creator+of+Destiny/Videos/01_Who+is+a+Creator+of+Destiny.mp4",
    time: null,
    ready: true
  },
  "rs_041": {
    title: "Exploration 02: What mode of response do future workplaces demand?",
    description: "This is a live demonstration of a session-in-action (~50 min video). Watch and learn how you can conduct systematic discussions to familiarize students with the demands of the future workplaces and how a Creator mode of response is extremely important to survive & thrive in today’s work environment.",
    type: "external_video",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Creator+of+Destiny/Videos/02_What+mode+of+response.mp4",
    time: null,
    ready: true
  },
  "rs_042": {
    title: "Exploration 03: Living as a Creator of Destiny",
    description: "This is a live demonstration of a session-in-action (~20 min video). Watch and learn how you can use real-life, career stories along with design exercises to show students how they can choose to live as a creator of their own destiny and transform any circumstances to become a future-ready contributor wherever they go.",
    type: "external_video",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Creator+of+Destiny/Videos/03_Living+as+a+Creator+of+Destiny.mp4",
    time: null,
    ready: true
  },
  "rs_043": {
    title: "Exploration 04: In your career, what is your typical mode of response?",
    description: "This is a live demonstration of a session-in-action (~8 min video). Watch and learn how using coach students through a self-reflection exercise followed by de-briefing to introspect and identify their own typical mode of response and help them identify points of improvement towards becoming a Creator of Destiny in their own life and careers.",
    type: "external_video",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Creator+of+Destiny/Videos/04_In+your+career.mp4",
    time: null,
    ready: true
  },
  "rs_044": {
    title: "Design Exercise Guide for Exploration 3 > Engagement 3A.2",
    description: "Refer to this step-by-step guide while conducting Design Exercise in 3A, which enables students to design responses that leverage what they have to transform a situation where they feel powerless and constrained.",
    type: "pdf_downloadable",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Creator+of+Destiny/Design+Guide/GTU-DesignGuide_S3_Eng3A.2.pdf",
    time: null,
    ready: true
  },
  "rs_045": {
    title: "Design Exercise Guide for Exploration 3 > Engagement 3B",
    description: "Refer to this step-by-step guide while conducting Design Exercise in 3B which enables students to develop strategies/ approaches where they can take responsibility to find solutions to create meaning in their career & work life.",
    type: "pdf_downloadable",
    tag: "Resources to conduct sessions effectively",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Creator+of+Destiny/Design+Guide/GTU-DesignGuide_S3_Eng3-B.pdf",
    time: null,
    ready: true
  },
  "rs_046": {
    title: "Foundation Note: From Victim to creator of our destiny stance to career",
    description: "This note clarifies how you perceive the same work and career environment differently by shifting to a ‘creator of destiny’ mode from a ‘victim’ mode.",
    type: "pdf_downloadable",
    tag: "Go deeper into the core ideas",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Creator+of+Destiny/Design+Guide/03_FN_VictimToCreator.pdf",
    time: null,
    ready: true
  },
  "rs_047": {
    title: "Concept Note: Contributors don’t feel powerless; they take charge and make things happen",
    description: "This note points out how a contributor develops confidence by taking up challenges and responsibilities for meeting outcomes.",
    type: "pdf_downloadable",
    tag: "Go deeper into the core ideas",
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Creator+of+Destiny/Design+Guide/Contributors+don't+feel+powerless_zonesFreedom-branded.pdf",
    time: null,
    ready: true
  },
  "rs_048": {
    title: "Idea Video: A Contributor shifts from a victim to a creator of destiny",
    description: "This video is a talk by Mr. V. Srinivas explaining about qualities of a victim we need to leave out in order to become a future ready contributor.",
    type: "external_video",
    tag: "Go deeper into the core ideas",
    src: "https://www.youtube.com/embed/vaYYFPoUVU8",
    time: null,
    ready: true
  },
  "rs_049": {
    title: "Idea Video: A contributor responds from inner aspiration, not outside pressure",
    description: "This video is a talk by Mr. V. Srinivas explaining how through your own inner aspirations and drive to grow, you can jump a level up and above, to contribute much more to the society.",
    type: "external_video",
    tag: "Go deeper into the core ideas",
    src: "https://www.youtube.com/embed/tK33Ou3b_qE",
    time: null,
    ready: true
  },
  "rs_111": {
    title: 'Roadmap for Teacher Orientation (Digital) for Year 2 - Day 2',
    description: 'A quick snapshot of what to cover on Day 2 for facilitators of Y2 of ACP',
    type: 'pdf_downloadable',
    tag: 'Program Support',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Prepare+Section_FDPACP-D2.pdf',
    time: 'NA',
    ready: true
  },
  "rs_112": {
    title: 'Roadmap for Teacher Orientation (Digital) for Year 3 - Day 2',
    description: 'A quick snapshot of what to cover on Day 2 for facilitators of Y1 of ACP',
    type: 'pdf_downloadable',
    tag: 'Program Support',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/Prepare+Section_FDPACP-D2.pdf',
    time: 'NA',
    ready: true
  },
  "rs_201": {
    title: 'A Guide on the goals of different explorations of the Module:',
    description: 'In this document, understand the goals and outcomes of this module, how it is organized, and how to systematically conduct it for the participants.',
    type: 'pdf_downloadable',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Ownership/Ownership_Goals+walkthrough.pdf',
    time: 'NA',
    ready: true
  },
  "rs_202": {
    title: 'Exploration 01: Introduction: What is Ownership?',
    description: 'This is a live demonstration of a session-in-action (~20 min video). Watch how you can conduct systematic discussions to help the group collectively form its own interpretation of ownership, and explore its criticality in a future-ready work environment.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Ownership/Video/HR_OWN_S1_01.mp4',
    time: 'NA',
    ready: true
  },
  "rs_203": {
    title: 'Exploration 02: Level 1: Role Ownership',
    description: 'This is a live demonstration of a session-in-action (~40 min video). Watch how you can conduct systematic discussions to help the group vividly visualize what role ownership involves and come up with concrete practices which can enable them to own their role completely.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Ownership/Video/HR_OWN_S1_02.mp4',
    time: 'NA',
    ready: true
  },
  "rs_204": {
    title: 'Exploration 03: Level 2: End-to-end Ownership',
    description: 'This is a live demonstration of a session-in-action (~25 min video). Watch how you can help the group to vividly come up with concrete practices which could enable them to take end-to-end ownership and realize how it is different from role ownership.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Ownership/Video/HR_OWN_S1_03.mp4',
    time: 'NA',
    ready: true
  },
  "rs_205": {
    title: 'Exploration 04: Level 3: Firm Ownership',
    description: 'This is a live demonstration of a session-in-action (~20 min video). Watch and learn how you can help the group to visualize firm ownership and how each one of them, irrespective of their hierarchy, could practice firm ownership.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Ownership/Video/HR_OWN_S1_04.mp4',
    time: 'NA',
    ready: true
  },
  "rs_301": {
    title: 'A Guide on the goals of different explorations of the Module:',
    description: 'In this document, understand the goals and outcomes of this module, how it is organized, and how to systematically conduct it for the participants.',
    type: 'pdf_downloadable',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Collaboration/Collab_Goals+walkthrough.pdf',
    time: 'NA',
    ready: true
  },
  "rs_302": {
    title: 'Exploration 01: Introduction: What is Collaboration?',
    description: 'This is a live demonstration of a session-in-action (~18 min video). Watch how you can conduct systematic discussions for the group to form its own interpretation of collaboration, and its criticality in a future-ready work environment.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Collaboration/Video/HR_COL_S2_01.mp4',
    time: 'NA',
    ready: true
  },
  "rs_303": {
    title: 'Exploration 02: Level 1: Trust communication',
    description: 'This is a live demonstration of a session-in-action (~50 min video). Watch how you can conduct systematic discussions to help the group come up with concrete practices of trust communication and why these practices are the foundation of any collaboration.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Collaboration/Video/HR_COL_S2_02.mp4',
    time: 'NA',
    ready: true
  },
  "rs_304": {
    title: 'Exploration 03: Level 2: Collaborative Action',
    description: 'This is a live demonstration of a session-in-action (~30 min video). Watch how you can conduct systematic discussions to help the group to come up with concrete practices which can help each one of us to collaborate systematically.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Collaboration/Video/HR_COL_S2_03.mp4',
    time: 'NA',
    ready: true
  },

  "rs_305": {
    title: 'Exploration 04: Level 3: Co-creation',
    description: 'This is a live demonstration of a session-in-action (~25 min video). Watch and learn how you can help participants appreciate co-creation (as distinct from collaborative action) and come up with some initial set of practices which they could begin with.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Collaboration/Video/HR_COL_S2_04.mp4',
    time: 'NA',
    ready: true
  },

  // GTU 4 Sustainable Success
  "rs_0211": {
    title: 'A Guide on the goals of different explorations of the Module:',
    description: 'In this document, understand the goals and outcomes of this module, how it is organized, and how to systematically conduct it for the students.',
    type: 'pdf_downloadable',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/Goals+walkthrough-success.pdf',
    time: 'NA',
    ready: true
  },
  "rs_0212": {
    title: 'Exploration 01: How do we measure success?',
    description: 'This is a live demonstration of a session-in-action (~05 min video). Watch and learn how you can conduct systematic discussions to enable students to become more aware of their own measures of success and also discover a wider way in which they can measure success and fulfillment in their lives.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/Training+videos/01.mp4',
    time: '05 min',
    ready: true
  },
  "rs_0213": {
    title: 'Exploration 01- Engagement 1B: How do we measure success?',
    description: 'This is a live demonstration of a session-in-action (~22 min video). Watch and learn how you could facilitate this exploration for your students through a quick recognition exercise - where they classify typical “fruits” of success commonly sought by people, to identify and distinguish between outer fruits & inner fruits.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/Training+videos/02.mp4',
    time: '22 min',
    ready: true
  },
  "rs_0214": {
    title: 'Exploration 02- Engagement 2A/2B: Why do the measures of success we choose matter?',
    description: 'This is a live demonstration of a session-in-action (~25 min video). Watch and learn how you could facilitate this exploration for your students by using two real-life case studies where in each, they explore the life consequences of the measures of success we choose.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/Training+videos/04.mp4',
    time: '25 min',
    ready: true
  },
  "rs_0215": {
    title: 'Exploration 2: Engagement 2E/2F/2G: Why do the measures of success we choose matter?',
    description: 'This is a live demonstration of a session-in-action (~13 min video). Watch and learn how you could facilitate this exploration for your students through a series of reflective discussions where they clarify for themselves, the meaning of “sustainable success” in the context of all the examples they explored.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/Training+videos/05.mp4',
    time: '13 min',
    ready: true
  },

  "rs_0216": {
    title: 'Reference Tool: Typical examples of outer fruits and inner fruits',
    description: ' For reference during any Tree Building Exercise. Use it to trigger responses of participants as they fill in the tool.',
    type: 'pdf_downloadble',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/Fruit_List.pdf',
    time: 'NA',
    ready: true
  },

  "rs_0217": {
    title: 'Concept Note: The cycle of Contribution: the life-force of a contributor’s Tree of Fulfillment:',
    description: 'This micro-read is a further deep dive into this concept of ‘dynamic of contribution’ and how it leads to sustainable success. Students discover what this means in their own life in Exploration 3 of the module.',
    type: 'pdf_downloadble',
    tag: 'Go deeper into the core ideas',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/ToF-life-force.pdf',
    time: 'NA',
    ready: true
  },

  // Additional resources for GTU 4 Sustainable Success
  "rs_0218": {
    title: 'Exploration 3: Engagement 4: Demonstration of the Tree of Fulfillment Learning Game',
    description: 'This video (~20 mins) provides a walkthrough and a demonstration of how to use the interactive learning game/ tool for conducting exploration 3 of this module.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/Training+videos/Tree+of+fulfilment+learning+tool.mp4',
    time: '13 min',
    ready: true
  },
  "rs_0219": {
    title: 'Little Book for reference: What we measure we become',
    description: 'A reading guide to a Little Book written by Mr. Srinivas V, lead researcher & designer of the Contributor Program, that helps appreciate the notion of ‘measures of success’ explored in this module.',
    type: 'pdf_downloadble',
    tag: 'Go deeper into the core ideas',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/resources/ReadingCard_KindleBook-1.pdf',
    time: 'NA',
    ready: true
  },
  "rs_0220": {
    title: 'Little Book for reference: Can we see life as a series of ‘measures of success’?',
    description: 'A reading guide to a Little Book written by Mr. Srinivas V, lead researcher & designer of the Contributor Program, that helps us widen our notion of what is ‘sustainable success’ as explored in this module.',
    type: 'pdf_downloadble',
    tag: 'Go deeper into the core ideas',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/resources/ReadingCard_KindleBook-2.pdf',
    time: 'NA',
    ready: true
  },
  "rs_0221": {
    title: 'Project Guide for Module 4',
    description: 'A sample group project that enables students to apply the key ideas explored in this module into real-life. This project guide can be shared with students to help them effectively conduct the project. This project can be assigned for internal assessment. (~20 marks)',
    type: 'pdf_downloadble',
    tag: 'Projects & Assessments',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/resources/GTU_project+guide-m4.pdf',
    time: 'NA',
    ready: true
  },

  // Additional resource for gtu module 1
  "rs_0222": {
    title: 'Guideline for Internal Assessment for CPDP program for GTU 2020',
    description: 'This document gives a guideline for how internal assessments for this course can be done based on student projects.',
    type: 'pdf_downloadble',
    tag: 'Projects & Assessments',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Who+is+Contributor/projects/InternalAsses_ProjGuide_GTU-Aug2020.pdf',
    time: 'NA',
    ready: true
  },
  "rs_0223": {
    title: 'Project Guide for Module 1',
    description: 'A sample group project that enables students to apply the key ideas explored in this module into real-life. This project guide can be shared with students to help them effectively conduct the project. This project can be assigned for internal assessment. (~20 marks)',
    type: 'pdf_downloadble',
    tag: 'Projects & Assessments',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Who+is+Contributor/projects/GTU_project+guide-m1.pdf',
    time: 'NA',
    ready: true
  },
  // Additional resource for gtu module 2
  "rs_0224": {
    title: 'Project Guide for Module 2',
    description: 'A sample individual project that enables students to apply the key ideas explored in this module into real-life. This project guide can be shared with students to help them effectively conduct the project. This project can be assigned for internal assessment. (~20 marks)',
    type: 'pdf_downloadble',
    tag: 'Projects & Assessments',
    src: "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Contributor's+Identity/Resources/GTU_project+guide-m2.pdf",
    time: 'NA',
    ready: true
  },
  // Additional resource for gtu module 3
  "rs_0225": {
    title: 'Project Guide for Module 3',
    description: 'A sample group project that enables students to apply the key ideas explored in this module into real-life. This project guide can be shared with students to help them effectively conduct the project. This project can be assigned for internal assessment. (~20 marks)',
    type: 'pdf_downloadble',
    tag: 'Projects & Assessments',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Creator+of+Destiny/projects/GTU_project+guide-m3.pdf',
    time: 'NA',
    ready: true
  },


  //Additional resources for ACP Facilitators (FDP_ACP 1)

  "rs_0091": {
    title: 'Message Checklist 1: Walkthrough of the “Illumine Sustainable Values Model”',
    description: 'Refer to this checklist while conducting Exploration 2: How Values are Assimilated : Section 1. These provide the key points to cover while walking through the slide.',
    type: 'pdf_downloadable',
    tag: 'Program Support',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/FTP_Day1/ValuesAssim-MessageChecklist.pdf',
    time: 'NA',
    ready: true
  },

  "rs_0092": {
    title: 'Message Checklist 2: Walkthrough of the Program Pedgogy: Structured Discovery Methodology',
    description: 'Refer to this checklist while conducting Exploration 2: How Values are Assimilated : Section 2. These provides the key points to cover while walking through the slide.',
    type: 'pdf_downloadable',
    tag: 'Program Support',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/acp/FTP_Day1/StrucDisc-MessageChecklist.pdf',
    time: 'NA',
    ready: true
  },

  // Trust E&P




  "rs_0041": {
    title: 'A Guide on the goals of different explorations of the Module',
    description: 'In this document, understand the goals and outcomes of this module, how it is organized, and how to systematically conduct it for the participants.',
    type: 'pdf_downloadable',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/Goals-walkthrough-Trust.pdf',
    time: 'NA',
    ready: true
  },
  "rs_0042": {
    title: 'Module 2: How-to-conduct Guide',
    description: 'This guide will help you systematically prepare to conduct & facilitate a group coaching session for this module. This includes slide-by-slide guidelines, tips for effective execution as well key message checklists.',
    type: 'pdf_downloadable',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/S02-TrustSurplus-How+to+Guide.pdf',
    time: 'NA',
    ready: true
  },
  "rs_0043": {
    title: 'Exploration 01: Engagement 1A: What is Trust Surplus?',
    description: 'This is a live demonstration of a session-in-action (~30 min video). Watch how you can conduct systematic discussions to help the group collectively engage with a series of scenarios from their work life, based on which they arrive at an understanding of what ‘trust surplus’ means.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/Engagement1A.mp4',
    time: '30 min',
    ready: true
  },
  "rs_0044": {
    title: 'Exploration 01: Engagement 1B: Trust Surplus needs to be built with multiple stakeholders',
    description: 'This is a live demonstration of a session-in-action (~17 min video). Watch how you can conduct systematic discussions to help the group deepen their appreciation of trust surplus in terms of the multiple stakeholders with which it needs to get built.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/Engagement1B.mp4',
    time: '17 min',
    ready: true
  },
  "rs_0045": {
    title: 'Exploration 01: Engagement 1C: Trust Surplus needs to be built on multiple dimensions',
    description: 'This is a live demonstration of a session-in-action (~8 min video). Watch how you can help the group widen their understanding by recognizing multiple forms of trust surplus, beyond the commonly understood forms such as commitment trust.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/Engagement1C.mp4',
    time: '8 min',
    ready: true
  },
  "rs_0046": {
    title: 'Exploration 01: Engagement 1D: Why is building Trust Surplus important for E&P as an organization?',
    description: 'This is a live demonstration of a session-in-action (~17 min video). Watch how you can help the group widen their understanding of the value and importance of trust surplus for E&P as an organization at individual and organizational levels.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/Engagement1D.mp4',
    time: '17 min',
    ready: true
  },
  "rs_0047": {
    title: 'Exploration 02: How is Trust Surplus built?',
    description: 'This is a live demonstration of a session-in-action (~47 min video). Watch how you can enable participants to learn how to continuously build ‘trust surplus’ in their day-to-day work life, by consciously creating “trust surplus events” out of every situation.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/Exploration+2.mp4',
    time: '47 min',
    ready: true
  },
  "rs_0048": {
    title: 'Exploration 03: Strategies for building Trust Surplus',
    description: 'This is a live demonstration of a session-in-action (~55 min video). Watch how you can enable participants to land their understanding of trust surplus into concrete actionable strategies that they can apply into their day-to-day work in E&P.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/Exploration+3.mp4',
    time: '55 min',
    ready: true
  },
  "rs_0049": {
    title: 'Exploration 04: Go Forward',
    description: 'This is a live demonstration of a session-in-action (~30 min video). Watch how you can enable participants to go forward with concrete actionables for themselves through a reflection on their understanding built across the module, and open sharing by a few.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/E%26P/Trust+Surplus/Exploration+4.mp4',
    time: '30 min',
    ready: true
  },

  //tslhr responsiveness
  "rs_00039": {
    title: 'A Guide on the goals of different explorations of the Module:',
    description: 'In this document, understand the goals and outcomes of this module, how it is organized, and how to systematically conduct it for the participants.',
    type: 'pdf_downloadable',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Responsiveness/Responsive_Goals+walkthrough.pdf',
    time: '30 min',
    ready: true
  },
  "rs_00040": {
    title: 'Exploration 01: Introduction: What is Responsiveness?',
    description: 'This is a live demonstration of a session-in-action (~30 min video). Watch how you can conduct systematic discussions to help the group collectively form its own interpretation of responsiveness, and explore its criticality in a future-ready work environment.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Responsiveness/Video/01.mp4',
    time: '30 min',
    ready: true
  },
  "rs_00041": {
    title: 'Exploration 02: Level 1: Functional Responsiveness',
    description: 'This is a live demonstration of a session-in-action (~20 min video). Watch how you can conduct systematic discussions to help the participants come out with practices to help them understand the meaning of the first level of responsiveness i.e. ‘functional responsiveness’.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Responsiveness/Video/0201.mp4',
    time: '20 min',
    ready: true
  },
  "rs_00042": {
    title: 'Exploration 02: Level 2: Whole Responsiveness',
    description: 'This is a live demonstration of a session-in-action (~18 min video). Watch how you can conduct systematic discussions to help the participants come out with practices to help them understand the meaning of the second level of responsiveness i.e. ‘whole responsiveness’.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Responsiveness/Video/0202.mp4',
    time: '18 min',
    ready: true
  },
  "rs_00043": {
    title: 'Exploration 02: Level 3: Enablement-based Responsiveness',
    description: 'This is a live demonstration of a session-in-action (~15 min video). Watch how you can conduct systematic discussions to help the participants come out with practices to help them understand the meaning of the third level of responsiveness i.e. ‘enablement-based responsiveness’.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Responsiveness/Video/0203.mp4',
    time: '15 min',
    ready: true
  },
  "rs_00044": {
    title: 'Exploration 03: Applying responsiveness',
    description: 'This is a live demonstration of a session-in-action (~7 min video). Watch how you can help the group to vividly come up with concrete practices which could enable them to apply responsiveness in their own role.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Responsiveness/Video/03.mp4',
    time: '7 min',
    ready: true
  },

  // Growing in Aspirations tslhr
  "rs_050": {
    title: 'A Guide on the goals of different explorations of the Module:',
    description: 'In this document, understand the goals and outcomes of this module, how it is organized, and how to systematically conduct it for the participants.',
    type: 'pdf_downloadable',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Growing+in+Aspirations/Aspirations_Goals+walkthrough.pdf',
    time: '7 min',
    ready: true
  },
  "rs_051": {
    title: 'Exploration 01: What does growing in aspiration mean?',
    description: 'This is a live demonstration of a session-in-action (~20 min video). Watch how you can conduct systematic discussions to help the group explore the three levels of aspirations and the nature of the goal or target we are aspiring for change with each level.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Growing+in+Aspirations/Training+videos/01.mp4',
    time: '7 min',
    ready: true
  },
  "rs_052": {
    title: 'Exploration 02: How to grow into level 1 aspiration? ',
    description: 'This is a live demonstration of a session-in-action (~35 min video). Watch how you can conduct systematic discussions to help the group vividly explore the first level of Aspiration- ‘Positively embracing challenges’ and what does it entail to operate in at this level.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Growing+in+Aspirations/Training+videos/02.mp4',
    time: '7 min',
    ready: true
  },
  "rs_053": {
    title: 'Exploration 03: How to grow into level 2 aspiration?',
    description: 'This is a live demonstration of a session-in-action (~35 min video). Watch how you can help the group to vividly explore the second level of Aspiration - ‘Proactively seeking stretch commitments’ and identify stretch commitments which individually or collectively as a team, you have taken up and successfully accomplished.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Growing+in+Aspirations/Training+videos/03.mp4',
    time: '7 min',
    ready: true
  },
  "rs_054": {
    title: 'Exploration 04: How to grow into level 3 aspiration?',
    description: 'This is a live demonstration of a session-in-action (~20 min video). Watch and learn how you can help the group to explore the third level of Aspiration- ‘Committing to quantum leaps’ and explore what quantum leaps are we trying to make through the 4 labs in HRM.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Growing+in+Aspirations/Training+videos/04.mp4',
    time: '7 min',
    ready: true
  },

  // GTU 5 Career Dev
  "rs_056": {
    title: 'A Guide on the goals of different explorations of the Module:',
    description: "In this document, understand the goals and outcomes of this module, how it is organized, and how to systematically conduct it for your students.",
    type: 'pdf_downloadable',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Career+Development/Goals+walkthrough-career.pdf',
    time: '7 min',
    ready: true
  },
  "rs_057": {
    title: 'Exploration 01: Introduction: Developing in a contributive career',
    description: "This is a live demonstration of a session-in-action (~3 min video). Watch and learn how you can conduct systematic discussions to enable students to explore possibilities as a future-ready contributor and choose their journey in 6 different meta-careers.",
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Career+Development/Training+videos/exp1.mp4',
    time: '7 min',
    ready: true
  },
  "rs_058": {
    title: 'Exploration 02: Developing as an innovator-creator',
    description: "This is a live demonstration of a session-in-action (~30 min video). Watch and learn how you could facilitate this exploration for your students and help them explore possibilities as a future-ready contributor and choose their journey as an ‘innovator-creator’ out of the 6 meta-careers.",
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Career+Development/Training+videos/exp2.mp4',
    time: '7 min',
    ready: true
  },
  "rs_059": {
    title: 'Exploration 03: Developing as a builder-entrepreneur',
    description: "This is a live demonstration of a session-in-action (~27 min video). Watch and learn how you could facilitate this exploration for your students and help them explore possibilities as a future-ready contributor and choose their journey as a ‘builder-entrepreneur’ out of the 6 meta-careers.",
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Career+Development/Training+videos/exp3.mp4',
    time: '7 min',
    ready: true
  },
  "rs_060": {
    title: 'Exploration 04: Developing as a change maker',
    description: "This is a live demonstration of a session-in-action (~27 min video). Watch and learn how you could facilitate this exploration for your students and help them explore possibilities as a future-ready contributor and choose their journey as a ‘change maker’ out of the 6 meta-careers.",
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Career+Development/Training+videos/exp4.mp4',
    time: '7 min',
    ready: true
  },
  "rs_061": {
    title: 'Exploration 05: Developing as an achiever',
    description: "This is a live demonstration of a session-in-action (~18 min video). Watch and learn how you could facilitate this exploration for your students and help them explore possibilities as a future-ready contributor and choose their journey as an ‘achiever’ out of the 6 meta-careers.",
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Career+Development/Training+videos/exp5.mp4',
    time: '7 min',
    ready: true
  },
  "rs_062": {
    title: 'Exploration 06: Developing as an manager-leader',
    description: "This is a live demonstration of a session-in-action (~20 min video). Watch and learn how you could facilitate this exploration for your students and help them explore possibilities as a future-ready contributor and choose their journey as an ‘manager-leader’ out of the 6 meta-careers.",
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Career+Development/Training+videos/exp6.mp4',
    time: '7 min',
    ready: true
  },
  "rs_063": {
    title: 'Exploration 07: Developing as a researcher',
    description: "This is a live demonstration of a session-in-action (~30 min video). Watch and learn how you could facilitate this exploration for your students and help them explore possibilities as a future-ready contributor and choose their journey as a ‘researcher’ out of the 6 meta-careers.",
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Career+Development/Training+videos/exp7.mp4',
    time: '7 min',
    ready: true
  },
  "rs_064": {
    title: 'Exploration 08: Closure Reflection',
    description: "This is a live demonstration of a session-in-action (~19 min video). Watch and learn how you could facilitate this exploration for your students and help them reflect upon how they will like to grow as a future-ready contributor using the 6 career models.",
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Career+Development/Training+videos/exp8.mp4',
    time: '7 min',
    ready: true
  },

  // Tata steel Value creators
  "rs_065": {
    title: 'A Guide on the goals of different explorations of the Module:',
    description: " In this document, understand the goals and outcomes of this module, how it is organized, and how to systematically conduct it for your participants.",
    type: 'pdf_downloadable',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Value+creator/ValueCreator_Goals+walkthrough-proofed.pdf',
    time: '7 min',
    ready: true
  },
  "rs_066": {
    title: 'Exploration 01: Understanding what is value creation',
    description: "This is a live demonstration of a session-in-action (~1 hr. video). Watch how you can conduct systematic discussions to help the participants clarify what it means to create value. They do so by contrasting it with ‘efficiency’ – which often gets confused as value creation.",
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Value+creator/Training+videos/01.mp4',
    time: '7 min',
    ready: true
  },
  "rs_067": {
    title: 'Exploration 02: Levels of value creation',
    description: "This is a live demonstration of a session-in-action (~17 min video). Watch how you can conduct systematic discussions to help the participants appreciate the difference in thinking at each level of the ladder of value creation",
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Value+creator/Training+videos/02.mp4',
    time: '7 min',
    ready: true
  },
  "rs_068": {
    title: 'Exploration 03: How to become a value creator?',
    description: "This is a live demonstration of a session-in-action (~35 min video). Watch how you can conduct systematic discussions to help the participants establish how each of the practices aid in value creation.",
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Value+creator/Training+videos/03.mp4',
    time: '7 min',
    ready: true
  },

  // TSLHR Evolving Professionally
  "rs_069": {
    title: 'A Guide on the goals of different explorations of the Module:',
    description: "In this document, understand the goals and outcomes of this module, how it is organized, and how to systematically conduct it for the participants.",
    type: 'pdf_downloadable',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Evolving+professionally/S05_EvoPro_Goals+walkthrough.pdf',
    time: '7 min',
    ready: true
  },
  "rs_070": {
    title: 'Exploration 01: Introduction: What does it mean to evolve professionally?',
    description: "This is a live demonstration of a session-in-action (~22 min video). Watch how you can conduct systematic discussions to help the group clarify what it means to evolve professionally and help them explore the ladder of Evolving Professionally.",
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Evolving+professionally/Training+videos/01.mp4',
    time: '7 min',
    ready: true
  },
  "rs_071": {
    title: 'Exploration 02: Level 1: Evolving one’s role',
    description: "This is a live demonstration of a session-in-action (~27 min video). Watch how you can conduct systematic discussions to help the group explore practices for the first level of Evolving Professionally and see why are these practices helpful for evolving one’s role.",
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Evolving+professionally/Training+videos/02.mp4',
    time: '7 min',
    ready: true
  },
  "rs_072": {
    title: 'Exploration 03: Level 2: Evolving in one’s practice',
    description: "This is a live demonstration of a session-in-action (~39 min video). Watch how you can help the group explore practices for the second level of Evolving Professionally and identify the importance of growing in one’s practice.",
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Evolving+professionally/Training+videos/03.mp4',
    time: '7 min',
    ready: true
  },
  "rs_073": {
    title: 'Exploration 04: Level 3: Evolving holistically',
    description: "This is a live demonstration of a session-in-action (~29 min video). Watch and learn how you can help the group explore practices for the third level of Evolving Professionally and identify how these practices help in evolving holistically.",
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Evolving+professionally/Training+videos/04.mp4',
    time: '7 min',
    ready: true
  },

  // Additional Resources for GTU 5 Carreer Development 
  "rs_074": {
    title: 'Project Guide for Module 5',
    description: "A sample group project that enables students to apply the key ideas explored in this module into real-life. This project guide can be shared with students to help them effectively conduct the project. This project can be assigned for internal assessment. (~20 marks)",
    type: 'pdf_downloadable',
    tag: 'Projects & Assessments',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Career+Development/GTU-project+guide-Module+5.pdf',
    time: '7 min',
    ready: true
  },

  // "rs_075": {
  //   title: 'Project Guide for Module 6',
  //   description: "A sample group project that enables students to apply the key ideas explored in this module into real-life. This project guide can be shared with students to help them effectively conduct the project. This project can be assigned for internal assessment. (~20 marks)",
  //   type: 'pdf_downloadable',
  //   tag: 'Resources to conduct sessions effectively',
  //   src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Evolving+professionally/S05_EvoPro_Goals+walkthrough.pdf',
  //   time: '7 min',
  //   ready: true
  // },

  // Q&A for aspiration

  "rs_076": {
    title: "How is 'Growing in Aspirations' different from 'Becoming a Value Creator'?",
    description: null,
    type: 'external_video',
    tag: 'Q&A ',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Growing+in+Aspirations/Training+videos/Q%26A01.mp4',
    time: '7 min',
    ready: true
  },
  "rs_077": {
    title: 'In Level 1, we are talking of positively embracing challenges. Does everyone not embrace challenges positively?',
    description: null,
    type: 'external_video',
    tag: 'Q&A ',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Growing+in+Aspirations/Training+videos/Q%26A02.mp4',
    time: '7 min',
    ready: true
  },
  "rs_078": {
    title: 'Is the need to make a living not a driver or motivation enough to positively embrace challenge? Is there a need for any higher motivation?',
    description: null,
    type: 'external_video',
    tag: 'Q&A ',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Growing+in+Aspirations/Training+videos/Q%26A03.mp4',
    time: '7 min',
    ready: true
  },
  "rs_079": {
    title: 'What if opportunities of taking on stretch commitments are not available in my role? What do I do then? How can I still grow to Level 2?',
    description: null,
    type: 'external_video',
    tag: 'Q&A ',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Growing+in+Aspirations/Training+videos/Q%26A04.mp4',
    time: '7 min',
    ready: true
  },
  "rs_080": {
    title: 'What qualifies for committing to quantum leap? How does one know that one is committing to making a quantum leap?',
    description: null,
    type: 'external_video',
    tag: 'Q&A ',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Growing+in+Aspirations/Training+videos/Q%26A.mp4',
    time: '7 min',
    ready: true
  },

  // Responsiveness Q&A
  "rs_081": {
    title: 'At a team level, why is it necessary that each of us is individually responsive as well? Why it is not enough if only a few people are responsive and the work gets done?',
    description: null,
    type: 'external_video',
    tag: 'Q&A ',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Responsiveness/Video/Q%26A01.mp4',
    time: '7 min',
    ready: true
  },
  "rs_082": {
    title: "How is 'whole responsiveness' different from thinking from a wider perspective or taking responsibility for a wider area?",
    description: null,
    type: 'external_video',
    tag: 'Q&A ',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Responsiveness/Video/Q%26A02.mp4',
    time: '7 min',
    ready: true
  },
  "rs_083": {
    title: "From a cultural perspective, how do we interpret 'responsiveness'",
    description: null,
    type: 'external_video',
    tag: 'Q&A ',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Responsiveness/Video/Q%26A03.mp4',
    time: '7 min',
    ready: true
  },

  // gtu-006 

  "rs_084": {
    title: "A Guide on the goals of different explorations of the Module",
    description: 'In this document, understand the goals and outcomes of this module, how it is organized, and how to systematically conduct it for your students.',
    type: 'pdf_downloadable',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Scope+of+contribution/Goals+walkthrough-scopeContrib.pdf',
    time: '7 min',
    ready: true
  },
  "rs_085": {
    title: "Introduction",
    description: 'This is a live demonstration of a session-in-action (~5 min video). Watch and learn how you could facilitate this exploration for your students and introduce them to the goal of the session. The 3 zones of contribution are first introduced to students through an audio-visual that explains the 3 zones, with a discussion at the end, to clarify their understanding from it. ',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Scope+of+contribution/Videos+(SJ)/Introduction+Final.mp4',
    time: '7 min',
    ready: true
  },
  "rs_086": {
    title: "Exploration 1-Engagement 1A: Zone 1: ‘Contribution to Self’",
    description: 'This is a live demonstration of a session-in-action (~7 min video). Watch and learn how you could facilitate this exploration for your students and appreciate Zone 1: Contribution to Self',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Scope+of+contribution/Videos+(SJ)/Zone+1+Final.mp4',
    time: '7 min',
    ready: true
  },
  "rs_087": {
    title: "Exploration 1-Engagement 1B: Zone 2: ‘Contribution to Collectives’",
    description: 'This is a live demonstration of a session-in-action (~6 min video). Watch and learn how you could facilitate this exploration for your students and help them appreciate Zone 2: Contribution to Collectives',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Scope+of+contribution/Videos+(SJ)/Zone+2+Final.mp4',
    time: '7 min',
    ready: true
  },
  "rs_088": {
    title: "Exploration 1-Engagement 1C: Zone 3: ‘Contribution to Society’",
    description: 'This is a live demonstration of a session-in-action (~8 min video). Watch and learn how you could facilitate this exploration them for your students and help them appreciate Zone 3:Contribution to Society',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Scope+of+contribution/Videos+(SJ)/Zone+3+Final.mp4',
    time: '7 min',
    ready: true
  },
  "rs_089": {
    title: "Exploration 1-Engagement 1D: Recognition Exercise",
    description: 'This is a live demonstration of a session-in-action (~15 min video). Watch and learn how you could facilitate the sorting exercise. The video briefly explains the goal of the exercise and how the contributions need to be sorted in the three zones.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Scope+of+contribution/Videos+(SJ)/Summary+Exercise+Final.mp4',
    time: '7 min',
    ready: true
  },
  "rs_090": {
    title: "Exploration 2- Engagement 2A: Visualizing role as a student",
    description: 'This is a live demonstration of a session-in-action (~6 min video). Watch and learn how you could enable students to visualize and list the activities they are a part of in their student life',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Scope+of+contribution/Videos+(SJ)/Exploration+2.mp4',
    time: '7 min',
    ready: true
  },
  "rs_091": {
    title: "Exploration 2: Engagement 2B: Demonstration of the Expanding Contribution Tool",
    description: 'This is a live demonstration of a session-in-action (~27 min video). Watch and learn how you could conduct the fillin tool to help students sort out various contributions they can make as a student in all 3 zone i.e. to self, collectives and the society',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Scope+of+contribution/Videos+(SJ)/Toolwalkthrough_ExpCont.mp4',
    time: '7 min',
    ready: true
  },

  "rs_092": {
    title: "Project Guide for Module 6",
    description: 'A sample group project that enables students to apply the key ideas explored in this module into real-life. This project guide can be shared with students to help them effectively conduct the project. This project can be assigned for internal assessment. (~20 marks)',
    type: 'pdf_downloadable',
    tag: 'Projects & Assessments',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Scope+of+contribution/GTU-project+guide-Module+6.pdf',
    time: '7 min',
    ready: true
  },
  "rs_093": {
    title: "Sample Questions for Assessments (Sept 2020)",
    description: 'Here is a sample bank of questions around each of the 6 modules to enable faculty members to compile question paper/ assessments with the appropriate marks and no. of questions as per the college requirements for the mid-semester assessments.',
    type: 'pdf_downloadable',
    tag: 'Projects & Assessments',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Scope+of+contribution/CPDP_SampleQsBank_r.pdf',
    time: '7 min',
    ready: true
  },


  // Additional resources for gtu-1
  "rs_094": {
    title: "Faculty Orientation: Orientation to the methods underlying the program",
    description: 'This video (~20min) is an part of the mind-course faculty orientation program. It is aimed to clarify the purpose of the program and introduce the new teaching methodology required for such a program.',
    type: 'external_video',
    tag: 'Improve your engagement as a facilitator',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Who+is+Contributor/Faculty+Orientation+videos/01_Program_Methods.mp4',
    time: '7 min',
    ready: true
  },

  "rs_095": {
    title: "Faculty Orientation: How to make classes effective",
    description: 'This video (~25min) is an part of the mind-course faculty orientation program. It shows a discussion and sharing of best practices by different teachers from GTU to make the classes more effective',
    type: 'external_video',
    tag: 'Improve your engagement as a facilitator',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Who+is+Contributor/Faculty+Orientation+videos/02_Effective_Classes.mp4',
    time: '7 min',
    ready: true
  },

  "rs_096": {
    title: "Faculty Orientation: A growth journey for ourselves as facilitators",
    description: 'This video (~2 min) is a short walkthrough of the ladder of continuous improvement you can develop yourself on, as a facilitator.',
    type: 'external_video',
    tag: 'Improve your engagement as a facilitator',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Who+is+Contributor/Faculty+Orientation+videos/03_Fac_Ladder.mp4',
    time: '7 min',
    ready: true
  },

  // Additional Resources for gtu-4
  "rs_097": {
    title: "Faculty Orientation: Live Demo & Walkthrough of Module 4",
    description: 'This video (~1 hr) is a part of an interactive mid-course faculty orientation on 15th Aug 2020 for the facilitators of CPDP in GTU university. It is meant to help teachers appreciate how to facilitate and run this module effectively with the tools provided.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/Training+videos/Module_4_Demo_GTU.mp4',
    time: '7 min',
    ready: true
  },

  // tslenp_wfw, tslhr_mod07

  "rs_098": {
    title: "A Guide on the goals of different explorations of the Module:",
    description: 'In this document, understand the goals and outcomes of this module, how it is organized, and how to systematically conduct it for the participants.',
    type: 'pdf_downloadable',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Way+forward+workshop/Goals+Doc.pdf',
    time: '7 min',
    ready: true
  },

  "rs_099": {
    title: "Introduction to Future Ready Ideal –Way Forward Workshop",
    description: 'This is a live demonstration of a session-in-action (~5min video). Watch how you can introduce the session to the participants.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Way+forward+workshop/videos/Introduction.mp4',
    time: '7 min',
    ready: true
  },

  "rs_100": {
    title: "Exploration 01: What is an Operating Ideal",
    description: 'This is a live demonstration of a session-in-action (~5 min. video). Watch how you can conduct systematic discussions to help the participants know the importance of making the Operating Ideal explicit for the system.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Way+forward+workshop/videos/01.mp4',
    time: '7 min',
    ready: true
  },

  "rs_101": {
    title: "Exploration 02: The Future-Ready Operating Ideal of TSL",
    description: 'This is a live demonstration of a session-in-action (~40min video). Watch how you can conduct systematic discussions to help participants (a) uncover the logic for a new operating ideal at this juncture, and (b) appreciate the end-state which each of us in TSL is aiming at through our collective growth on the operating ideal.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Way+forward+workshop/videos/02.mp4',
    time: '7 min',
    ready: true
  },

  "rs_102": {
    title: "Exploration 03: How we will adopt the Future-Ready Operating Ideal as a system",
    description: 'Watch how you can help the participants engage with the 3-Wave Intervention Model and understand the role of each wave in the adoption & assimilation journey of the Operating Ideal.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Way+forward+workshop/videos/03.mp4',
    time: '7 min',
    ready: true
  },

  "rs_103": {
    title: "Exploration 04: Approach for Wave 1",
    description: 'This is a live demonstration of a session-in-action (~35min video). Watch how you can conduct systematic discussions to help the participants recognize the barriers to adopting the operating ideal and the two important mechanisms which they can employ to deal with these barriers and unleash the operating ideal.',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Way+forward+workshop/videos/04.mp4',
    time: '7 min',
    ready: true
  },

  "rs_104": {
    title: "Next phase in the intervention: Walkthrough Wave 1–Phase 2",
    description: 'This is a live demonstration of a session-in-action (~23min video). This video demonstrates the upcoming intervention tracks',
    type: 'external_video',
    tag: 'Resources to conduct sessions effectively',
    src: 'https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Way+forward+workshop/videos/05.mp4',
    time: '7 min',
    ready: true
  },

}