import React from "react";
import TextBox from '../../components/Textbox';

import bulb from './assets/bulb.png';

import './style.css'





export default ({ textBoxId, manageTool, engagementData, isFullScreen, instructions, leftImg, leftInfo, leftInfoQuestions, rightTitle, textBoxQuestions, zone_2_left_points, textBoxColor }) => {
  let firstRow = [], secondRow = [];

  let instructionSize = '13px';
  let textBoxQuestionSize = '14px';
  let leftInfoSize = '15px';
  let leftInfoQuestionSize = '12px';

  if (isFullScreen) {
    instructionSize = '16px';
    textBoxQuestionSize = '18px';
    leftInfoSize = '20px';
    leftInfoQuestionSize = '14px';
  }

  const populateTextBox = (start, end, row) => {
    while (start < end) {
      row.push(displayTextBox(start++))
    }
  }

  const displayTextBox = (i) => {
    return (
      <div className='gtu_5_list-img' style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', width: '100%', padding: '10px', backgroundColor: textBoxColor, margin: '5px', border: '3px solid #EA7B00' }}>
        <strong style={{ fontSize: textBoxQuestionSize, textAlign: "center" }}>
          {textBoxQuestions[i]}
        </strong>
        <TextBox id={textBoxId[i]} initialData={engagementData[textBoxId[i]] || null} processData={manageTool} customStyleWrapper='gtu_5_wrapper' customStyleToolbar='gtu_5_toolbar' />
      </div>
    )
  }

  populateTextBox(0, textBoxQuestions.length / 2, firstRow);
  populateTextBox(textBoxQuestions.length / 2, textBoxQuestions.length, secondRow);

  return <div style={{ height: '100%' }}>
    <div style={{ width: '100%', display: 'flex', backgroundColor: 'white' }}>
      <div style={{ height: '60px', width: '100%', padding: '14px', lineHeight: '22px' }}>
        <div style={{ fontWeight: 'bold', fontSize: instructionSize }}>Instructions:</div>
        <div style={{ fontSize: instructionSize }}>{instructions}</div>
      </div>
    </div>
    <div style={{ display: "flex", backgroundColor: '#FFF9CD', height: 'calc(100% - 60px)' }}>
      <div style={{ padding: '10px', width: '25%', display: 'flex', flexDirection: 'column', marginTop: '5%' }}>
        <img src={leftImg} width='100%' />
        <div style={{ fontSize: leftInfoSize, fontWeight: 'bold', textAlign: 'center' }}>{leftInfo}</div>
        <div style={{ position: 'relative', margin: '15px 9px' }}>
          <img src={bulb} style={{ height: '40px', position: 'absolute', top: '-20px', left: '-25px' }} />
          <div style={{ fontSize: leftInfoQuestionSize, color: '#6B6A5F', overflow: "scroll" }}>{leftInfoQuestions}</div>
          <div style={{ overflow: 'scroll' }}>
            {
              zone_2_left_points ? (zone_2_left_points.map((ele, index) =>
                <div key={index} style={{ fontSize: leftInfoQuestionSize, color: '#6B6A5F'}}>&#9679;&nbsp;&nbsp;&nbsp;{ele}</div>)) : null
            }
          </div>
        </div>
      </div>
      <div style={{ width: '75%', display: 'flex', flexDirection: 'column' }}>
        <div>{rightTitle}</div>
        <div style={{ width: '100%', height: '48%', display: 'flex' }}>
          {firstRow}
        </div>
        <div style={{ width: '100%', height: '48%', display: 'flex' }}>
          {secondRow}
        </div>
      </div>
    </div>
  </div >
}