import React from 'react';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import './style.css';
import { manageFullScreen } from '../../containers/application/channelDashboard/redux_actions';

let ToolNavigation = ({ currentScene, sceneCount, next, previous, isToolFullScreen, toggleFullScreen }) => {
  return <div className='actions-cntr'>
    <div style={{display: 'flex', alignItems:'center'}}>
    <div className='navigation-cntr'>
      {<NavigateBeforeIcon className={`btn${currentScene == 0 ? '-inactive' : ''}`} onClick={() => { if (currentScene != 0) previous(currentScene) }} />}
      {<NavigateNextIcon className={`btn${currentScene == sceneCount - 1 ? '-inactive' : ''}`} onClick={() => { if (currentScene < sceneCount - 1) next(currentScene) }} />}
    </div>
    <FullscreenIcon className='btn' onClick={()=>toggleFullScreen(!isToolFullScreen)} style={{color:'#CECECE'}} />
    </div>
    <div style={{ color: '#CECECE', lineHeight: '28px', fontSize: 11, margin: '0px 8px' }}>
      Illlumine Knowledge Tools
    </div>
  </div>
}

export default ToolNavigation;