
import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import ImageSlide from '../../components/ImageSlide';
import ToolNavigation from '../../components/ToolNavigation';
import VideoPlayer from '../../components/VideoPlayer';

import GTUENG from "./gtu4Eng";

import bulb_1 from "./assets/bulb_1.png";
import bulb_2 from "./assets/bulb_2.png";
import bulb_3 from "./assets/bulb_3.png";
import bulb_4 from "./assets/bulb_4.png";

// import './style.css';

let Unit = (props) => {
    let { isFullScreen, manageFullScreen, dimensions, manageToolData, engagementData, onSlideChange } = props;
    let { slideHeight, slideWidth } = dimensions;
    const trigQ = [
        { q_no: bulb_1, q: "How will this impact their confidence to take on challenging goals bigger responsibilities and adapt to changing demands? Why?" },
        { q_no: bulb_2, q: "How will this impact their capacity to turn setbacks, failure, criticism – into opportunities for growth? Why?" },
        { q_no: bulb_3, q: "How will this impact their ability to collaborate, work in teams, work with multiple stakeholders and partners?" },
        { q_no: bulb_4, q: "How will their self development, self esteem and self-belief that ‘I can’ be impacted in the long term?" }];

    const [index, setIndex] = useState(0);
    const slides = [
        { id: 0, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/unit_04_20.PNG"} /> },
        { id: 1, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/unit_04_21.PNG"} /> },
        { id: 2, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/unit_04_22.PNG"} /> },
        { id: 3, obj: <GTUENG readOnly={false} manageToolData={manageToolData} engagementData={engagementData} eventTools={true} /> },
        { id: 4, obj: <GTUENG readOnly={true} manageToolData={manageToolData} engagementData={engagementData} q_no={trigQ[0].q_no} trigQ={trigQ[0].q} /> },
        { id: 5, obj: <GTUENG readOnly={true} manageToolData={manageToolData} engagementData={engagementData} q_no={trigQ[1].q_no} trigQ={trigQ[1].q} /> },
        { id: 6, obj: <GTUENG readOnly={true} manageToolData={manageToolData} engagementData={engagementData} q_no={trigQ[2].q_no} trigQ={trigQ[2].q} /> },
        { id: 7, obj: <GTUENG readOnly={true} manageToolData={manageToolData} engagementData={engagementData} q_no={trigQ[3].q_no} trigQ={trigQ[3].q} /> },
        { id: 8, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/unit_04_34.PNG"} /> },
        { id: 9, obj: <VideoPlayer
            imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/unit_04_35.PNG"}
            mediaUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/Training+videos/tree+with+fruits_final+render_version+1.mp4"} />
        },
        { id: 10, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/unit_04_36.PNG"} /> },
        { id: 11, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/unit_04_37.PNG"} /> },

    ];

    const transitions = useTransition(slides[index], item => item.id, {
        from: { opacity: 0 }, enter: { opacity: 1 }, leave: { opacity: 0 },
    });
    // useEffect(() => { setIndex(sceneCount) }, [sceneCount]);
    return <div className='tool-holder'>
      <div className='slide-cntr' style={{ width: slideWidth, height: slideHeight }}>
        {transitions.map(({ item, props, key }) => (
          <animated.div
            key={key}
            className="slide"
            style={{ ...props, position: 'absolute', width: slideWidth, height: slideHeight }}
          ><div style={{ height: '100%' }}>{item.obj}</div></animated.div>
            ))}
        </div>
        <ToolNavigation
            currentScene={index}
            sceneCount={slides.length}
            next={(index) => {
                manageToolData({toolId: 'tracker', content:{...engagementData.tracker, [Date.now()]:index+1}});
                onSlideChange(engagementData);
                setIndex(index + 1);
            }}
            previous={(index) => {
                manageToolData({toolId: 'tracker', content:{...engagementData.tracker, [Date.now()]:index-1}});
                onSlideChange(engagementData);
                setIndex(index - 1)
            }}
            isToolFullScreen={isFullScreen}
            toggleFullScreen={manageFullScreen}
        />
    </div>
}

export default Unit;