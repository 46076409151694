const INITIAL_STATE = {
  activeComponents : {},
  toolFullScreen: false,
  engagementData: {},
  userState:{}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;
    case "SYNC_LOCAL_FROM_GLOBAL":
      return {...state, userState: action.payload}
    case "RESET_TRACKER":
      return {...state, engagementData:{...state.engagementData, tracker: null}}
    case "ACTIVATE_COMPONENT":
      let {courseId, target, value, instanceId, sessionId, uid} = action.payload;
      return {...state, activeComponents: {
        ...state.activeComponents, [courseId]:{
          ...state.activeComponents[courseId], [target]:value}
        }, engagementData: {
        ...state.engagementData, courseId, milestone: value, instanceId, sessionId, uid
        }
      };
    case "MANAGE_FULLSCREEN":
      return {...state, toolFullScreen: action.payload}
    case "MANAGE_TOOL_DATA":
      return {...state, 
        engagementData: {...state.engagementData, [action.payload.toolId]: action.payload.content}
      }
    case "RESET_CHANNEL_STATE":
      return INITIAL_STATE
    default:
      return state;
  }
};
