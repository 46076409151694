import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import ImageSlide from '../../components/ImageSlide';
import ToolNavigation from '../../components/ToolNavigation';
import SelfDescription from './outerfruits';
import FruitMap from './fruitMap';
import VideoPlayer from '../../components/VideoPlayer';
import SlideImg_0 from "./assets/Ex2-S0-SectionBreak.PNG";
import SlideImg_1 from "./assets/Ex2-S1.PNG";
import SlideImg_2 from "./assets/Ex2-S2VideoCover.PNG";
import SlideImg_3 from "./assets/Ex2-S3.PNG";
import SlideImg_4 from "./assets/Ex2-S4-ToolAns.png";
import SlideImg_5 from "./assets/Ex2-S5-ToolAns.png";
import SlideImg_6 from "./assets/Ex2-S6-ToolAns.png";
import SlideImg_7 from "./assets/Ex2-S7.PNG";
import './style.css';

let cases = ['Ram: He is a nice person, always ready to help colleagues. Relationships are important to him. But work is just about having a secure job and comfortable life. He does the basic minimum work and doesn’t push himself to meet goals.',
  'Shyam: He is competent, develops his job knowledge, and tries to be efficient at work. But in his drive to chase targets and career advancement, Shyam often disregards relationships & people; and chooses short-term results over sustainable outcomes.',
  'Ghanshyam: He takes end to end ownership of goals. He goes all out to deliver to outcomes, mobilizing people and resources where needed. When a project hits roadblocks, he innovatively finds solutions. He takes pride in doing high quality work & rarely breaks a delivery commitment. He invests into building the people he works with – partners or colleagues.']

let UnitOne = (props) => {
  let { isFullScreen, manageFullScreen, manageToolData, engagementData, dimensions, onSlideChange } = props;
  let { slideHeight, slideWidth } = dimensions;

  const [index, setIndex] = useState(0);
  const slides = [
    { id: 0, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_0} /> },
    { id: 1, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_1} /> },
    { id: 2, obj: <VideoPlayer imgUrl={SlideImg_2} mediaUrl='https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/ToF-V1.mp4' /> },
    { id: 3, obj: <SelfDescription manageTool={manageToolData} engagementData={engagementData} /> },
    { id: 4, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_3} /> },
    { id: 5, obj: <FruitMap story={cases[0]} id='2B-1' manageTool={manageToolData} engagementData={engagementData} /> },
    { id: 6, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_4} /> },
    { id: 7, obj: <FruitMap story={cases[1]} id='2B-2' manageTool={manageToolData} engagementData={engagementData} /> },
    { id: 8, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_5} /> },
    { id: 9, obj: <FruitMap story={cases[2]} id='2B-3' manageTool={manageToolData} engagementData={engagementData} /> },
    { id: 10, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_6} /> },
    { id: 11, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_7} /> }
  ];

  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0 }, enter: { opacity: 1 }, leave: { opacity: 0 },
  });
  // useEffect(() => { setIndex(sceneCount) }, [sceneCount]);
  return <div className='tool-holder'>
    <div className='slide-cntr' style={{ width: slideWidth, height: slideHeight }}>
      {transitions.map(({ item, props, key }) => (
        <animated.div
          key={key}
          className="slide"
          style={{ ...props, position: 'absolute' }}
        >
          {item.obj}
        </animated.div>
      ))}
    </div>
    <ToolNavigation
      currentScene={index}
      sceneCount={slides.length}
      next={(index) => {
        manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index + 1 } });
        onSlideChange(engagementData);
        setIndex(index + 1);
      }}
      previous={(index) => {
        manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index - 1 } });
        onSlideChange(engagementData);
        setIndex(index - 1)
      }}
      isToolFullScreen={isFullScreen}
      toggleFullScreen={manageFullScreen}
    />
  </div>
}

export default UnitOne;