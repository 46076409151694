import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import ImageSlide from '../../components/ImageSlide';
import ToolNavigation from '../../components/ToolNavigation';
import AspirationEng from './AspirationEng';



// import './style.css';

let Unit = (props) => {
  let { isFullScreen, manageFullScreen, dimensions, manageToolData, engagementData, onSlideChange } = props;
  let { slideHeight, slideWidth } = dimensions;
  const [index, setIndex] = useState(0);

  let topQuestion = ['Level 1: Positively embracing challenges'];
  let toolQuestion = ['Why do you think people do it? What is their motive?', 'How do they go about it? What kind of activities do they take up?', 'How do they manage themselves & their team for it?', 'How do they develop & add on skills to achieve this?']
  let leftInfoTitle = ['ASPIRATION:'];
  let leftInfo = ['Meeting targets consistently & reliably, come what may'];
  let leftBoardQuestion = ['What does it entail to operate in at this level - What is the nature of activities involved? What kind of challenges would you deal with?'];
  let leftBoardAnswer = ['Visualize this by brainstorming to think of examples', 'Then answer 4 questions to fill in'];
  let textBoxId = ['tslhr_aspiration_1', 'tslhr_aspiration_2', 'tslhr_aspiration_3', 'tslhr_aspiration_4'];
  const slides = [
    { id: 0, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Growing+in+Aspirations/S06_ASP_05.PNG"} /> },
    { id: 1, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Growing+in+Aspirations/S06_ASP_06.PNG"} /> },
    { id: 2, obj: <AspirationEng textBoxId={textBoxId} manageTool={manageToolData} engagementData={engagementData} topQuestion={topQuestion[0]} toolQuestion={toolQuestion} leftInfoTitle={leftInfoTitle[0]} leftInfo={leftInfo} leftBoardQuestion={leftBoardQuestion[0]} leftBoardAnswer={leftBoardAnswer} /> },
    { id: 3, obj: <ImageSlide dimensions={dimensions} imgUrl={"https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/HR/Growing+in+Aspirations/S06_ASP_08.PNG"} /> }
  ];

  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0 }, enter: { opacity: 1 }, leave: { opacity: 0 },
  });
  // useEffect(() => { setIndex(sceneCount) }, [sceneCount]);

  return <div className='tool-holder'>
    <div className='slide-cntr' style={{ width: slideWidth, height: slideHeight }}>
      {transitions.map(({ item, props, key }) => (
        <animated.div
          key={key}
          className="slide"
          style={{ ...props, position: 'absolute', width: slideWidth, height: slideHeight }}
        >
          <div style={{ height: '100%' }}>{item.obj}</div>
        </animated.div>
      ))}
    </div>
    <ToolNavigation
      currentScene={index}
      sceneCount={slides.length}
      next={(index) => {
        manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index + 1 } });
        onSlideChange(engagementData);
        setIndex(index + 1);
      }}
      previous={(index) => {
        manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index - 1 } });
        onSlideChange(engagementData);
        setIndex(index - 1)
      }}
      isToolFullScreen={isFullScreen}
      toggleFullScreen={manageFullScreen}
    />
  </div>
}

export default Unit;