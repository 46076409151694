import React from "react";
import EventModal from "./modal";

import negative_inner from "./assets/negative_inner.png";
import positive_inner from "./assets/positive_inner.png";
import negative_outer from "./assets/negative_outer.png";
import positive_outer from "./assets/positive_outer.png";



let fruitStyles = {
  'positive_outer': { backgroundColor: '#E64708' },
  'negative_outer': { backgroundColor: '#C4BD97', border: '5px solid #E64708' },
  'positive_inner': { backgroundColor: '#0070C0' },
  'negative_inner': { backgroundColor: '#C4BD97', border: '5px solid #0070C0' }
}

export default ({ manageToolData, readOnly, engagementData, eventTools, q_no, trigQ }) => {
  const addTextBoxes = (type, user) => {

    let d = Date.now();
    if (!engagementData['gtu004_03']) { engagementData['gtu004_03'] = {} }
    if (!engagementData['gtu004_03'][user]) { engagementData['gtu004_03'][user] = {} }
    if (!engagementData['gtu004_03'][user][type]) { engagementData['gtu004_03'][user][type] = {} }

    manageToolData({
      toolId: 'gtu004_03',
      content: {
        ...engagementData['gtu004_03'],
        [user]: {
          ...engagementData['gtu004_03'][user],
          [type]: {
            ...engagementData['gtu004_03'][user][type],
            [d]: { val: null }
          }
        }
      }
    });
  }

  let postFruits = (user, type) => {
    return Object.keys(engagementData && engagementData['gtu004_03']
      && engagementData['gtu004_03'][user] && engagementData['gtu004_03'][user][type]
      ? engagementData['gtu004_03'][user][type] : {}).map(eid => {
        console.log(engagementData['gtu004_03']);
        let a = engagementData['gtu004_03'][user][type][eid];
        return <textarea readOnly={readOnly} key={eid} className='text-box scroll-bar' value={a.val || ''}
          style={fruitStyles[type]}
          onChange={ev => {
            engagementData['gtu004_03'][user][type][eid] = { val: ev.currentTarget.value }
            manageToolData({ toolId: `gtu004_03`, content: engagementData['gtu004_03'] });
          }} />;
      })
  }

  return <div style={{ height: '100%', backgroundColor: '#FFF9CD', padding: '15px' }}>
    {eventTools ? <div style={{ width: '100%', height: '105px', padding: '8px', display: 'flex' }}>
      <div style={{ width: '25%' }}>
        <div style={{ fontSize: '12px', marginBottom: '6px' }}><strong>Instructions:</strong></div>
        <div style={{ fontSize: '12px', marginBottom: '4px' }}> 1. First click an event to explore it.</div>
        <div style={{ fontSize: '12px', marginBottom: '4px' }}>2. Then discuss to add both positive & negative inner & outer fruits the event results in, for Anil & Sunil.</div>
      </div>
      <div className="separator" style={{ width: '55%', display: 'flex' }}>
        <EventModal />
      </div>
      <div style={{ width: '20%', alignSelf: 'flex-end', textAlign: 'center', color: '#A47D00' }}>
        {/* <a style={{ color: '#A47D00', textDecoration: "underline" }} href="https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/Fruit_List.pdf" target="_blank"><span style={{ fontSize: '32px',color: '#A47D00',textDecoration:"none" }}>&#x1F6C8;</span>List of Fruits</a> */}
        {/* <p><span style={{ fontSize: '32px',color: '#A47D00',textDecoration:"none" }}>&#x1F6C8;</span>List of Fruits</p> */}
        {/* <EventModal pdf="https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Sustainable+success/Fruit_List.pdf" /> */}
      </div>
    </div>
      : <div style={{ width: '100%', height: '105px', padding: '8px 24px' }}>
        <div style={{ display: 'flex', backgroundColor: '#E3E8ED', borderRadius: '12px' }}>
          <img src={q_no} width='65px' height='75px' alt="bulb" />
          {typeof trigQ === 'string' ?
            <p style={{ fontWeight: 'bold', fontSize: '19px', color: '#44546A', padding: '12px' }}>{trigQ}<span style={{ fontSize: '14px', fontWeight: '400' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Explain with direct reference to the fruits built</span></p> :
            <div style={{ padding: '12px' }}>
              {trigQ.map((item, i) => <p key={i} style={{ fontWeight: 'bold', fontSize: '19px', color: '#44546A' }}>{item}</p>)}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ fontSize: '14px', fontWeight: '400' }}>Share your insights</span>
                <span style={{ fontSize: '14px', fontWeight: '400', fontStyle: 'italic' }}>What is the cost of lost opportunity?</span>
              </div>
            </div>
          }

        </div>
      </div>
    }
    <div style={{ height: 'calc(100% - 105px)', width: '100%', display: 'flex', flexGrow: '1' }}>
      {eventTools ?
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }} >
          <div className="add-text-box-icon-area" style={{ display: 'flex', flexDirection: 'column', height: '50%', padding: '20px 0px' }}>
            <img className='ripple add-text-box-icon' src={positive_outer} onClick={() => addTextBoxes('positive_outer', 'anil')} alt="button" />
            <img className='ripple add-text-box-icon' src={negative_outer} onClick={() => addTextBoxes('negative_outer', 'anil')} alt="button" />
          </div>
          <div className="add-text-box-icon-area" style={{ display: 'flex', flexDirection: 'column', height: '50%', padding: '20px 0px' }}>
            <img className='ripple add-text-box-icon' src={positive_inner} onClick={() => addTextBoxes('positive_inner', 'anil')} alt="button" />
            <img className='ripple add-text-box-icon' src={negative_inner} onClick={() => addTextBoxes('negative_inner', 'anil')} alt="button" />
          </div>
        </div> : null
      }
      <div className="bg-tree" style={{ padding: '14px', borderRadius: '25px', display: 'flex', flexGrow: '1' }}>
        <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
          <p style={{ alignSelf: 'flex-end', marginRight: '20px', color: "#A47D00", fontWeight: 'bold' }}>Anil’s Tree of Fulfillment</p>
          <div className="scroll-bar text-box-area">
            {postFruits('anil', 'positive_outer')}
            {postFruits('anil', 'negative_outer')}
          </div>
          <div className="scroll-bar text-box-area">
            {postFruits('anil', 'positive_inner')}
            {postFruits('anil', 'negative_inner')}
          </div>
        </div>
        <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
          <p style={{ marginLeft: '20px', color: "#A47D00", fontWeight: 'bold' }}>Sunil’s Tree of Fulfillment</p>
          <div className="scroll-bar text-box-area">
            {postFruits('sunil', 'positive_outer')}
            {postFruits('sunil', 'negative_outer')}
          </div>
          <div className="scroll-bar text-box-area">
            {postFruits('sunil', 'positive_inner')}
            {postFruits('sunil', 'negative_inner')}
          </div>
        </div>
      </div>
      {eventTools ?
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }} >
          <div className="add-text-box-icon-area" style={{ display: 'flex', flexDirection: 'column', height: '50%', padding: '20px 0px' }}>
            <img className='ripple add-text-box-icon' src={positive_outer} onClick={() => addTextBoxes('positive_outer', 'sunil')} alt="button" />
            <img className='ripple add-text-box-icon' src={negative_outer} onClick={() => addTextBoxes('negative_outer', 'sunil')} alt="button" />
          </div>
          <div className="add-text-box-icon-area" style={{ display: 'flex', flexDirection: 'column', height: '50%', padding: '20px 0px' }}>
            <img className='ripple add-text-box-icon' src={positive_inner} onClick={() => addTextBoxes('positive_inner', 'sunil')} alt="button" />
            <img className='ripple add-text-box-icon' src={negative_inner} onClick={() => addTextBoxes('negative_inner', 'sunil')} alt="button" />
          </div>
        </div> : null
      }
    </div>
  </div >
}

