import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import ImageSlide from '../../components/ImageSlide';
import ToolNavigation from '../../components/ToolNavigation';
import VideoPlayer from '../../components/VideoPlayer';
// import SlideImg_0 from "./assets/IntroSlide.PNG";
import SlideImg_0 from "./assets/Ex1-S0-SectionBreak.PNG";
import SlideImg_1 from "./assets/Ex1-S1.PNG";
import SlideImg_2 from "./assets/Ex1-S2VideoCover.PNG";
import SlideImg_3 from "./assets/Ex1-S3.PNG";
import SlideImg_4 from "./assets/Ex1-S4.PNG";
import SlideImg_5 from "./assets/Ex1-S5.PNG";
import SlideImg_6 from "./assets/Ex1-S6.PNG";
import SlideImg_7 from "./assets/Ex1-S7.PNG";
import SlideImg_8 from "./assets/Ex1-S8.PNG";

import './style.css';

let UnitOne = (props) => {
  let { isFullScreen, manageFullScreen, dimensions, manageToolData, engagementData, onSlideChange } = props;
  let { slideHeight, slideWidth } = dimensions;

  const [index, setIndex] = useState(0);
  const slides = [
    // { id: 0, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_0} /> },
    { id: 1, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_0} /> },
    { id: 2, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_1} /> },
    { id: 3, obj: <VideoPlayer imgUrl={SlideImg_2} mediaUrl='https://coaching-platform.s3.ap-south-1.amazonaws.com/tata-steel/BeingFunction-v1.1.mp4' /> },
    { id: 4, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_3} /> },
    { id: 5, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_4} /> },
    { id: 6, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_5} /> },
    { id: 7, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_6} /> },
    { id: 8, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_7} /> },
    { id: 9, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_8} /> }
  ];

  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0 }, enter: { opacity: 1 }, leave: { opacity: 0 },
  });
  // useEffect(() => { setIndex(sceneCount) }, [sceneCount]);

  return <div className='tool-holder'>
    <div className='slide-cntr' style={{ width: slideWidth, height: slideHeight }}>
      {transitions.map(({ item, props, key }) => (
        <animated.div
          key={key}
          className="slide"
          style={{ ...props, position: 'absolute' }}
        >
          {item.obj}
        </animated.div>
      ))}
    </div>
    <ToolNavigation
      currentScene={index}
      sceneCount={slides.length}
      next={(index) => {
        manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index + 1 } });
        onSlideChange(engagementData);
        setIndex(index + 1);
      }}
      previous={(index) => {
        manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index - 1 } });
        onSlideChange(engagementData);
        setIndex(index - 1)
      }}
      isToolFullScreen={isFullScreen}
      toggleFullScreen={manageFullScreen}
    />
  </div>
}

export default UnitOne;