import React from "react";
import Button from '@material-ui/core/Button';
import { connectEngagementSessionData } from '../../containers/application/channelDashboard/redux_actions'
let server = "https://us-central1-coaching-platform-illumine.cloudfunctions.net/serverResponse";

function startSession({ sessionId, instanceId, uid, engagementId, manageToolData }) {
  let requestOptions = {
    method: 'POST',
    body: JSON.stringify({ sessionId, instanceId, uid, engagementId }),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  };

  fetch(`${server}/classSessionV2`, requestOptions)
    .catch(err => {
      console.log(err)
    }).then(response => {
      response
        .json()
        .then(response => {
          if (!response.success) {
          } else {
            console.log(response);
            let { engagementSessionId, engagementId, otp } = response.msg;
            manageToolData({ toolId: engagementId, content: { engagementSessionId, otp } })
            connectEngagementSessionData(engagementSessionId)
          }
        });
    });
}

export default ({ sessionId, instanceId, uid, engagementId, engagementData, manageToolData }) => {
  let engagementSessionId = engagementData[engagementId] && engagementData[engagementId].engagementSessionId
    ? engagementData[engagementId].engagementSessionId : null;
  return <div style={{
    background: '#F2F2F2', width: '100%', height: '100%',
    padding: '0px 32px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around'
  }}>
    <div style={{ padding: 16, background: '#FFF', borderRadius: 16, maxWidth: 490 }}>
      <div style={{ fontWeight: '500', fontSize: 20 }}>Participants take the Pre-Session Assessment</div>
      {engagementData[engagementId] && engagementData[engagementId].otp
        ? <div>{engagementData[engagementId].otp}</div>
        : <div>ASD</div>}

      <Button
        variant="contained"
        onClick={(e) => { startSession({ sessionId, instanceId, uid, engagementId, manageToolData }) }}
      >
        {engagementData[engagementId] && engagementData[engagementId].otp ? 'Restart Session' : 'Start Session'}
      </Button>
    </div>
    <div style={{ padding: 16, background: '#FFF', borderRadius: 16 }}>
      {Object.keys(engagementData[engagementSessionId] && engagementData[engagementSessionId].participants
        ? engagementData[engagementSessionId].participants
        : {}).map(pid => {
          let a = engagementData[engagementSessionId].participants[pid]
          return <div key={pid}>{a.name}</div>
        })}
    </div>
  </div>
}