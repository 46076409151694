import React, { useState } from 'react';
import { useTransition, animated } from 'react-spring';
import ImageSlide from '../../components/ImageSlide';
import ToolNavigation from '../../components/ToolNavigation';
import FruitMap from './fruitMap';
import VideoPlayer from '../../components/VideoPlayer';
import SlideImg_1 from "./assets/Ex3-S0-SectionBreak.PNG";
import SlideImg_2 from "./assets/Ex3-S1.PNG";
// import SlideImg_5 from "./assets/Ex3-S2-01.PNG";
import SlideImg_6 from "./assets/Ex3-S2-AV-1.PNG";
import SlideImg_7 from "./assets/Ex3-S2-AV-2.PNG";
import SlideImg_8 from "./assets/Ex3-S2-AV-3.PNG";
import SlideImg_8_1 from "./assets/Ex3-S2-AV-4.PNG";
import SlideImg_8_2 from "./assets/Ex3-S2-AV-5.PNG";
import SlideImg_8_3 from "./assets/Ex3-S2-AV-6.PNG";
import SlideImg_8_4 from "./assets/Ex3-S2-AV-7.PNG";
import SlideImg_9 from "./assets/Ex3-S4.PNG";
import SlideImg_10 from "./assets/Ex3-S5-A.PNG";
import './style.css';

let cases = [<div>
  <div style={{ fontWeight: 'bold' }}>When we choose to take on a stretch goal.</div>
  <div>For example, the civil works for a project have fallen behind schedule by 6 months for various reasons. The team now decides to change their approach and do what it takes to bring the project back on schedule.</div>
  <div style={{ marginTop: '1rem', fontWeight: 'bold' }}>
    <div style={{ color: 'rgb(230,71,8)' }}>Is this an act of contribution? Why?</div>
    <ol>
      <li style={{ color: 'rgb(230,71,8)' }}>Will value creation for organization take place? How?</li>
      <li style={{ color: 'rgb(230,71,8)' }}>Will team members develop and strengthen themselves? How?</li>
    </ol>
  </div>
</div>,
<div>
  <div style={{ fontWeight: 'bold' }}>A person makes a commitment without thinking through.</div>
  <div>For example, when asked by the boss for the timeline for completion of a job, the engineer assures it will be completed within 30 days, as he feels that is what the boss expects to hear. But he actually ends up taking 60 days.</div>
  <div style={{ marginTop: '1rem', color: 'rgb(230,71,8)', fontWeight: 'bold' }}>What happens because of this choice?</div>
</div>,
<div>
  <div style={{ fontWeight: 'bold' }}>Share any one example of a contribution event you have experienced or seen</div>
  <ul stye={{ fontSize: 13 }}>
    <div>TRIGGERS FOR THINKING:<br />Think of instances where you –</div>
    <div className='bulletPointForCaseStudy'>
      <ul>
        <li>Worked collaboratively, in a win-win way, with partners or teams of other departments.</li>
        <li>Created significant value for the organization through innovation.</li>
        <li>Improved the method of working or approach of the team and thereby created significant value.</li>
        <li>Turned a failure into a success, through your approach.</li>
        <li>Built trust amongst partners and customers through your approach</li>
      </ul>
    </div>
  </ul>
</div>]

let UnitOne = (props) => {
  let { isFullScreen, manageFullScreen, dimensions, manageToolData, engagementData, onSlideChange } = props;
  let { slideHeight, slideWidth } = dimensions;
  const [index, setIndex] = useState(0);
  const slides = [
    { id: 0, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_1} /> },
    { id: 1, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_2} /> },
    { id: 2, obj: <FruitMap story={cases[0]} id='3A-1' manageTool={manageToolData} engagementData={engagementData} /> },
    { id: 3, obj: <FruitMap story={cases[1]} id='3A-2' manageTool={manageToolData} engagementData={engagementData} /> },
    { id: 4, obj: <FruitMap story={cases[2]} id='3A-3' manageTool={manageToolData} engagementData={engagementData} /> },
    // { id: 5, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_5} /> },
    { id: 6, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_6} /> },
    { id: 7, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_7} /> },
    { id: 8, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_8} /> },
    { id: 13, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_9} /> },
    { id: 14, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_10} /> }
  ];

  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0 }, enter: { opacity: 1 }, leave: { opacity: 0 },
  });


  return <div className='tool-holder'>
    <div className='slide-cntr' style={{ width: slideWidth, height: slideHeight }}>
      {transitions.map(({ item, props, key }) => (
        <animated.div
          key={key}
          className="slide"
          style={{ ...props, position: 'absolute' }}
        >
          <div style={{ height: '100%' }}>{item.obj}</div>
        </animated.div>
      ))}
    </div>
    <ToolNavigation
      currentScene={index}
      sceneCount={slides.length}
      next={(index) => {
        manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index + 1 } });
        onSlideChange(engagementData);
        setIndex(index + 1);
      }}
      previous={(index) => {
        manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index - 1 } });
        onSlideChange(engagementData);
        setIndex(index - 1)
      }}
      isToolFullScreen={isFullScreen}
      toggleFullScreen={manageFullScreen}
    />
  </div>
}

export default UnitOne;