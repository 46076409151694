import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import "./channelDashboard.css";


import LearningCycle from "../../../components/dashboard_learningCycleView";
import channelData from "../../../modals/d_channels";
import { activateComponent, manageFullScreen, manageToolData, updateUserEngagement, resetTracker } from "./redux_actions";
import { Link } from "react-router-dom";
const { innerWidth: WinWidth, innerHeight: WinHeight } = window;
const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  }
});

let channelDashboard = class channelDashboard extends Component {
  render() {
    const { channelState } = this.props;
    let engagementData = this.props.channelState.engagementData;
    let channelId = this.props.match.params.channelId;
    let sessionId = this.props.match.params.sessionId;
    let channel = channelData[channelId];
    let uid = this.props.auth.user.user.uid;
    let activeStepId = channelState.activeComponents[channelId]
      ? channelState.activeComponents[channelId].milestone
      : null;

    let activeStep = activeStepId
      ? channel.organisers.units[activeStepId]
        ? channel.organisers.units[activeStepId]
        : channel.organisers.units[Object.keys(channel.organisers.units)[0]]
      : "intro";
    let slideHeight, slideWidth;
    let isFullScreen = channelState.toolFullScreen ? true : false;

    if (isFullScreen) {
      slideHeight = WinHeight - 28;
      slideWidth = WinWidth;
    } else {
      slideHeight = WinHeight - 132;
      slideWidth = 0.7 * WinWidth;
    }

    let dimensions = { slideHeight, slideWidth };
    let Layout = layout(activeStep.type, activeStep, isFullScreen, this.props.manageFullScreen,
      this.props.manageToolData, engagementData, dimensions, channelId, sessionId);

    return <div className="_dashboardCntr">
      <div
        style={{
          margin: "1rem",
          fontWeight: 600,
          fontSize: '1rem'
        }}>
        <Link to='/application/dashboard' style={{
          display: "flex",
          alignItems: "center"
        }}><svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none" />
            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" /></svg>My Modules</Link>
      </div>

      <div className="programMapCntr">
        <div style={{
          width: "25%", overflowY: 'scroll', overflowX: 'hidden'
        }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%"
            }}>
            <div className="programOverviewTrigger">
              <div className="ch-title">{channel.title}</div>
            </div>
            <div className="program-flow-cntr">
              <LearningCycle
                stepperstate={{
                  activeStepId
                }}
                referenceId={channelId}
                className="steps"
                elements={channel.organisers}
                onclick={(el) => {
                  this.props.resetTracker();
                  el.instanceId = Date.now();
                  el.sessionId = sessionId;
                  el.uid = uid;
                  this.props.activateComponent(el);
                }} />
            </div>
          </div>
        </div>
        <div style={{ width: "75%", display: 'flex', justifyContent: 'center' }}>
          {!activeStepId
            ? <img style={{ maxWidth: '100%', maxHeight: '100%', height: 'auto', width: 'auto' }}
              src={channel.introSlide} />
            : <div className={`step-details-cntr${isFullScreen ? " fullscreen" : ""}`}>
              {isFullScreen ? null : Layout}
            </div>}
        </div>
      </div>
      <Modal
        classes={{ root: 'fullscreen' }}
        open={isFullScreen}
        onClose={() => { this.props.manageFullScreen(false) }}
        allowFullScreen
        aria-labelledby="fullscreen-title"
        aria-describedby="fullscreen-description"
      ><div>{Layout}</div>
      </Modal>
    </div>
  }
}

function layout(type, data, isFullScreen, mfs, mtd, engagementData, dimensions) {
  let width = document.body.offsetWidth * 0.68;
  let height = width * 569 / 960;
  let onSlideChange = (engagementData) => { updateUserEngagement(engagementData) }
  switch (type) {
    case 'ppt':
      return <iframe
        title="i-frame"
        src={data.src}
        frameBorder="0"
        width={width}
        height={height}
        allowFullScreen="true"></iframe>
    case 'tool':
      let Tool = data.tool;
      return <Tool dimensions={dimensions} isFullScreen={isFullScreen} manageFullScreen={mfs}
        manageToolData={mtd} engagementData={engagementData} onSlideChange={onSlideChange} />
    default:
      return null
  }
}

const mapStateToProps = state => ({ auth: state.auth, channelState: state.channelState });

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    activateComponent, manageFullScreen, manageToolData, resetTracker
  }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withStyles(styles)(channelDashboard));