import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Dialog from '@material-ui/core/Dialog';

import Capsule from "../../../components/MyCapsules";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import FirstTimeUserMsg from './firstTimeUserMsg';
import AccessCode from './accessCode';

import channelData from "../../../modals/d_channels";
import { resetChannelState } from "./redux_actions";
import AddCircleIcon from '@material-ui/icons/AddCircle';

import "./applicationDashboard.css";

let applicationDashboard = (props) => {
  let { user } = props.auth.user;
  const [isAccessCodeOpen, setAccessCodeOpen] = useState(false);
  let mySubscriptions = props.userState.mySubscriptions;
  let isSubscribed = Object.keys(mySubscriptions).length;
  let sorted = Object
    .keys(mySubscriptions)
    .length
    ? Object
      .keys(mySubscriptions)
      .sort((a, b) => {
        return (mySubscriptions[a].subscribedOn > mySubscriptions[b].subscribedOn
          ? 1
          : -1)
      })
    : null;
  let sorted_products = sorted
    ? sorted.map(id => {
      return id;
    })
    : null;
  props.resetChannelState();
  return <div className="channelDashboardCntr scrollbar" style={{
    width: '100%'
  }}>
    <Header active='myCapsule' />
    <div className="channelsCntr">
      {sorted
        ? <div>
          {sorted.includes('gtu_1') && <div className='capsule-cntr'
            style={{
              borderTop: 0,
              fontSize: "1.5rem",
              backgroundColor: '#f8e07c'
            }}>
            <h1 style={{ fontSize: '1.5rem', marginBottom: '20px' }}>Are you a GTU faculty member?</h1>

            <p style={{ fontSize: '1.25rem', marginBottom: '20px' }}>This platform will soon be discontinued. To register on the new platform, <a href='https://forms.gle/Ph1E2pDQZaHqzU977' style={{
              borderTop: 0,
              fontSize: '1.25rem',
              textDecoration: 'underline'
            }}>kindly fill this form by clicking here</a></p>

            <p style={{ marginBottom: '15px' }}>By filling this, our team will connect with you to help you get access to the latest Pedgog coaching platform where you can </p>

            <p style={{ marginBottom: '15px' }}>1. Conduct classes using interactive tools</p>

            <p style={{ marginBottom: '15px' }}> 2. Prepare in a systematic manner</p>

            <p style={{ marginBottom: '15px' }}> 3. Be part of a coaching community </p>


            <p style={{ marginBottom: '15px' }}> For any queries, please contact: Mr. Ashok Shenoy: +919845647008 or  <a href=' http://bit.ly/contributorfacguide' style={{
              borderTop: 0,

              textDecoration: 'underline'
            }}>visit this page </a></p></div>}
          <div
            className='capsule-cntr'
            style={{
              borderTop: 0,
              fontSize: "1.5rem"
            }}>My Modules</div>
          {buildappDashboard(sorted_products, channelData)}
          <div style={{ float: 'right', margin: '1rem 2rem' }} onClick={() => { setAccessCodeOpen(true) }}>
            <AddCircleIcon style={{ fontSize: 40, fill: '#7CBA00' }} />
          </div>
        </div>
        : <FirstTimeUserMsg user={user} />}
    </div>
    <Footer />
    <Dialog open={isAccessCodeOpen} onClose={() => { setAccessCodeOpen(false) }}>
      <AccessCode user={user} onSuccess={() => { setAccessCodeOpen(false) }} onFailure={() => { setAccessCodeOpen(false) }} />
    </Dialog>
  </div>
}

const buildappDashboard = (sorted_products, channelData) => {
  return sorted_products.map(key => {
    let channel = channelData[key];
    if (channel) { return <Capsule key={key} cardData={channel} buttonText="Run Session" /> }
    return null;
  });
};


const mapStateToProps = state => ({ userState: state.userState, auth: state.auth, channelState: state.channelState });
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ resetChannelState }, dispatch);
}
export default withRouter(connect(mapStateToProps, matchDispatchToProps)(applicationDashboard));