// import overview_faceTheBrute from "../Tools/overview_faceTheBrute";
import WhoIsAContributor from "../Tools/whoIsAContributor";
import ExploreFullfillment from "../Tools/exploreFullfillment";
import PowerOfContribution from "../Tools/powerOfContribution";
import Tata from "../Tools/tata";
import Conclusion from '../Tools/conclusion';

import GTU001 from '../Tools/gtu001/001';
import GTU002 from '../Tools/gtu001/002';
import GTU003 from '../Tools/gtu001/003';
import GTU004 from '../Tools/gtu001/004';
import GTU005 from '../Tools/gtu001/005';
import GTU006 from '../Tools/gtu001/006';
import GTU007 from '../Tools/gtu001/007';

import GTU201 from '../Tools/gtu002/001';
import GTU202 from '../Tools/gtu002/002';
import GTU203 from '../Tools/gtu002/003';
import GTU204 from '../Tools/gtu002/004';
import GTU205 from '../Tools/gtu002/005';
import GTU206 from '../Tools/gtu002/006';

import GTU207 from '../Tools/gtu003/001';
import GTU208 from '../Tools/gtu003/002';
import GTU209 from '../Tools/gtu003/003';
import GTU210 from '../Tools/gtu003/004';

import enp201 from '../Tools/tslhr_ownership/001';
import enp202 from '../Tools/tslhr_ownership/002';
import enp203 from '../Tools/tslhr_ownership/003';
import enp204 from '../Tools/tslhr_ownership/004';

import enc301 from '../Tools/tslhr_collaboration/001';
import enc302 from '../Tools/tslhr_collaboration/002';
import enc303 from '../Tools/tslhr_collaboration/003';
import enc304 from '../Tools/tslhr_collaboration/004';
import enc305 from '../Tools/tslhr_collaboration/005';

import GTU211 from '../Tools/gtu004/001';
import GTU212 from '../Tools/gtu004/002';
import GTU213 from '../Tools/gtu004/003';

import experiments from "../Tools/experiments/index";
import write from "../Tools/experiments/write";

import enp309 from '../Tools/tslhr_responsiveness/001';
import enp310 from '../Tools/tslhr_responsiveness/002';
import enp311 from '../Tools/tslhr_responsiveness/003';

import enp312 from '../Tools/tslenp_trust/001';
import enp313 from '../Tools/tslenp_trust/002';
import enp314 from '../Tools/tslenp_trust/003';
import enp315 from '../Tools/tslenp_trust/004';


import enc316 from '../Tools/tslhr_aspirations/001';
import enc317 from '../Tools/tslhr_aspirations/002';
import enc318 from '../Tools/tslhr_aspirations/003';
import enc319 from '../Tools/tslhr_aspirations/004';
import enc320 from '../Tools/tslhr_aspirations/005';

import GTU214 from '../Tools/gtu005/001';
import GTU215 from '../Tools/gtu005/002';
import GTU216 from '../Tools/gtu005/003';
import GTU217 from '../Tools/gtu005/004';
import GTU218 from '../Tools/gtu005/005';
import GTU219 from '../Tools/gtu005/006';
import GTU220 from '../Tools/gtu005/007';
import GTU221 from '../Tools/gtu005/008';

import enc306 from '../Tools/tslhr_valuecreation/001';
import enc307 from '../Tools/tslhr_valuecreation/002';
import enc308 from '../Tools/tslhr_valuecreation/003';


import flex_trust_001 from '../Tools/trust_flex/001';
import flex_trust_002 from '../Tools/trust_flex/002';
import flex_trust_003 from '../Tools/trust_flex/003';
import flex_trust_004 from '../Tools/trust_flex/004';


import enc321 from '../Tools/tslhr_evolvingProfessionally/001';
import enc322 from '../Tools/tslhr_evolvingProfessionally/002';
import enc323 from '../Tools/tslhr_evolvingProfessionally/003';
import enc324 from '../Tools/tslhr_evolvingProfessionally/004';


import GTU222 from '../Tools/gtu006/001';
import GTU223 from '../Tools/gtu006/002';
import GTU224 from '../Tools/gtu006/003';

import FOS1 from '../Tools/FOS-Demo/001';

import enp205 from '../Tools/tslenp_wfw/001';
import enp206 from '../Tools/tslenp_wfw/002';
import enp207 from '../Tools/tslenp_wfw/003';
import enp208 from '../Tools/tslenp_wfw/004';
import enp209 from '../Tools/tslenp_wfw/005';



// let {posts_001, posts_002} = posts;

export const units = {
  "uo_001": {
    title: 'Who is a contributor?',
    type: 'tool',
    tool: WhoIsAContributor,
    time: '[~45 min]'
  },
  "uo_002": {
    title: 'Life rewards of being a contributor',
    type: 'tool',
    tool: ExploreFullfillment,
    time: '[~45 min]'
  },
  "uo_003": {
    title: 'The power of Consistent Contribution',
    type: 'tool',
    tool: PowerOfContribution,
    time: '[~45 min]'
  },
  "uo_004": {
    title: 'Becoming a Contributor: 4 levers',
    type: 'tool',
    tool: Tata,
    time: '[~5 min]'
  },
  "uo_005": {
    title: 'Closure',
    type: 'tool',
    tool: Conclusion,
    time: '[~5 min]'
  },
  "uo_006": {
    title: "A new vision of values education",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vRUNNtX17h9DHGNbnvuYf1qVmBwfsiWuDOr71KKy0rQ5hJ7yedSiSCLckqBwuDHOw/embed?start=false&loop=false&delayms=18000000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[2 hrs]"
  },
  "uo_007": {
    title: "How Values are Assimilated",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vT3QsIM_GWu0CR1SZ-3L4jJD5W0jHn63PAY9QlYpAw7j81RiSO5h78z7zCKecP2zA/embed?start=false&loop=false&delayms=18000000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[1 hrs]"
  },
  "uo_008": {
    title: "Program Curriculum Framework",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vTPJx205y2mKS9P5AJsFIA-xlc8_DB3H0ju5Sr8pHdtq7EB49k3bAL2ZYxZTi79gw/embed?start=false&loop=false&delayms=18000000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[~10 mins]"
  },
  "uo_009": {
    title: "How the program was developed",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vQ-bp32Rt9jwXu7i5TiWEYBEaYliTlSMWeQTE5ROzT1fot8FwWU2BSzsvUuTkfOzA/embed?start=false&loop=false&delayms=18000000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[~15 mins]"
  },
  "uo_010": {
    title: "How to conduct the program",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vRj3ow4bYC8bJhRFt9ZEGMC5lgyg2TUnRoxkwEk3vIQVCZlqg3HqANZMLs751QayA/embed?start=false&loop=false&delayms=18000000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[20 mins]"
  },
  "uo_011": {
    title: "Practice Assignment",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vRppSUMD36UUWmSpIK39HrhDsKLSq6i45pzuok_3U9LB9sANx_kfkEbXzUCFKvAXw/embed?start=false&loop=false&delayms=18000000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[10 mins]"
  },
  "uo_012": {
    title: "Goals of Year 1",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vT_CbSntZBBAGxQxAdDp6kLGxkPen7hQiP8JlpGH3-jkb8OVaBV1-ZPpvEE8_5_-g/embed?start=false&loop=false&delayms=1800000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[10 mins]"
  },
  "uo_013": {
    title: "Role of the Facilitator in Year 1",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vRI0zOiVx9C_mb9b_C6M9mxDAD_-T-SuscRtqv9bcobHvlmPRoNKq7do1ng1wvA5g/embed?start=false&loop=false&delayms=1800000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[50 mins]"
  },
  "uo_0014": {
    title: "Practice Sessions for Year 1",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vS41PRa8slRfRisRvjtzYcLRxaSC48Hno2P5UY5e1Z_KyK06U-pdbLMe1s6LA71HA/embed?start=false&loop=false&delayms=1800000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[3 hours]"
  }, "uo_012_h": {
    title: "Goals of Year 1",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vSLrfRlCqcZzO6bjMQdWlCCMijKydFciQ6icU9WbH476Wjynq5Zli43tSPa1gvIzA/embed?start=false&loop=false&delayms=1800000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[10 mins]"
  },
  "uo_013_h": {
    title: "Role of the Facilitator in Year 1",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vRDWe2tCFwK7mrcJdwIg4YR7ezTGeGnBFVwDDfmHmftJPSLCf_KkBnvT62FLCnWpQ/embed?start=false&loop=false&delayms=1800000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[50 mins]"
  },
  "uo_0014_h": {
    title: "Practice Sessions for Year 1",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vRBtr9c5bQtqaRb5Uwz4TiiKRfhdfHHGOQg0fxzGtKJNYs4ksFDXtLnRTjl3nFTlg/embed?start=false&loop=false&delayms=1800000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[3 hours]"
  },
  "uo_0015": {
    title: "Goals of Year 2",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vSSzv2YcbzMzvIpAbSn4oZzGYV9HaFDLzNi2VxwsPZauaDuGk5iZHZdRsNluavGJg/embed?start=false&loop=false&delayms=1800000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[10 mins]"
  },
  "uo_0016": {
    title: "Role of the Facilitator in Year 2",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vTMyhcyWv1xA43e-A9kVytHxoC0vtNcQBEa4yuuw_bc5df7RYgThfqi2mfmqvalLw/embed?start=false&loop=false&delayms=1800000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[50 mins]"
  },
  "uo_0017": {
    title: "Practice Sessions for Year 2",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vRzyPGMdfYNwgKTQOQn38vjlQPHUESUOzJto0PRgwgxoYjRWB3_wTE73zSgW9qnSA/embed?start=false&loop=false&delayms=1800000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[3 hours]"
  },
  "uo_0015_h": {
    title: "Goals of Year 2",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vSMT3KFmBxMNnTpyJsdZzxCEjRlfs3Oq9Q8csOx3-GL0Yt1VnLaltLfBV0FERWGFA/embed?start=false&loop=false&delayms=1800000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[10 mins]"
  },
  "uo_0016_h": {
    title: "Role of the Facilitator in Year 2",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vShleICFXkpTFbW1mts-TpiH0yAH6116XzrGgu45ZJpT2xoNWOQE35dhnocxCg4Eg/embed?start=false&loop=false&delayms=1800000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[50 mins]"
  },
  "uo_0017_h": {
    title: "Practice Sessions for Year 2",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vTsEjzb9hkvgPBxYHOXuX7Wgm1ZtXPyLaDF-5vq-2b2DSsTUFcgz1eXY0_DFzgCsw/embed?start=false&loop=false&delayms=1800000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[3 hours]"
  },
  "uo_0018": {
    title: "Goals of Year 3",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vT7pM1PZlCUcQPI3X2Ll7RsAaBGBcVyG4ZaGx9UjO6dXPsfMlKT_H5zW7QQFDPCUA/embed?start=false&loop=false&delayms=1800000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[10 mins]"
  },
  "uo_0019": {
    title: "Role of the Facilitator in Year 3",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vRssQ4eUJJp2m5xMJ8YyaJjpFte7DKFXupf6b8j2W7oinQnkIR6ozkhmvyukh8bJQ/embed?start=false&loop=false&delayms=1800000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[50 mins]"
  },
  "uo_0020": {
    title: "Practice Sessions for Year 3",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vTFdQOc_KERn40-taIuTBj89PZxhVLpu7T1YVHooHV0ehmU8lCkpheKrCSchGvkeg/embed?start=false&loop=false&delayms=1800000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[3 hours]"
  },
  "uo_0018_h": {
    title: "Goals of Year 3",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vR48O5kmwrr2i0hNlCI7la4aLLFOg3IK0Gy04gxaSZQZghsmYHtRtqGEVokJv8u-w/embed?start=false&loop=false&delayms=1800000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[10 mins]"
  },
  "uo_0019_h": {
    title: "Role of the Facilitator in Year 3",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vRe85I1Y-KhtKz-Vn_wzlHQ_CZgjW_qCuQ_pmuSD5o-nSI5Uu27LhUIs4LSHd4dGQ/embed?start=false&loop=false&delayms=1800000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[50 mins]"
  },
  "uo_0020_h": {
    title: "Practice Sessions for Year 3",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vRIskQbTgPQpMH8hfouBqAnTsydXd0MVd7V8gf0yt1CpQVcuHaEI26zKoRM1cTtUQ/embed?start=false&loop=false&delayms=1800000",
    // srcHn: "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
    time: "[3 hours]"
  },
  "uo_014": {
    title: "The future of work",
    type: "tool",
    tool: GTU001,
    time: "[15 mins]"
  },
  "uo_015": {
    title: "Results-orientation and making things happen",
    type: "tool",
    tool: GTU002,
    time: "[15-20 mins]"
  },
  "uo_016": {
    title: "Demonstrating human concern",
    type: "tool",
    tool: GTU003,
    time: "[15-20 mins]"
  },
  "uo_017": {
    title: "Excellence in work",
    type: "tool",
    tool: GTU004,
    time: "[15-20 mins]"
  },
  "uo_018": {
    title: "Acting in a way that build trust",
    type: "tool",
    tool: GTU005,
    time: "[15-20 mins]"
  },
  "uo_019": {
    title: "The Contributor's Checklist",
    type: "tool",
    tool: GTU006,
    time: "[15-20 mins]"
  },
  "uo_020": {
    title: "Closure Reflection",
    type: "tool",
    tool: GTU007,
    time: "[10-15 mins]"
  },
  "uo_021": {
    title: "What is identity?",
    type: "tool",
    tool: GTU201,
    time: "[30 mins]"
  },
  "uo_022": {
    title: "1: Defining myself in terms of the difference I can make",
    type: "tool",
    tool: GTU202,
    time: "[15-20 mins]"
  },
  "uo_023": {
    title: "2: Defining myself in terms of responsibilities I take ownership for",
    type: "tool",
    tool: GTU203,
    time: "[15-20 mins]"
  },
  "uo_024": {
    title: "3: Defining myself in terms of my future possibilities",
    type: "tool",
    tool: GTU204,
    time: "[15-20 mins]"
  },
  "uo_025": {
    title: "4: The Learner Identity",
    type: "tool",
    tool: GTU205,
    time: "[15-20 mins]"
  },
  "uo_026": {
    title: "Becoming future-ready by developing a dynamic identity",
    type: "tool",
    tool: GTU206,
    time: "[10-15 mins]"
  },
  "uo_027": {
    title: "Exploration 1: Who is a Creator of Destiny?",
    type: "tool",
    tool: GTU207,
    time: "[40 mins]"
  },
  "uo_028": {
    title: "Exploration 2: What mode of response do future workplaces demand?",
    type: "tool",
    tool: GTU208,
    time: "[40-45 mins]"
  },
  "uo_029": {
    title: "Exploration 3: Living as a Creator of Destiny",
    type: "tool",
    tool: GTU209,
    time: "[25-30 mins]"
  },
  "uo_030": {
    title: "Exploration 4: In your career, what is your typical mode of response?",
    type: "tool",
    tool: GTU210,
    time: "[10-15 mins]"
  },
  "uo_031": {
    title: "What is Ownership",
    type: "tool",
    tool: enp201,
    time: "[15-20 mins]"
  },
  "uo_032": {
    title: "Level 1: Role Ownership",
    type: "tool",
    tool: enp202,
    time: "[30-45 mins]"
  },
  "uo_033": {
    title: "Level 2: End to End Ownership",
    type: "tool",
    tool: enp203,
    time: "[30 mins]"
  },
  "uo_034": {
    title: "Level 3: Firm Ownership",
    type: "tool",
    tool: enp204,
    time: "[30 mins]"
  },

  // tslhr collaboration
  "uo_035": {
    title: "What is Collaboration?",
    type: "tool",
    tool: enc301,
    time: "[15 mins]"
  },
  "uo_036": {
    title: "Level 1: Trust communication",
    type: "tool",
    tool: enc302,
    time: "[30-35 mins]"
  },
  "uo_037": {
    title: "Level 2: Collaborative Action",
    type: "tool",
    tool: enc303,
    time: "[30 mins]"
  },
  "uo_038": {
    title: "Level 3: Co-creation",
    type: "tool",
    tool: enc304,
    time: "[30 mins]"
  },

  "uo_0039": {
    title: "Closure",
    type: "tool",
    tool: enc305,
    time: "[10 mins]"
  },

  // ACP_IWP
  "uo_0021": {
    title: "Core vision of the Awakened Citizen Program",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vTXnk5mF1g2_QGgXilszYMUogqWNff14XGKBl2WHd7MpAaCAvXbifSoZmld62LTxA/embed?start=false&loop=false&delayms=1800000",
    time: "[10 - 15 mins]"
  },
  "uo_0022": {
    title: "Framework underlying the Awakened Citizen Program",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vRZAka-HIgDYy-qCQQHqkqHHhBO7jkL8cTB1fvJiXpluOeGHWNyzGcHi53d_2AnXA/embed?start=false&loop=false&delayms=1800000",
    time: "[10 - 15 mins]"
  },
  "uo_0023": {
    title: "Translating the vision of infinite human possibilities into a program",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vSbsipRbBQD1gqnOVzinEX5rcVdr1U7SM1VNEEa3QbGIMAKfyP1O7c--otum9SrbA/embed?start=false&loop=false&delayms=1800000",
    time: "[30 - 40 mins]"
  },
  "uo_0024": {
    title: "How the program is taught",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vQLdYbe6p8uPpH-MCwhE1J6VafXaKEGSs0K-t1Sbd5nnqkfY5MaVd0aD-szOCOX2w/embed?start=false&loop=false&delayms=1800000",
    time: "[45 - 50 mins]"
  },
  "uo_0025": {
    title: "How to run the program in your schools",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vTDeO52qVF_PMDWloCeWXfxRc76axX9MPcWh5M5SPmYdfDJ29XveWHs05l1HkEArA/embed?start=false&loop=false&delayms=1800000",
    time: "[15 - 20 mins]"
  },
  "uo_0026": {
    title: "Impact of the program",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vT2euADfyIHgbTDw_NIU3A3S4BSSmpfOLY4-RKShHa84SgsCG-_am3KYqjqqHTeaA/embed?start=false&loop=false&delayms=1800000",
    time: "[10 - 15 mins]"
  },

  // GTU 4 Sustainable Success
  "uo_0211": {
    title: "Exploration 1: How do we measure success?",
    type: "tool",
    tool: GTU211,
    time: "[20 mins]"
  },
  "uo_0212": {
    title: "Exploration 2: Why do the measures of success we choose matter?",
    type: "tool",
    tool: GTU212,
    time: "[35-40 mins]"
  },
  "uo_0213": {
    title: "Exploration 3: How to build our engine of Sustainable Success",
    type: "tool",
    tool: GTU213,
    time: "[1 hr]"
  },
  "experiments": {
    title: "Text Bubbles",
    type: "tool",
    tool: experiments,
    description: "demonstrates the capability of inclass engagement",
    time: "[30 mins]"
  },
  "write":{
    title:"Write Anywhere",
    type: "tool",
    tool: write,
    description:"demonstrates the usecases of write",
    time:"[as u wish]"
  },
  // Responsiveness
  "uo_039": {
    title: "Exploration 1: What is Responsiveness?",
    type: "tool",
    tool: enp309,
    time: "[~ 30 min.]"
  },
  "uo_040": {
    title: "Exploration 2: The Levels of Responsiveness",
    type: "tool",
    tool: enp310,
    time: "[~ 1 hr.]"
  },
  "uo_041": {
    title: "Exploration 3: Applying responsiveness",
    type: "tool",
    tool: enp311,
    time: "[~ 20 min.]"
  },

  // Tata steel enp trust surplus
  "uo_042": {
    title: "Exploration 1: What is Trust Surplus?",
    type: "tool",
    tool: enp312,
    time: "[~ 1 hr.]"
  },
  "uo_043": {
    title: "Exploration 2: How is Trust Surplus built?",
    type: "tool",
    tool: enp313,
    time: "[~ 45 min.]"
  },
  "uo_044": {
    title: "Exploration 3: Strategies for building Trust Surplus",
    type: "tool",
    tool: enp314,
    time: "[~ 1 hr. 15 min.]"
  },
  "uo_045": {
    title: "Exploration 4: Go Forward",
    type: "tool",
    tool: enp315,
    time: "[~ 30 min.]"
  },

  // Growing Aspirarions tslhr
  "uo_046": {
    title: "Exploration 1: What does growing in aspiration mean?",
    type: "tool",
    tool: enc316,
    time: "[~ 15-20 min.]"
  },
  "uo_047": {
    title: "Exploration 2: How to grow into level 1 aspiration?",
    type: "tool",
    tool: enc317,
    time: "[~ 30 min.]"
  },
  "uo_048": {
    title: "Exploration 3: How to grow into level 2 aspiration?",
    type: "tool",
    tool: enc318,
    time: "[~ 30 min.]"
  },
  "uo_049": {
    title: "Exploration 4: How to grow into level 3 aspiration?",
    type: "tool",
    tool: enc319,
    time: "[~ 30 min.]"
  },
  "uo_050": {
    title: "Exploration 5: Closure",
    type: "tool",
    tool: enc320,
    time: "[~ 10 min]"
  },

  // GTU 5 Career Dev
  "uo_051": {
    title: "Exploration 1:  Introduction: Developing in a contributive career",
    type: "tool",
    tool: GTU214,
    time: "[~ 5 min.]"
  },
  "uo_052": {
    title: "Exploration 2: Developing as an innovator-creator",
    type: "tool",
    tool: GTU215,
    time: "[~ 20 min]"
  },
  "uo_053": {
    title: "Exploration 3: Developing as a builder-entrepreneur",
    type: "tool",
    tool: GTU216,
    time: "[~ 20 min]"
  },
  "uo_054": {
    title: "Exploration 4: Developing as a change maker",
    type: "tool",
    tool: GTU217,
    time: "[~ 20 min]"
  },
  "uo_055": {
    title: "Exploration 5: Developing as an achiever",
    type: "tool",
    tool: GTU218,
    time: "[~ 20 min]"
  },
  "uo_056": {
    title: "Exploration 6: Developing as a manager-leader",
    type: "tool",
    tool: GTU219,
    time: "[~ 20 min]"
  },
  "uo_057": {
    title: "Exploration 7:  Developing as a researcher",
    type: "tool",
    tool: GTU220,
    time: "[~ 20 min]"
  },
  "uo_058": {
    title: "Exploration 8: Closure Reflection ",
    type: "tool",
    tool: GTU221,
    time: "[~ 5-10 min.]"
  },

  // Tata steel culture lab value creators
  "uo_059": {
    title: "Exploration 1: Understanding what is value creation",
    type: "tool",
    tool: enc306,
    time: "[~ 20-30 min.]"
  },
  "uo_060": {
    title: "Exploration 2: Levels of value creation",
    type: "tool",
    tool: enc307,
    time: "[~ 15-20 min.]"
  },
  "uo_061": {
    title: "Exploration 3: How to become a value creator?",
    type: "tool",
    tool: enc308,
    time: "[~ 1 hr.]"
  },

  // demo flex

  "uo_062": {
    title: "Exploration 1: What is Trust Surplus?",
    type: "tool",
    tool: flex_trust_001,
    time: "[~ 1 hr.]"
  },
  "uo_063": {
    title: "Exploration 2: How is Trust Surplus built?",
    type: "tool",
    tool: flex_trust_002,
    time: "[~ 30 min.]"
  },
  "uo_064": {
    title: "Exploration 3: Strategies for building Trust Surplus",
    type: "tool",
    tool: flex_trust_003,
    time: "[~ 45 min.]"
  },
  "uo_065": {
    title: "Exploration 4: Go Forward",
    type: "tool",
    tool: flex_trust_004,
    time: "[~ 15 min.]"
  },

  // tshlr Evolving Professionally

  "uo_066": {
    title: "Exploration 1: What does it mean to evolve professionally?",
    type: "tool",
    tool: enc321,
    time: "[~ 20 min.]"
  },
  "uo_067": {
    title: "Exploration 2: Level 1: Evolving one’s role",
    type: "tool",
    tool: enc322,
    time: "[~ 30 min.]"
  },
  "uo_068": {
    title: "Exploration 3: Level 2: Evolving in one’s practice",
    type: "tool",
    tool: enc323,
    time: "[~ 30 min.]"
  },
  "uo_069": {
    title: "Exploration 4: Level 3: Evolving holistically",
    type: "tool",
    tool: enc324,
    time: "[~ 20 min.]"
  },

  // acp day-1 hindi

  "uo_070": {
    title: "A new vision of values education",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vTdzvvKBup9vPXYZPZPyZuGjYkbning5eiDIxHfD3_m5icc_znSN_WFFncK3bUwKw/embed?start=false&loop=false&delayms=1800000",
    time: "[2 hrs]"
  },
  "uo_071": {
    title: "How Values are Assimilated",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vRiXxP7NWVoY6pBxRcdE4TA5_ToBOdzG8rPMyRwJAUKuOfJO5VUYcf4J_XjhcwcPA/embed?start=false&loop=false&delayms=1800000",
    time: "[1 hrs]"
  },
  "uo_072": {
    title: "Program Curriculum Framework",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vQp4Gg2imeNtAfWQJ7OsfBE5JLVRNigBCdqnnvm547vzoM8a8iyTPrgzFy8YD6iWw/embed?start=false&loop=false&delayms=1800000",
    time: "[~10 mins]"
  },
  "uo_073": {
    title: "How the program was developed",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vS0K_RGk_oyIyjNqgMNMRD4Uund22o07zIsY8IEaT1SxbfpIsvRTxvoe3xKvqSZGg/embed?start=false&loop=false&delayms=1800000",
    time: "[~15 mins]"
  },
  "uo_074": {
    title: "How to conduct the program",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vQiqTBA7JU_aNZ_f4awbeK0DqPSv5SFQ_e0uMAUIR_EXjj8iMb5INzP5xgxTOuXRA/embed?start=false&loop=false&delayms=1800000",
    time: "[20 mins]"
  },
  "uo_075": {
    title: "Practice Assignment",
    type: "ppt",
    src: "https://docs.google.com/presentation/d/e/2PACX-1vQOykiiksIypMtEW88nH5ReofiZ-Ey7oHu2a8sotw_wghr8M7ZsbjmtBeOADmjf5A/embed?start=false&loop=false&delayms=1800000",
    time: "[10 mins]"
  },

  // GTU 006

  "uo_076": {
    title: "Exploration 1: Widening zones of contribution possible in any role",
    type: "tool",
    tool: GTU222,
    time: "[~ 1hr.]"
  },
  "uo_077": {
    title: "Exploration 2: Expanding the zone of our impact & contribution",
    type: "tool",
    tool: GTU223,
    time: "[~ 1hr.]"
  },
  "uo_078": {
    title: "Exploration 3: Closure",
    type: "tool",
    tool: GTU224,
    time: "[~ 5 mins.]"
  },

  // FOS Demo
  "uo_079": {
    title: "Exploration 3: How to systematically build our Tree of Fulfillment",
    type: "tool",
    tool: FOS1,
    time: "[1 hr]"
  },

  // tslenp wfw, tslhr_mod07
  
  "uo_080": {
    title: "Exploration 1: What is an Operating Ideal",
    type: "tool",
    tool: enp205,
    time: "[~ 10min.]"
  },
  "uo_081": {
    title: "Exploration 2: The Future-Ready Operating Ideal of TSL",
    type: "tool",
    tool: enp206,
    time: "[~ 20min.]"
  },
  "uo_082": {
    title: "Exploration 3: How we will adopt the Future-Ready Operating Ideal as a system",
    type: "tool",
    tool: enp207,
    time: "[~ 10 min.]"
  },
  "uo_083": {
    title: "Exploration 4: Approach for Wave 1",
    type: "tool",
    tool: enp208,
    time: "[~ 45 min.]"
  },
  "uo_084": {
    title: "Next phase in the intervention: Walkthrough Wave 1–Phase 2",
    type: "tool",
    tool: enp209,
    time: "[~ 10 min.]"
  },



}