import firebase from "firebase/app";
import "firebase/database";
import store from "../../../Redux_Store";
let server = "https://us-central1-possibilities-parenting.cloudfunctions.net/serverResponse";

if (!firebase.apps.length) {
  const config = {
    apiKey: "AIzaSyC7wfwLtOf3RhyelZOz9u8jiMzda5jeDGI",
    authDomain: "coaching-platform-illumine.firebaseapp.com",
    databaseURL: "https://coaching-platform-illumine.firebaseio.com",
    projectId: "coaching-platform-illumine",
    storageBucket: "coaching-platform-illumine.appspot.com",
    messagingSenderId: "1002955816731",
    appId: "1:1002955816731:web:7826d9a3f63fb75a499dbe",
    measurementId: "G-P02VM70XHT"
  };
  firebase.initializeApp(config);
}
let db = firebase.database();

export const activateComponent = (p) => {
  let { courseId, value, instanceId, sessionId, uid } = p;
  return dispatch => {
    dispatch({
      type: "ACTIVATE_COMPONENT",
      payload: {
        courseId,
        target: "milestone",
        value, instanceId, sessionId, uid
      }
    })
  }
};

export const manageFullScreen = (st) => {
  return dispatch => {
    dispatch({
      type: "MANAGE_FULLSCREEN",
      payload: st
    })
  }
}

export const manageToolData = ({ toolId, content }) => {
  console.log('running');
  console.log(toolId);
  return dispatch => {
    dispatch({
      type: "MANAGE_TOOL_DATA",
      payload: { toolId, content }
    })
  }
}

export const resetData = () => {
  return dispatch => { dispatch({ type: "RESET_CHANNEL_STATE" }) }
}

export const resetTracker = () => {
  return dispatch => { dispatch({ type: "RESET_TRACKER" }) }
}

export const updateReadingTracker = (p)=>{
  let updates = {};
  let { uid, groupId, resourceId, instanceId } = p; 
  updates[`/users/${uid}/engagements/readings/${groupId}/${resourceId}/${instanceId}`] = true;
  db.ref().update(updates);
  return null;
}

export const updateUserEngagement = (p) => {
  let { uid, sessionId, instanceId, milestone, courseId, tracker } = p;
  let updates = {};
  let unitData = {};
  Object.keys(p).forEach(k => {
    if (!(k == 'uid' || k == 'sessionId' || k == 'instanceId' || k == 'milestone' || k == 'courseId' || k == 'tracker')) {
      unitData[k] = p[k];
    }
  });
  updates[`/users/${uid}/engagements/sessions/${courseId}/${sessionId}/${instanceId}/milestone`] = milestone;
  updates[`/users/${uid}/engagements/sessions/${courseId}/${sessionId}/${instanceId}/tracker`] = tracker;
  if (Object.keys(unitData).length) {
    updates[`/users/${uid}/engagements/sessions/${courseId}/${sessionId}/unitData`] = unitData;
  }

  db.ref().update(updates);
  return null;
};

export const connectEngagementSessionData = (sessionId) => {
  db
    .ref(`/EngagementSessions/${sessionId}`)
    .on('value', snap => {
      store.dispatch({ type: "MANAGE_TOOL_DATA", payload: {toolId: sessionId, content: snap.val()} });
    });
}