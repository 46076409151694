import React, { useState } from 'react';
import {
  Input,
  InputLabel,
  FormControl,
  Button,
} from "@material-ui/core";

import { constants } from '../../../constants';
function AccessCode({user, onSuccess, onFailure}) {
  const [accessCode, setAccessCode] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  // let handleChange = prop => event => {
  //   setAccessCode(event.target.value);
  // };
  return <div className='blk-cntr'>
    <div style={{ width: 420 }}>
      <div style={{fontSize: "1.5rem", fontWeight: 500}}>Hi!</div>
      <div style={{fontSize: "1.5rem", fontWeight: 500}}>Gain access to more modules...</div>
      <p style={{margin: "28px 0px", lineHeight:1.4}}>Please input the ACCESS CODE provided to you so we create your session list</p>
      <form>
        <FormControl
          fullWidth
          className="input-cntr"
        >
          <InputLabel htmlFor="access-code">Access Code</InputLabel>
          <Input
            id="access-code"
            label="Access Code"
            type="text"
            autoComplete="off"
            value={accessCode ? accessCode : ''}
            onChange={ev => { setAccessCode(ev.target.value) }}
          />
        </FormControl>
        <FormControl
          fullWidth
          className="input-cntr"
        >
          <Button
            variant="raised"
            disabled={!accessCode}
            classes={{root:"action-btn", label:"btn-text"}}
            onClick={() => {
              fetch(`${constants.server}/access-code`, {
                method: 'POST',
                body: JSON.stringify({ accessCode, uid: user.uid })
              }).catch(err => {
                console.log(err)
              }).then(response => {
                response
                  .json()
                  .then(response => { 
                    console.log(response)
                    if(!response.success)
                    {setErrMsg(response.errMsg)} 
                    else
                    {onSuccess()}
                  });
              });
            }}
          >
            <span>Unlock Sessions</span>
          </Button>
        </FormControl>
          <div style={{fontSize: '0.75rem', color: '#F00', textAlign: 'center'}}>{errMsg}</div>
      </form>
    </div>
  </div>
}

export default AccessCode;