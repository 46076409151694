import React, { useEffect, useState } from 'react';
import paper, { view, Path, Point, PointText } from 'paper';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import TextBox from '../../components/Textbox';

class TextBoxBlock {
 constructor({ point, text }) {
  this.point = point;
  this.content = text;
  this.justification = 'left';
  this.fillColor = 'black';
  this.fontSize = 16
 }
}

let Write = (props) => {
 let { slideHeight, slideWidth, manageToolData, engagementData } = props;
 console.log(engagementData);
 let [up, setCp] = useState(false);
 let [textBoxes, setTextBoxes] = useState({})
 let [dialogOpen, setDialogOpen] = useState(false);
 useEffect(ev => {
  paper.install(window);
  paper.setup('paper-canvas');

  // draw or call your drawing functions here

  view.onFrame = draw;

  view.onDoubleClick = (ev) => {
   setDialogOpen(true);
   let pt = new Point(ev.point);
   let TextBoxBlk = new TextBoxBlock({
    point: new Point(ev.point),
    text: "asdf"
   });
   setTextBoxes({ ...textBoxes, [Date.now()]: TextBoxBlk })
  }


  function draw(event) {
   // console.log(textBoxes);
   // to update path on each frame. To bring Animation
  }
 }, [])

 Object.keys(textBoxes).map(k => {
  let t = textBoxes[k];
  let l = new PointText({ ...t });
 })

 return <div style={{ width: slideWidth, height: slideHeight, position: 'relative' }}>
  <canvas id='paper-canvas' resize='true' width={slideWidth} height={2 * slideHeight} />
  <div style={{ position: 'absolute', bottom: 4, right: 4, zIndex: 1000 }} onClick={() => {
   view.scrollBy(up ? new Point(0, 525) : new Point(0, -525));
   setCp(!up)
  }}>A</div>
  {/* <Dialog onClose={() => { setDialogOpen(false) }} open={dialogOpen}>
   <TextBox
    id={`text-box-baba`}
    target={''}
    processData={manageToolData}
    initialData={engagementData["text-box-baba"]}
    customStyleWrapper={null}
    customStyleToolbar={null} />
  </Dialog> */}
 </div>
}

export default Write;