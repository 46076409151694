import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import ImageSlide from '../../components/ImageSlide';
import ToolNavigation from '../../components/ToolNavigation';
import SlideImg_1 from "./assets/Ex4-S0-SectionBreak.PNG";
import SlideImg_2 from "./assets/Ex4.PNG";
import './style.css';

let cases = ['Faizal is a nice person, always ready to help colleagues. People turn to him for personal advice. Relationships are important to him. But work is just about having a secure job and comfortable life. He doesn’t push himself to meet expectations.',
'Meeta is competent, develops her expertise, and tries to be efficient at work. But in her drive for efficiency and chasing targets, she often compromises on quality & achieves her goals at the cost of people.',
'Daniel takes end to end ownership of goals. He goes all out to deliver to outcomes, mobilizing people and resources where needed. When a project hits roadblocks, he innovatively finds solutions. He takes pride in doing high quality work & rarely breaks a delivery commitment. He invests into building the people he works with – partners or colleagues.']

let UnitOne = (props) => {
  let { isFullScreen, manageFullScreen, dimensions, engagementData, manageToolData, onSlideChange } = props;
  let { slideHeight, slideWidth } = dimensions;
  const [index, setIndex] = useState(0);
  const slides = [
    { id: 0, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_1} /> },
    { id: 1, obj: <ImageSlide dimensions={dimensions} imgUrl={SlideImg_2} /> }
  ];

  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0 }, enter: { opacity: 1 }, leave: { opacity: 0 },
  });
  // useEffect(() => { setIndex(sceneCount) }, [sceneCount]);

  return <div className='tool-holder'>
    <div className='slide-cntr' style={{width: slideWidth, height: slideHeight}}>
    {transitions.map(({ item, props, key }) => (
      <animated.div
        key={key}
        className="slide"
        style={{ ...props, position:'absolute', width: slideWidth, height: slideHeight }}
      >
        <div style={{height: '100%'}}>{item.obj}</div>
      </animated.div>
    ))}
    </div>
    <ToolNavigation
      currentScene = {index}
      sceneCount = {slides.length}
      next={(index) => {
        manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index + 1 } });
        onSlideChange(engagementData);
        setIndex(index + 1);
      }}
      previous={(index) => {
        manageToolData({ toolId: 'tracker', content: { ...engagementData.tracker, [Date.now()]: index - 1 } });
        onSlideChange(engagementData);
        setIndex(index - 1)
      }}
      isToolFullScreen = {isFullScreen}
      toggleFullScreen = {manageFullScreen}
      />
  </div>
}

export default UnitOne;